<template>
<div>
    <el-dialog
        title="时间模式设置"
        center
        width="800px"
        :visible.sync="dialogTimeViseble"
        @close="closeDialog"
        :close-on-click-modal="false"
    >
      <div class="dialog-contents">
        <p class="stuInfo">
            <span>学生姓名：{{studentInfo.studentName}}</span>
            <span>年级：{{studentInfo.sectionName}}</span>
        </p>
        <div class="btn-div">
          <el-button-group style="float:right;">
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />·
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
          <el-button type="default" size="small" @click="recordClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>历史记录</span>
          </el-button>
        </el-button-group>
        </div>
        <div class="table-area">
        <el-table
            size="small"
            :data="customData"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="handleSelectionChange"
            @row-click="roleItemClick"
        >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
            type="index"
            label="编号"
            align="center"
            width="60">
        </el-table-column>
        <el-table-column
            prop="basicPrice"
            label="单价"
            align="center"
            >
        </el-table-column>
        <el-table-column
            prop="beginTime"
            label="生效时间"
            align="center"
           >
        </el-table-column>
        <el-table-column
            prop="endTime"
            label="结束时间"
            align="center"
            >
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            >
        </el-table-column>
        
        </el-table>
        </div>
        <div style="display:flex;align-items: center;justify-content: center;padding:15px 0;">
           <el-button type="primary" size="small" @click="enableModel()" :disabled="enableBtnShow">启用时间模式</el-button>
            <el-button type="primary" size="small" @click="closeDialog()">返回</el-button>
        </div>
         <!-- <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="customTotal"
        background
      ></el-pagination>
    </div> -->
      </div>
    </el-dialog>
    <el-dialog
        :title="priceParams.title"
        center
        width="500px"
        :visible.sync="addShow"
        :close-on-click-modal="false"
    >
        <div>
            <el-form
          :model="addParams"
          status-icon
          :rules="saveRules"
          ref="addParams"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="基本单价" prop="basicPrice">
                <el-input
                  style="width:90px;"
                  v-model="addParams.basicPrice"
                ></el-input>
                <span style="padding:0 3px;">元/课时</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="生效时间" prop="beginTime">
                <el-date-picker
                    v-model="batchPickerValue2"
                    type="date"
                    @change="casePickerChange1()"
                    :picker-options="startDatePicker"
                    placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                    >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="结束时间" prop="endTime">
                <el-date-picker
                    v-model="batchPickerValue3"
                     type="date"
                     @change="casePickerChange2()"
                     :picker-options="endDatePicker"
                    placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                    >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('addParams')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
        title="价格查看"
        center
        width="400px"
        :visible.sync="seePriceShow"
        :close-on-click-modal="false"
    >
      <div>
        <div class="seePrice-div">
          <p>基本单价：{{priceInfoParams.basicPrice}}元/课时</p>
        <ul>
          <li v-for="(item,index) in priceInfoParams.listXmtStudentUnitPriceGrandson" :key="index">
            <p>课时范围{{index+1}}：{{item.begin}}至{{item.end}}课时</p>
            <p>课时单价：{{item.unitPrice}}元/课时</p>
          </li>
        </ul>
        </div>
      </div>
     </el-dialog>
     <el-dialog
        title="历史记录"
        center
        width="800px"
        :visible.sync="recordShow"
        :close-on-click-modal="false"
    >
      <div class="dialog-content">
          <div class="table-area">
            <el-table
              size="small"
              :data="recordList"
              style="width: 100%; "
              row-key="id"
              border
              tooltip-effect="dark"
            >
            <el-table-column
                type="index"
                label="编号"
                align="center"
                width="60">
            </el-table-column>
              <el-table-column
                prop="basicPrice"
                label="单价"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="beginTime"
                label="生效时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="endTime"
                label="结束时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="recordSizeChange"
              @current-change="recordCurrentChange"
              :current-page="recordParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="recordParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordTotal"
              background
            ></el-pagination>
          </div>
      </div>
     </el-dialog>
</div>
    

</template>
<script>
import {
    studentUnitPriceChildList,//列表
    studentUnitPriceChildaSave,//新增
    studentUnitPriceChildaUpdate,//修改
    studentUnitPriceChildaRemove,//删除
    setCustomPriceStatu,//启用禁用
    studentUnitPriceChildInfo,
    selectModelInfoByStudentId,
    setType
 } from '@/api/finance/finance.js'
export default {
    props: ["studentInfo","timeStatus"],
    watch: {
        timeStatus(newValue,oldValue){
            this.params.studentId=this.studentInfo.studentId;
            this.addParams.studentId=this.studentInfo.studentId;
            this.recordParams.studentId=this.studentInfo.studentId;
            this.dialogTimeViseble=newValue;
            if(newValue==true){
            this.studentUnitPriceChildListFn();
            }
        }
  },
    data(){
        return{
            batchPickerValue2:"",
            batchPickerValue3:"",
            rowsEndTime:"",
            startDatePicker: null,
            endDatePicker: null,
            beginTime:"",
            endTime:"",
            dialogTimeViseble:false,
            recordList:[],
            recordShow:false,
            priceInfoParams:{},
            seePriceShow:false,
            customData:[],
            customTotal:0,
            dialogVisible:false,
            params:{
                pageNumber:1,
                pageSize:1000,
                studentId:"",
                type:"2",
                delFlag:"0"
            },
            priceParams:{
              title:'新增单价',
              type:'add'
            },
            addShow:false,
            addParams:{
                basicPrice:"",//基本单价
                studentId:"",//学生Id
                type:2,
                beginTime:null,
                endTime:null,

                
            },
            rowIdList:[],
            editInfo:[],
            recordTotal:0,
            saveRules:{
              basicPrice: [
                { required: true, message: "基本单价不能为空", trigger: "blur" },
              ],
              beginTime: [
                { required: true, message: "生效时间不能为空", trigger: "blur" },
              ],
              endTime: [
                { required: true, message: "结束时间不能为空", trigger: "blur" },
              ],
            },
            recordParams:{
                pageNumber:1,
                pageSize:10,
                studentId:"",
                type:"2",
                delFlag:"1"
            },
            enableBtnShow:false
        }
    },
    created(){
       
        this.studentUnitPriceChildListFn();
    },
    mounted(){
        this.startDatePicker = this.beginDate();
        this.endDatePicker = this.processDate();
    },
    methods:{
        closeDialog(){
          this.$emit('CB_timeStatus')
        },
        enableModel(){
          selectModelInfoByStudentId({studentId:this.studentInfo.studentId,type:'2'}).then(res => {
            if(res.code==0){
              this.$confirm(`${res.rows}`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  setType({id:this.studentInfo.id,type:'2'}).then((res) => {
                    if (res.code == 0&&res.msg=='操作成功！') {
                      this.msgSuccess("操作成功！");
                      this.enableBtnShow=true;
                      this.$emit('refreshList')
                    }else{
                      this.msgWarn(res.rows);
                    }
                  });
                })
                .catch(() => {});
            }
          });
        },
        seePrice(row){
          this.seePriceShow=true;
          studentUnitPriceChildInfo(row.id).then(res => {
            if(res.code==0){
              this.priceInfoParams=res.rows;
            }
          });
        },
        studentUnitPriceChildListFn(){
            studentUnitPriceChildList(this.params).then(res => {
                this.customData=res.rows;
                this.customTotal=res.total;
                var rowsData=res.rows;
                if(rowsData.length){
                  this.rowsEndTime=rowsData[0].endTime;
                }else{
                  this.rowsEndTime=null;
                }
            });
        },
        recordClick(){
          this.recordShow=true;
          this.recordFn();
          
        },
        casePickerChange1(){
            this.addParams.beginTime = this.batchPickerValue2+" 00:00:00";
        },
        casePickerChange2(){
            this.addParams.endTime = this.batchPickerValue3+" 23:59:59";
        },
        recordFn(){
          studentUnitPriceChildList(this.recordParams).then(res => {
              this.recordList=res.rows;
              this.recordTotal=res.total;
          });
        },
        beginDate() {
            const self = this
            return {
                disabledDate(time){
                    if (self.batchPickerValue3) {
                        return new Date(self.batchPickerValue3).getTime() < time.getTime()
                    }
                }
            }
        },
        processDate() {
            const  self = this
            return {
                disabledDate(time) {
                    if (self.batchPickerValue2) {
                        return new Date(self.batchPickerValue2).getTime()- 86400000 > time.getTime()
                    }
                }
            }
        },
        confirmSave(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addParams.studentId=this.studentInfo.studentId;
                    if(this.priceParams.type=='add'){
                      studentUnitPriceChildaSave(this.addParams).then((res) => {
                        if (res.code == 0) {
                            this.addShow=false;
                            this.msgSuccess("操作成功！");
                            this.studentUnitPriceChildListFn();
                        }
                    });
                    }else if(this.priceParams.type=='edit'){
                      studentUnitPriceChildaUpdate(this.addParams).then((res) => {
                          if (res.code == 0) {
                              this.addShow=false;
                              this.msgSuccess("操作成功！");
                              this.studentUnitPriceChildListFn();
                          }
                      });
                    }
                    
                }else {
                    this.msgWarn("必填字段不能为空！");
                    return false;
                }
            })
        },
       
        handleSizeChange(pageSize){
          this.params.pageSize = pageSize;
          this.studentUnitPriceChildListFn();
        },
        handleCurrentChange(currentPage){
          this.params.pageNumber = currentPage;
          this.studentUnitPriceChildListFn();
        },
        recordSizeChange(pageSize){
          this.recordParams.pageSize = pageSize;
          this.recordFn();
        },
        recordCurrentChange(currentPage){
          this.recordParams.pageNumber = currentPage;
          this.recordFn();
        },
        priceDifferenceBlur(){

        },
        closeListClick(){
     
          this.$emit("studentListParamsChange");
        },
        handleSelectionChange(val){
          console.log(val,'val...')
          this.rowIdList=[];
          this.editInfo=[];
          if(val.length>0){
            for (let i = 0, len = val.length; i < len; i++) {
              this.rowIdList.push(val[i].id);
              this.editInfo.push(val[i])
            }
             
          }
        },
        roleItemClick(row){
          // this.$refs.customDataTable.toggleRowSelection(row);
        },
        addClick(){
            this.priceParams={
              title:'新增单价',
              type:'add'
            }
            this.addParams={
                basicPrice:"",//基本单价
                studentId:"",//学生Id
                type:2,
                beginTime:null,
                // beginTime:this.rowsEndTime?this.rowsEndTime.split(' ')[0]:null,
                endTime:null,
            }
            var endtime=this.rowsEndTime?this.rowsEndTime.split(' ')[0]:null;
            if(endtime){
              var d=new Date(endtime);
              d=+d+1000*60*60*24;
              d=new Date(d);
              this.batchPickerValue2=d.getFullYear()+"-"+(d.getMonth()+1)+'-'+d.getDate();
            }else{
              this.batchPickerValue2=''
            }
            this.batchPickerValue3='';
            this.casePickerChange1();
            console.log(this.batchPickerValue2,'batchPickerValue2...')
            this.addShow=true;
        },
        editClick(){
          this.priceParams={
              title:'编辑单价',
              type:'edit'
            }
          if (this.editInfo.length > 1) {
            this.msgWarn("请选择单个数据进行编辑！");
            return;
          }
          if (this.editInfo.length == 0) {
            this.msgWarn("请选择一个数据进行编辑！");
            return;
          }
          this.addShow=true;
          this.addParams=this.editInfo[0];
          this.batchPickerValue2=this.editInfo[0].beginTime.split(' ')[0]
          this.batchPickerValue3=this.editInfo[0].endTime.split(' ')[0]
        },
        //是否启用禁用
        isEnableClick(row){
          let data={
            id:this.studentInfo.id,
            childId:row.id,
            status:row.status==0?1:0,
            no:row.no,
          }
          setCustomPriceStatu(data).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentUnitPriceChildListFn();
            }
          });
        },
        delClick(){
          if (this.rowIdList.length == 0) {
            this.msgWarn("请至少选择一条数据进行删除！");
            return;
          }
          this.$confirm("确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              studentUnitPriceChildaRemove(this.rowIdList).then((res) => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.studentUnitPriceChildListFn();
                }
              });
            })
            .catch(() => {});
        },
        enableClick(){

        },
        disableClick(){

        },
    }
    
}
</script>
<style scoped lang="scss">
    .stuInfo{
        display: flex;
        font-size:16px;
        span:nth-child(2){
            margin-left:60px;
        }
    }
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:30px;
      float:right;
      margin-top:20px;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
    
</style>