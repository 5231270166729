<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <!-- <span class="item-lable">关键字：</span> -->
          <el-input
            v-model="params.teacherName"
            placeholder="老师姓名"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <!-- <span class="item-lable">关键字：</span> -->
          <el-input
            v-model="params.name"
            placeholder="学生姓名/班级名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
         <div class="select-condition-item">
          <span class="item-lable">上课时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">学科：</span>
          <el-select
            v-model="params.subjectId"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in subjectIdList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
            <span class="item-lable">年级：</span>
            <el-select
            v-model="params.sectionId"
            placeholder="请选择"
            size="small"
            >
            <el-option
                v-for="item in sectionList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
            ></el-option>
            </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="classHoursFn()"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="importExcel"
          icon="el-icon-printer"
          >导出excel</el-button
        >
      </div>
    </div>
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
            <span class="item-lable">上课方式：</span>
            <el-select
              v-model="params.classMode"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in classModeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="select-condition-item">
          <span class="item-lable">教务姓名：</span>
          <el-select
            v-model="params.teacherManagerRealName"
            placeholder="请选择"
            size="small"
          >
           <el-option
              v-for="item in saleList"
              :key="item.userId"
               :label="`${item.username}（${item.name}）`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品类型：</span>
          <el-select
            v-model="params.productType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">课程级别：</span>
          <el-select
            v-model="params.courseLevel"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in courseLevelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <p style="padding-bottom:10px;">
      <span style="padding:0 5px;">共计{{total}}条信息</span>
      <span style="padding:0 5px;color:#13ce66;">正常课时数：{{allUsedClassHour}}</span>
      <span style="padding:0 5px;color:#13ce66;">课耗：{{allUsePrice}}</span>
      <span style="padding:0 5px;color:#13ce66;">课酬：{{allTeachFee}}</span>
      <span style="padding:0 5px;color:red;">异常课时数：{{abnormalUsedClassHour}}</span>
      <span style="padding:0 5px;color:red;">课耗：{{abnormalUsePrice}}</span>
      <span style="padding:0 5px;color:red;">课酬：{{abnormalTeachFee}}</span>
    </p>
    <div class="table-area">
      <el-table
        size="small"
        :data="dataList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="beginTime"
          label="上课时间"
          align="center"
        >
         <template slot-scope="scope">
            <p>{{scope.row.beginTime?scope.row.beginTime.substring(0,16):""}}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="学生姓名/班级名称"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{scope.row.studentName?scope.row.studentName:scope.row.roomName}}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectName"
          label="科目"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时数"
          align="center"
        ></el-table-column>
        <el-table-column
          label="课程级别"
          align="center"
          :formatter="formatterPlanCourseLevel"
        ></el-table-column>
        <el-table-column
          prop="usePrice"
          label="课耗"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teacherRealName"
          label="老师姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachFee"
          label="课酬"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="formatterClassMode"
          label="上课方式"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="moneyType"
          label="产品类型"
          align="center"
          :formatter="formatterProductType"
        ></el-table-column>
         <el-table-column
          prop="teacherManagerRealName"
          label="教务姓名"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="sailRealName"
          label="所属销售"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          label="补课时"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.classHourType == 7" style="color:red;">是</span>
            <span v-else >否</span>
          </template>
        </el-table-column>
         <el-table-column
          prop="moneyType"
          label="课程状态"
          align="center"
        > 
          <template slot-scope="scope">
              <span v-if="scope.row.abnormalStatus==0">正常</span>
              <el-popover
                v-else
                placement="top-start"
                title="异常原因"
                width="200"
                trigger="hover"
                :content="scope.row.abnormalCause">
                <span slot="reference"  style="color:red;">异常(查看原因)</span>
              </el-popover>
            
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  import dayjs from "dayjs";
  import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
  import { listNoPage,financialClassHours } from '@/api/administration/tubeTeachBusiness/studentManage.js'
  import { statisticsOfFinancialClassHours } from '@/api/finance/finance.js'
    export default {
        data(){
            return{
              dataJson:[],
              jsonData:[],
              allUsedClassHour:'',
              allUsePrice:'',
              allTeachFee:'',
              abnormalTeachFee:'',
              abnormalUsePrice:'',
              abnormalUsedClassHour:'',
              saleList:[],
              subjectIdList:[],
              sectionList:[],
                dataList:[],
                total:0,
                timePickerValue:"",
                params:{
                  name:"",
                  teacherName:"",
                  pageNumber:1,
                  pageSize:10,
                  searchBeginDate:null,
                  searchEndDate:null,
                  classMode:"",
                  subjectId:"",
                  sectionId:"",
                  teacherManagerRealName:"",
                  courseLevel:"",
                  productType:0,
                  dataAuthority: "dept"
                },
                classModeList: [
                  { value: "", label: "全部" },
                  { value: 1, label: "线上" },
                  { value: 2, label: "线下" },
                ],
                productTypeList: [
                  {
                    value: 0,
                    label: "全部",
                  },
                  {
                    value: 1,
                    label: "一对一充值",
                  },
                  {
                    value: 3,
                    label: "试听课",
                  },
                  {
                    value: 2,
                    label: "一对多",
                  },
                  {
                    value: 4,
                    label: "公开课",
                  },
                ],
                courseLevelList: [
                  {
                    value: '',
                    label: "全部",
                  },
                   {
                    value: 0,
                    label: "正常课程",
                  },
                  {
                    value: 1,
                    label: "初级课程",
                  },
                  {
                    value: 2,
                    label: "中级课程",
                  },
                  {
                    value: 3,
                    label: "高级课程",
                  },
                  {
                    value: 4,
                    label: "特级课程",
                  },
                ],
                pickerOptions: {
                shortcuts: [
                {
                    text: "今天",
                    onClick(picker) {
                    const end = new Date(
                        dayjs()
                        .add(1, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs().format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: "昨天",
                    onClick(picker) {
                    const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                    const start = new Date(
                        dayjs()
                        .subtract(1, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: "最近一周",
                    onClick(picker) {
                    const end = new Date(
                        dayjs()
                        .add(1, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs()
                        .subtract(7, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: "最近一个月",
                    onClick(picker) {
                    const end = new Date(
                        dayjs()
                        .add(1, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs()
                        .subtract(30, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: "最近三个月",
                    onClick(picker) {
                    const end = new Date(
                        dayjs()
                        .add(1, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    const start = new Date(
                        dayjs()
                        .subtract(90, "day")
                        .format("YYYY-MM-DD") + " 00:00:00"
                    );
                    picker.$emit("pick", [start, end]);
                    }
                }
                ]
            },
            }
        },
        created(){
          this.getSubjectList();
          this.getSectionIdList();
          this.classHoursFn();
          this.roleSign();
        },
        methods:{
          classHoursFn(){
            let teacherManagerRealName = ''
            this.saleList.forEach( item => {
              if(item.userId && item.userId == this.params.teacherManagerRealName){
                teacherManagerRealName = item.name
              }
            })
            statisticsOfFinancialClassHours({ ...this.params, teacherManagerRealName}).then((res) => {
              this.dataList=res.rows;
              this.total=res.total;
              this.allUsePrice=res.countMap?res.countMap.usePrice:0
              this.allUsedClassHour=res.countMap?res.countMap.usedClassHour:0
              this.allTeachFee=res.countMap?res.countMap.teachFee:0;

              this.abnormalTeachFee=res.countMap?res.countMap.abnormalTeachFee:0;
              this.abnormalUsePrice=res.countMap?res.countMap.abnormalUsePrice:0;
              this.abnormalUsedClassHour=res.countMap?res.countMap.abnormalUsedClassHour:0;
            });
          },
        async  importExcel(){
            this.jsonData=[];
            let str = `上课时间,学生姓名/班级姓名,科目,年级,课时数,课程级别,课耗,老师姓名,课酬,上课方式,产品类型,教务姓名,所属销售,课程状态\n`
            let data={
                  name:"",
                  teacherName:"",
                  pageNumber:1,
                  pageSize:100000,
                  searchBeginDate:null,
                  searchEndDate:null,
                  classMode:"",
                  subjectId:"",
                  sectionId:"",
                  teacherManagerRealName:"",
                  courseLevel:"",
                  productType:0,
                  dataAuthority: "dept"
            }
          await  statisticsOfFinancialClassHours(data).then((res) => {
              for(let i = 0, len = res.rows.length; i < len; i++){
                this.jsonData.push({
                  'beginTime': res.rows[i].beginTime,
                  'studentName': res.rows[i].studentName?res.rows[i].studentName:res.rows[i].roomName,
                  'subjectName': res.rows[i].subjectName,
                  'sectionName': res.rows[i].sectionName,
                  'usedClassHour': res.rows[i].usedClassHour,
                  'courseLevel': this.formatterCourseType(res.rows[i]),
                  'usePrice': res.rows[i].usePrice,
                  'teacherRealName': res.rows[i].teacherRealName,
                  'teachFee': res.rows[i].teachFee,
                  'classMode': this.formatterClassMode(res.rows[i]),
                  'productType': this.formatterProductType(res.rows[i]),
                  'teacherManagerRealName': res.rows[i].teacherManagerRealName,
                  'sailRealName': res.rows[i].sailRealName,
                  'classStatus': this.attendClassStatusList(res.rows[i]),
                })
              }
            });
            // 增加\t为了不让表格显示科学计数法或者其他格式
            for(let i = 0 ; i < this.jsonData.length ; i++ ){
                for(const key in this.jsonData[i]){
                    str+=`${this.jsonData[i][key] + '\t'},`;     
                }
                str+='\n';
            }
            // encodeURIComponent解决中文乱码
            const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
            // 通过创建a标签实现
            const link = document.createElement("a");
            link.href = uri;
            // 对下载的文件命名
            link.download =  "课时统计列表.xls";
            link.click();
          },
           formatterCourseType(item){
           var jibie = "";
            if (item.planCourseLevel == 1) {
              jibie = "初级课程";
            } else if (item.planCourseLevel == 2) {
              jibie = "中级课程";
            } else if (item.planCourseLevel == 3) {
              jibie = "高级课程";
            } else if (item.planCourseLevel == 4) {
              jibie = "特级课程";
            } else {
              jibie = "正常课程";
            }
            return jibie;
          },
          roleSign(){
            let data = {
              roleSign: "studentAdmin",
              status: 1,
              roleSignAdmin:"studentAdminAdmin"
            };

            financialClassHours(data).then(res => {
              this.saleList = res;
              this.saleList.unshift({
                    userId: "",
                    username: "全部",
                    name:"全部"
                });
            });
          },
          //获取年级
          getSectionIdList() {
              getSelectList({
                  parentCode: sectionCode
              }).then(res => {
                  if (res.code == 0) {
                  this.sectionList = res.rows;
                  this.sectionList.unshift({
                      macroId: "",
                      name: "全部"
                  });
                  }
              });
          },
          getSubjectList() {
            getSelectList({
                parentCode: subjectCode
            }).then(res => {
                if (res.code == 0) {
                this.subjectIdList = res.rows;
                this.subjectIdList.unshift({
                    macroId: "",
                    name: "全部"
                });
                }
            });
        },
           datePickerChange(){
            if (!this.timePickerValue) {
              this.params.searchBeginDate = null;
              this.params.searchEndDate = null;
              return;
            }
            this.params.searchBeginDate = this.timePickerValue[0];
            this.params.searchEndDate = this.timePickerValue[1];
          },
          formatterClassMode(item){
            if(item.classMode==1){
              return '线上'
            }else if(item.classMode==2){
              return '线下'
            }
          },
          attendClassStatusList(row){
            if(row.abnormalStatus==0){
              return '无异常'
            }else if(row.abnormalStatus==1){
              return '有异常'
            }
          },
          formatterProductType(item){
            if(item.productType==1||item.productType==3){
              return '一对一'
            }else if(item.productType==2||item.productType==5){
              return '一对多'
            }else if(item.productType==4){
              return '公开课'
            }
          },
          formatterPlanCourseLevel(row){
            var jibie = "";
            if (row.courseLevel == 1) {
              jibie = "初级课程";
            } else if (row.courseLevel == 2) {
              jibie = "中级课程";
            } else if (row.courseLevel == 3) {
              jibie = "高级课程";
            } else if (row.courseLevel == 4) {
              jibie = "特级课程";
            } else {
              jibie = "正常课程";
            }
            return jibie;
          },
          handleSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.classHoursFn();
          },
          handleCurrentChange(currentPage) {
            this.params.pageNumber = currentPage;
            this.classHoursFn();
          },
        }
    }

</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content{
    .detail_type{
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
    .row_detail{
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .certificate{
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
>>>.td-img .el-icon-circle-close {
    color: white !important;
  }
  a{text-decoration:none;}
</style>
