<template>
  <div>
    <el-dialog
        title="退费"
        center
        width="800px"
        :close-on-click-modal="false"
        :visible="Visible"
        @close="$emit('close')"
    >
    <div>
        <p class="title_p"><span></span><span>学员信息</span></p>
        <p class="student_info"><span>{{studentInfo.name}}</span><span>{{studentInfo.sex==1?'男':studentInfo.sex==2?'女':''}}</span><span>{{studentInfo.phoneNo}}</span></p>
        <p class="title_p"><span></span><span>充值记录</span></p>
        <el-table
            size="small"
            :data="xmtOrderList"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="handleSelectionChange"
            @row-click="roleItemClick"
        >
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
            prop="totalPrice"
            label="缴费金额"
            align="center"
           >
            <template slot-scope="scope">
                 <el-popover
                    placement="top-start"
                    title="充值人："
                    width="150"
                    trigger="hover"
                    :content="scope.row.orderSourceRealName">
                    <span slot="reference">{{scope.row.totalPrice}}</span>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column
            prop="zdyGiveClassHour"
            label="赠送课时"
            align="center"
            width="180">
        </el-table-column>
         <el-table-column
            prop="basicPrice"
            label="收款类型"
            align="center"
            :formatter="formatterMoneyType"
            width="180">

        </el-table-column>
         <el-table-column
            prop="createTime"
            label="充值时间"
            align="center"
            width="180">
        </el-table-column>
        </el-table>
        <p class="total_p">总计 缴费金额：{{xmtOrderMoney}}元</p>
        <p class="title_p" style="margin-top:20px;"><span></span><span>课消记录</span></p>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="margin-top:15px;">
            <el-tab-pane label="概览" name="first">
                <el-table
                size="small"
                :data="classUsedOverview"
                style="width: 100%;margin-top:10px;"
                row-key="roleId"
                border
                tooltip-effect="dark"
                ref="customDataTable"
            >
            <el-table-column
                prop="no"
                :formatter="dealjibie"
                label="课程级别"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usedClassHour"
                label="已上课时"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="singlePrice"
                label="单价"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usePrice"
                label="总课耗"
                align="center"
            >
            </el-table-column>
        </el-table>
        <p class="total_p">总计 课耗：{{classUsedViewMoney}}元</p>
            </el-tab-pane>
            <el-tab-pane label="详细" name="second">
             <el-table
                size="small"
                :data="classUsedDetailed"
                style="width: 100%;margin-top:10px;"
                row-key="roleId"
                border
                tooltip-effect="dark"
                ref="customDataTable"
               
            >
            <el-table-column
                prop="name"
                label="科目"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usedClassHour"
                label="课时数"
                align="center"
            >
            </el-table-column>
            <el-table-column
               :formatter="dealjibie"
                prop="basicPrice"
                label="课程级别"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="singlePrice"
                label="单价"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usePrice"
                label="课耗"
                align="center"
            >
            </el-table-column>
        </el-table>
        <p class="total_p">总计 课耗：{{classUsedDetailedMoney}}元</p>
        </el-tab-pane>
        </el-tabs>
        
        <p class="title_p" style="margin-top:20px;"><span></span><span>退款记录</span></p>
        <el-table
            size="small"
            :data="getApprovalList"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="recordSelectionChange"
            @row-click="recordItemClick"
        >
        <el-table-column
            prop="createTime"
            label="退款时间"
            align="center"
           >
        </el-table-column>
        <el-table-column
            prop="realityRefundAmount"
            label="退款金额"
            align="center"
           >
        </el-table-column>
         <el-table-column
            prop="createName"
            label="申请人"
            align="center"
           >
        </el-table-column>
        </el-table>
        <p class="total_p">总计 退款金额：{{getApprovalMoney}}元</p>
        <p class="title_p" style="margin-top:20px;"><span></span><span>本次退款</span></p>
        <el-form
            :model="rechargeForm"
            status-icon
            :rules="rechargeRules"
            ref="rechargeForm"
            label-width="100px"
            size="small"
          >
          <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应退款金额" prop="refundAmount">
                  <span>{{rechargeForm.refundAmount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="其他扣除项" prop="realityUsePrice">
                   <el-input
                    v-show="visibleNum!=2"
                    v-model="rechargeForm.realityUsePrice"
                    placeholder=""
                    :maxlength="6"
                    @input="realityUseBlur()"
                    style="width:100px;"
                  ></el-input>
                  <el-radio-group v-show="visibleNum!=2" @change="poundageChange()" v-model="rechargeForm.poundageType" style="margin-left:20px;">
                    <el-radio :label="1">%</el-radio>
                    <el-radio :label="2">元</el-radio>
                </el-radio-group>
                <span v-show="visibleNum==2">{{rechargeForm.realityUsePrice}}</span>
                <span v-show="visibleNum==2">{{rechargeForm.poundageType==1?'%':'元'}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="实退款金额" prop="realityRefundAmount">
                  <span v-show="visibleNum!=2">{{rechargeForm.realityRefundAmount}}</span>
                  <span v-show="visibleNum==2">{{rechargeForm.realityRefundAmount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="备注" prop="backReason">
                  <el-input
                    v-show="visibleNum!=2"
                    type="textarea"
                    v-model="rechargeForm.backReason"
                    placeholder="例如：手续费、设备押金、课时费不正常等"
                  ></el-input>
                  <span v-show="visibleNum==2">{{rechargeForm.backReason}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款姓名" prop="bankUserName">
                  <el-input
                    v-model="rechargeForm.bankUserName"
                    v-show="page==1"
                    placeholder="请输入内容"
                  ></el-input>
                  <span v-show="page!=1">{{studentRefundInfo.bankUserName}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款账号" prop="bankAccount">
                  <el-input
                    v-show="page==1"
                    v-model="rechargeForm.bankAccount"
                    placeholder="请输入内容"
                  ></el-input>
                  <span v-show="page!=1">{{studentRefundInfo.bankAccount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="开户支行" prop="bankAddress">
                  <el-input
                   v-show="page==1"
                    v-model="rechargeForm.bankAddress"
                    placeholder="请输入内容"
                  ></el-input>
                  <span  v-show="page!=1">{{studentRefundInfo.bankAddress}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="联系方式" prop="bankUserPhone">
                  <el-input
                    v-show="page==1"
                    v-model="rechargeForm.bankUserPhone"
                    placeholder="请输入内容"
                    :maxlength="11"
                  ></el-input>
                  <span v-show="page!=1">{{studentRefundInfo.bankUserPhone}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="page!=1">
              <el-col :span="12">
                <el-form-item label="申请时间" prop="createTime">
                  <span >{{studentRefundInfo.createTime}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="page!=1">
              <el-col :span="12">
                <el-form-item label="打款时间" prop="educationalApprovalTime">
                  <span >{{studentRefundInfo.educationalApprovalTime}}</span>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>

    </div>
     <span slot="footer" class="dialog-footer" v-show="page==1">
        <el-button
          type="primary"
          size="small"
          @click="submitRecharge('rechargeForm')"
          >提交审核</el-button
        >
         <el-button
          type="primary"
          size="small"
          @click="$emit('close')"
          >取消</el-button
        >
         <el-button
          type="primary"
          size="small"
          @click="previewClick()"
          >预览</el-button
        >
      </span>
       <span slot="footer" class="dialog-footer" v-show="page==2&&visibleNum==1">
        <el-button
          type="primary"
          size="small"
          @click="examineRefund(1)"
          >通过</el-button
        >
         <el-button
          type="primary"
          size="small"
          @click="examineRefund(2)"
          >驳回</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-show="page==3">
        <el-button
          type="primary"
          size="small"
          @click="editRefund('rechargeForm')"
          >确定</el-button
        >
         <el-button
          type="primary"
          size="small"
           @click="$emit('close')"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
         @click="previewClick()"
          >预览</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
        title="退费"
        center
        :close-on-click-modal="false"
        width="800px"
        :visible.sync="dialogStatus"
    >
    <div>
        <p class="title_p"><span></span><span>学员信息</span></p>
        <p class="student_info"><span>{{studentInfo.name}}</span><span>{{studentInfo.sex==1?'男':studentInfo.sex==2?'女':''}}</span><span>{{studentInfo.phoneNo}}</span></p>
        <p class="title_p"><span></span><span>充值记录</span></p>
        <el-table
            size="small"
            :data="xmtOrderList"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
           
        >
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
            prop="orderSourceRealName"
            label="缴费金额"
            align="center"
           >
        </el-table-column>
        <el-table-column
            prop="zdyGiveClassHour"
            label="赠送课时"
            align="center"
            width="180">
        </el-table-column>
         <el-table-column
            prop="basicPrice"
            label="收款类型"
            align="center"
            :formatter="formatterMoneyType"
            width="180">

        </el-table-column>
         <el-table-column
            prop="createTime"
            label="充值时间"
            align="center"
            width="180">
        </el-table-column>
        </el-table>
        <p class="total_p">总计 缴费金额：{{xmtOrderMoney}}元</p>
        <p class="title_p" style="margin-top:20px;"><span></span><span>课消记录</span></p>
                <el-table
                size="small"
                :data="classUsedOverview"
                style="width: 100%;margin-top:10px;"
                row-key="roleId"
                border
                tooltip-effect="dark"
                ref="customDataTable"
                v-show="activeName=='first'"
            >
            <el-table-column
                prop="no"
                :formatter="dealjibie"
                label="课程级别"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usedClassHour"
                label="已上课时"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="singlePrice"
                label="单价"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usedClassHour"
                label="总课耗"
                align="center"
            >
            </el-table-column>
        </el-table>
        <p class="total_p" v-show="activeName=='first'">总计 课耗：{{classUsedViewMoney}}元</p>
             <el-table
                size="small"
                :data="classUsedDetailed"
                style="width: 100%;margin-top:10px;"
                row-key="roleId"
                border
                tooltip-effect="dark"
                ref="customDataTable"
                 v-show="activeName=='second'"
               
            >
            <el-table-column
                prop="name"
                label="科目"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usePrice"
                label="课时数"
                align="center"
            >
            </el-table-column>
            <el-table-column
               :formatter="dealjibie"
                prop="basicPrice"
                label="课程级别"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="singlePrice"
                label="单价"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="usedClassHour"
                label="课耗"
                align="center"
            >
            </el-table-column>
        </el-table>
        <p class="total_p" v-show="activeName=='second'">总计 课耗：{{classUsedDetailedMoney}}元</p>
        <p class="title_p" style="margin-top:20px;"><span></span><span>退款记录</span></p>
        <el-table
            size="small"
            :data="getApprovalList"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="recordSelectionChange"
            @row-click="recordItemClick"
        >
        <el-table-column
            prop="createTime"
            label="退款时间"
            align="center"
           >
        </el-table-column>
        <el-table-column
            prop="realityRefundAmount"
            label="退款金额"
            align="center"
           >
        </el-table-column>
         <el-table-column
            prop="createName"
            label="申请人"
            align="center"
           >
        </el-table-column>
        </el-table>
        <p class="total_p">总计 退款金额：{{getApprovalMoney}}元</p>
        <p class="title_p" style="margin-top:20px;"><span></span><span>本次退款</span></p>
        <el-form
            :model="rechargeForm"
            status-icon
            label-width="100px"
            size="small"
          >
          <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="应退款金额" prop="refundAmount">
                  <span>{{rechargeForm.refundAmount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="其他扣除项" prop="realityUsePrice">
                   <span>{{rechargeForm.realityUsePrice}}</span>
                   <span v-show="rechargeForm.realityUsePrice">{{rechargeForm.poundageType==1?'%':'元'}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="实退款金额" prop="realityRefundAmount">
                  <span>{{rechargeForm.realityRefundAmount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="备注" prop="backReason">
                  <span>{{rechargeForm.backReason}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款姓名" prop="bankUserName">
                  <span>{{rechargeForm.bankUserName}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收款账号" prop="bankAccount">
                  <span>{{rechargeForm.bankAccount}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="开户支行" prop="bankAddress">
                  <span>{{rechargeForm.bankAddress}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="联系方式" prop="bankUserPhone">
                  <span>{{rechargeForm.bankUserPhone}}</span>
                </el-form-item>
              </el-col>
            </el-row>
        </el-form>
    </div>
    </el-dialog>
    </div>
</template>
<script>
import { refundOneToOneInfos,saveOneToOne,getOneToOneInfo,examineOneToOne,updateOneToOne } from "@/api/sales/saleManage";

export default {
    props: ["Visible","visibleStudentId","page","visibleNum","visibleId"],
    data(){
        let check_realityUsePrice = (rule, value, callback) => {
          if (this.rechargeForm.poundageType == 1) {
            if (!value) {
              callback();
            } else if (!/^([0-9]{1,2}$)|(^[0-9]{1,2}\.[0-9]{1,2}$)|100$/.test(value)) {
              callback(new Error("其他扣除项不能大于100%！"));
            } else {
              callback();
            }
          } else {
            callback();
          }
        }
        return{
            dialogStatus:false,
            activeName:'first',
            isCollapse:true,
            rechargeData:[],
            classData:[],
            recordData:[],//退款记录
            studentInfo:{},
            xmtOrderList:[],//充值记录
            xmtOrderMoney:0,
            getApprovalList:[],//退款记录
            getApprovalMoney:0,
            classUsedOverview:[],//概览
            classUsedViewMoney:0,
            classUsedDetailed:[],//详细
            classUsedDetailedMoney:0,
            rechargeForm:{//充值退费
             poundageType:1,
             realityRefundAmount:'',
             studentId:'',
             realityUsePrice:0,
            },
            rechargeRules:{
              realityUsePrice: [{ validator: check_realityUsePrice, trigger: "blur" }],
            },  
            studentRefundInfo:{},//审核查看退费账户信息

        }
    },
    mounted(){
      if(this.page==1){//专员审核
        this.refundOneToOneInfosFn();
      }else{
        this.getOneToOneInfoFn();
      }
    },
    methods:{
        handleClick(){

        },
        previewClick(){//预览
          this.dialogStatus=true;
        },
        submitRecharge(formName){
            this.$refs[formName].validate((valid) => {
                if (valid){
                    this.rechargeForm.studentId=this.visibleStudentId;
                    saveOneToOne(this.rechargeForm).then((res) => {
                        if (res.code == 0 && res.msg == "操作成功！") {
                            this.msgSuccess("操作成功！");
                            this.refundSync = false;
                        } else {
                            this.msgWarn(res.rows);
                            return false;
                        }
                    });
                }
            })
        },
        //修改退费
        editRefund(formName){
            this.$refs[formName].validate((valid) => {
                if (valid){
                    this.rechargeForm.id=this.visibleId;
                    this.rechargeForm.studentId=this.visibleStudentId;
                    updateOneToOne(this.rechargeForm).then((res) => {
                        if (res.code == 0 && res.msg == "操作成功！") {
                            this.msgSuccess("操作成功！");
                            this.refundSync = false;
                            this.$emit('close')
                        } else {
                            this.msgWarn(res.rows);
                            return false;
                        }
                    });
                }
            })
        },
        //扣除项目类型
        poundageChange(){
          if(this.rechargeForm.poundageType==1){//%
                this.rechargeForm.realityRefundAmount=Number(this.rechargeForm.refundAmount)-Number(this.rechargeForm.realityUsePrice)*(Number(this.rechargeForm.refundAmount)/100);
                this.$set(this.rechargeForm)
                console.log(this.rechargeForm.realityRefundAmount)
           }else{
                this.rechargeForm.realityRefundAmount=Number(this.rechargeForm.refundAmount)-Number(this.rechargeForm.realityUsePrice);
                this.$set(this.rechargeForm)
                console.log(this.rechargeForm.realityRefundAmount)
         }  
        },
        realityUseBlur(){//其他扣除项
            if(this.rechargeForm.poundageType==1){//%
                this.rechargeForm.realityRefundAmount=Number(this.rechargeForm.refundAmount)-Number(this.rechargeForm.realityUsePrice)*(Number(this.rechargeForm.refundAmount)/100);
                this.$set(this.rechargeForm)
           }else{
                this.rechargeForm.realityRefundAmount=Number(this.rechargeForm.refundAmount)-Number(this.rechargeForm.realityUsePrice);
                this.$set(this.rechargeForm)
         }  
        },
        //专员审核
        refundOneToOneInfosFn(){
            refundOneToOneInfos({studentId:this.visibleStudentId}).then((res) => {
                if(res.code==0){
                    this.studentInfo=res.rows.studentInfo;
                    this.rechargeForm.refundAmount=res.rows.studentInfo.accountBalance;
                    this.xmtOrderList=res.rows.xmtOrderList;
                    this.xmtOrderList.forEach(ele=>{
                        this.xmtOrderMoney += ele.totalPrice;
                    });
                    //退款记录
                    this.getApprovalList=res.rows.getApprovalList;
                    this.getApprovalList.forEach(ele=>{
                        this.getApprovalMoney += ele.realityRefundAmount;
                    });
                    //概览
                    this.classUsedOverview=res.rows.classUsedOverview;
                    this.classUsedOverview.forEach(ele=>{
                        this.classUsedViewMoney += ele.usePrice;
                    });
                    //详细
                    this.classUsedDetailed=res.rows.classUsedDetailed;
                    this.classUsedDetailed.forEach(ele=>{
                        this.classUsedDetailedMoney += ele.usePrice;
                    });
                }
            });
        },
        getOneToOneInfoFn(){
          getOneToOneInfo({id:this.visibleId}).then((res) => {
              if (res.code == 0) {
                    this.studentRefundInfo=res.rows.studentRefundInfo;
                    this.studentInfo=res.rows.studentInfo;
                    this.rechargeForm=res.rows.studentRefundInfo;
                    this.rechargeForm.poundageType=res.rows.studentRefundInfo.poundageType;
                    this.xmtOrderList=res.rows.xmtOrderList;
                    this.xmtOrderList.forEach(ele=>{
                        this.xmtOrderMoney += ele.totalPrice;
                    });
                    //退款记录
                    this.getApprovalList=res.rows.getApprovalList;
                    this.getApprovalList.forEach(ele=>{
                        this.getApprovalMoney += ele.realityRefundAmount;
                    });
                    //概览
                    this.classUsedOverview=res.rows.classUsedOverview;
                    this.classUsedOverview.forEach(ele=>{
                        this.classUsedViewMoney += ele.usePrice;
                    });
                    //详细
                    this.classUsedDetailed=res.rows.classUsedDetailed;
                    this.classUsedDetailed.forEach(ele=>{
                        this.classUsedDetailedMoney += ele.usePrice;
                    });
              } else {
                  this.msgWarn(res.rows);
                  return false;
              }
          });
        },
        examineRefund(type){
          if(type==1){
              this.$confirm("确定要退费吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                type: "warning",
              })
                .then(() => {
                  examineOneToOne({id:this.visibleId,teacherApproval:1}).then((res) => {
                    if (res.code == 0) {
                      this.msgSuccess("操作成功！");
                    }
                  });
                })
                .catch(() => {});
            }else{
              this.$prompt("请输入驳回原因", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /\S/,
                inputErrorMessage: "驳回原因不能为空！",
                closeOnClickModal: false
              })
                .then( ({ value }) => {
                    examineOneToOne({
                      id: this.visibleStudentId,
                      teacherApproval: 2,
                      teacherApprovalReason: value
                    }).then(res => {
                      if (res.code == 0) {
                        this.msgSuccess("操作成功！");
                      }
                    });
                })
                .catch(() => {});
            }
        },
        dealjibie(row) {  
            var jibie = "";
            if (row.courseLevel == 1) {
                jibie = "初级课程";
            } else if (row.courseLevel == 2) {
                jibie = "中级课程";
            } else if (row.courseLevel == 3) {
                jibie = "高级课程";
            } else if (row.courseLevel == 4) {
                jibie = "特级课程";
            } else {
                jibie = "正常课程";
            }
            return jibie;
        },
        formatterMoneyType(row){
            switch (row.moneyType) {
                case 1:
                return "全款";
                case 2:
                return "定金";
                case 3:
                return "尾款";
                case 4:
                return "特殊订单";
                case 5: 
                return '余额转充值'
                default:
                return "/";
            } 
        },
        handleSelectionChange(){

        },
        roleItemClick(){

        },
        classSelectionChange(){

        },
        classItemClick(){

        },
        recordSelectionChange(){

        },
        recordItemClick(){

        },
    }
}
</script>
<style scoped lang="scss">
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:50px;
      float:right;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
 .title_p{
     display: flex;
     align-items: center;

     span{
         display: inline-block;
     }
     span:nth-child(1){
         width:3px;
         height:20px;
         background: #27C580;
         border-radius: 2px;
     }
     span:nth-child(2){
        color:#222;
        font-size:15px;
        margin-left:15px;
        font-weight: 600;

    }
 }
 .student_info{
     padding:20px 0;
     span:nth-child(1){
         font-weight: 600;
         font-size: 15px;
         color:#000;
     }
     span{
         padding:0 5px;
     }
 }
 .total_p{
     font-weight: 600;
     color:#222;
     padding:10px 0;
 }
</style>