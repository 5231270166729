<template>
<div>
    <el-dialog
        title="阶梯模式设置"
        center
        width="800px"
        :close-on-click-modal="false"
        :visible="Visible"
        @close="$emit('close')"
    >
      <div class="dialog-contents">
        <p class="stuInfo">
            <span>学生姓名：{{studentInfo.studentName}}</span>
            <span>年级：{{studentInfo.sectionName}}</span>
        </p>
        <div class="btn-div">
          <el-button-group style="float:right;">
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
           <el-button type="default" size="small" @click="enableClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang" />
            </svg>
            <span>启用</span>
          </el-button>
          <el-button type="default" size="small" @click="disableClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-bofang-copy" />
            </svg>
            <span>禁用</span>
          </el-button>
          <el-button type="default" size="small" @click="recordClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>历史记录</span>
          </el-button>
        </el-button-group>
        </div>
        <div class="table-area">
        <el-table
            size="small"
            :data="customData"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="handleSelectionChange"
            @row-click="roleItemClick"
        >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
            prop="no"
            label="单价编号"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="basicPrice"
            label="基本单价"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="阶梯价格">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="seePrice(scope.row)" >查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" >{{scope.row.status==0?'已禁用':'已启用'}}</el-button>
          </template>
        </el-table-column>
        </el-table>
        </div>
        <div style="display:flex;align-items: center;justify-content: center;padding:15px 0;">
           <el-button type="primary" size="small" @click="enableModel()" :disabled="enableBtnShow">启用阶梯模式</el-button>
            <el-button type="primary" size="small" @click="$emit('close')">返回</el-button>
        </div>
         <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="customTotal"
        background
      ></el-pagination>
    </div>
      </div>
    </el-dialog>
    <el-dialog
        :title="priceParams.title"
        center
        width="500px"
        :visible.sync="addShow"
        :close-on-click-modal="false"
    >
        <div>
            <el-form
          :model="addParams"
          status-icon
          :rules="saveRules"
          ref="addParams"
          label-width="100px"
          size="small"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="基本单价" prop="basicPrice">
                <el-input
                  style="width:90px;"
                  v-model="addParams.basicPrice"
                ></el-input>
                <span style="padding:0 3px;">元/课时</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="单价差值" prop="priceDifference">
                <el-input
                  style="width:90px;"
                  v-model="addParams.priceDifference"
                  @blur="priceDifferenceBlur()"
                ></el-input>
                <span style="padding:0 3px;">元</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
                <el-form-item  prop="addParams" style="color:red;">
                    <span slot="label">
                        <span style="font-weight:600;">阶梯价格</span>
                    </span>
                    <el-button @click="addLadderPrice()" type="primary" size="small" >新增</el-button>
                </el-form-item>
            </el-col>
          </el-row>
          <div v-for="(item,index) in addParams.listXmtStudentUnitPriceGrandson" :key="index">
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item :label="'课时范围'+`${index+1}`" prop="addParams">
                <el-input
                  style="width:90px;"
                  v-model="item.begin"

                  @blur="ladderPriceBlur(item,index)"
                ></el-input>
                <span style="padding:0 3px;">至</span>
                <el-input
                  style="width:90px;"
                  v-model="item.end"
                  @blur="ladderPriceBlur(item,index)"
                ></el-input>
                <span style="padding:0 3px;">课时</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="课时单价" prop="addParams">
                <el-input
                  style="width:90px;"
                  v-model="item.unitPrice"
                ></el-input>
                <span style="padding:0 5px;">元/课时</span>
                <el-button type="danger" size="small" @click="removePrice(index)">删除</el-button>
              </el-form-item>
            </el-col>
          </el-row>
           
          </div>
         
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('addParams')"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
        title="价格查看"
        center
        width="400px"
        :visible.sync="seePriceShow"
        :close-on-click-modal="false"
    >
      <div>
        <div class="seePrice-div">
          <p>基本单价：{{priceInfoParams.basicPrice}}元/课时</p>
        <ul>
          <li v-for="(item,index) in priceInfoParams.listXmtStudentUnitPriceGrandson" :key="index">
            <p>课时范围{{index+1}}：{{item.begin}}至{{item.end}}课时</p>
            <p>课时单价：{{item.unitPrice}}元/课时</p>
          </li>
        </ul>
        </div>
      </div>
     </el-dialog>
     <el-dialog
        title="历史记录"
        center
        width="800px"
        :visible.sync="recordShow"
        :close-on-click-modal="false"
    >
      <div class="dialog-content">
          <div class="table-area">
            <el-table
              size="small"
              :data="recordList"
              style="width: 100%; "
              row-key="id"
              border
              tooltip-effect="dark"
            >
              <el-table-column
                prop="no"
                label="单价编号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="basicPrice"
                label="基本单价"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sectionName"
                label="阶梯价格"
                align="center"
              >
               <template slot-scope="scope">
                  <el-button type="text" size="small" @click="seePrice(scope.row)" >查看</el-button>
               </template>
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div class="page-area">
            <el-pagination
              @size-change="recordSizeChange"
              @current-change="recordCurrentChange"
              :current-page="recordParams.pageNumber"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="recordParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordTotal"
              background
            ></el-pagination>
          </div>
      </div>
     </el-dialog>
</div>
    

</template>
<script>
import {
    studentUnitPriceChildList,//列表
    studentUnitPriceChildaSave,//新增
    studentUnitPriceChildaUpdate,//修改
    studentUnitPriceChildaRemove,//删除
    setCustomPriceStatu,//启用禁用
    studentUnitPriceChildInfo,
    selectModelInfoByStudentId,
    setType
 } from '@/api/finance/finance.js'
export default {
    props: ["studentInfo","Visible"],
    watch: {
    "studentInfo.studentId"(show) {
      if (show) {
        this.params.studentId=this.studentInfo.studentId;
        this.addParams.studentId=this.studentInfo.studentId;
        this.recordParams.studentId=this.studentInfo.studentId;
        this.studentUnitPriceChildListFn();
      }
    },
    Visible: function (val, oldVla) {
      if (this.$refs["addParams"] !== undefined) {
        this.$refs["addParams"].resetFields();
      }
    },
  },
    data(){
        return{
            recordList:[],
            recordShow:false,
            priceInfoParams:{},
            seePriceShow:false,
            customData:[],
            customTotal:0,
            dialogVisible:false,
            params:{
                pageNumber:1,
                pageSize:10,
                studentId:"",
                type:"1",
                delFlag:"0"
            },
            priceParams:{
              title:'新增单价',
              type:'add'
            },
            addShow:false,
            addParams:{
                basicPrice:"",//基本单价
                priceDifference:"",//单价差值
                studentId:"",//学生Id
                type:1,
                listXmtStudentUnitPriceGrandson:[
                    // {
                    //     begin:"",
                    //     end:"",
                    //     unitPrice:""
                    // }
                ]
            },
            rowIdList:[],
            editInfo:[],
            recordTotal:0,
            saveRules:{
              basicPrice: [
                { required: true, message: "基本单价不能为空", trigger: "blur" },
              ],
             
            },
            noList:[],
            enableBtnShow:false,
            recordParams:{
                pageNumber:1,
                pageSize:10,
                studentId:"",
                type:"1",
                delFlag:"1"
            },
        }
    },
    created(){
      this.params.studentId=this.studentInfo.studentId;
        this.addParams.studentId=this.studentInfo.studentId;
        this.recordParams.studentId=this.studentInfo.studentId;
        this.studentUnitPriceChildListFn();
    },
    mounted(){
        
    },
    methods:{
        enableModel(){
          selectModelInfoByStudentId({studentId:this.studentInfo.studentId,type:'1'}).then(res => {
            if(res.code==0){
              this.$confirm(`${res.rows}`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  setType({id:this.studentInfo.id,type:'1'}).then((res) => {
                    if (res.code == 0&&res.msg=='操作成功！') {
                      this.msgSuccess("操作成功！");
                      this.enableBtnShow=true;
                      this.$emit('refreshList')
                    }else{
                      this.msgWarn(res.rows);
                    }
                  });
                })
                .catch(() => {});
            }
          });
        },
        seePrice(row){
          this.seePriceShow=true;
          studentUnitPriceChildInfo(row.id).then(res => {
            if(res.code==0){
              this.priceInfoParams=res.rows;
            }
          });
        },
        studentUnitPriceChildListFn(){
            studentUnitPriceChildList(this.params).then(res => {
                this.customData=res.rows;
                this.customTotal=res.total;
            });
        },
        removePrice(index){
          this.addParams.listXmtStudentUnitPriceGrandson.splice(this.addParams.listXmtStudentUnitPriceGrandson.length-1,1)
        },
        recordClick(){
          this.recordShow=true;
          this.recordFn();
        },
        recordFn(){
          studentUnitPriceChildList(this.recordParams).then(res => {
              this.recordList=res.rows;
              this.recordTotal=res.total;
          });
        },
        confirmSave(formName){
          var arr=this.addParams.listXmtStudentUnitPriceGrandson;
         for(var i=0;i<arr.length;i++){
            if(i < arr.length - 1){
              if(Number(arr[i].end)+1!=Number(arr[i+1].begin)){
                this.msgWarn('课时范围'+Number(i+2)+'课时配置不正确！');
                return;
              }
            }
          }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addParams.studentId=this.studentInfo.studentId;
                    if(this.priceParams.type=='add'){
                      studentUnitPriceChildaSave(this.addParams).then((res) => {
                        if (res.code == 0&&res.msg=='操作成功！') {
                            this.addShow=false;
                            this.msgSuccess(res.msg);
                            this.studentUnitPriceChildListFn();
                        }else{
                          this.msgWarn(res.rows);
                        }
                    });
                    }else if(this.priceParams.type=='edit'){
                      studentUnitPriceChildaUpdate(this.addParams).then((res) => {
                          if (res.code == 0&&res.msg=='操作成功！') {
                              this.addShow=false;
                              this.msgSuccess("操作成功！");
                              this.studentUnitPriceChildListFn();
                          }else{
                            this.msgWarn(res.rows);
                          }
                      });
                    }
                    
                }else {
                    this.msgWarn("必填字段不能为空！");
                    return false;
                }
            })
        },
        //新增阶梯价格
        addLadderPrice(){
            var re = /^[0-9]+.?[0-9]*/;
            if(!re.test(this.addParams.priceDifference)||!re.test(this.addParams.basicPrice)){
                this.msgWarn("请输入正确单价格式！");
                return;
            }
            if((Number(this.addParams.basicPrice)>Number(this.addParams.priceDifference))&&this.addParams.listXmtStudentUnitPriceGrandson.length==0){
              this.addParams.listXmtStudentUnitPriceGrandson.push({
                begin:1,
                end:103,
                unitPrice:this.addParams.basicPrice
              })
            }
            else if(this.addParams.listXmtStudentUnitPriceGrandson.length){
              this.addParams.listXmtStudentUnitPriceGrandson.push({
                begin:this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].end+1,
                end:this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].end+100,
                unitPrice:(this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].unitPrice-this.addParams.priceDifference).toFixed(2)
              })
            }
            else{
              this.msgWarn("单价差值不能大于基本单价！");
              return;
            }
        },
        ladderPriceBlur(item,index){
         
        },
        handleSizeChange(pageSize){
          this.params.pageSize = pageSize;
          this.studentUnitPriceChildListFn();
        },
        handleCurrentChange(currentPage){
          this.params.pageNumber = currentPage;
          this.studentUnitPriceChildListFn();
        },
        recordSizeChange(pageSize){
          this.recordParams.pageSize = pageSize;
          this.recordFn();
        },
        recordCurrentChange(currentPage){
          this.recordParams.pageNumber = currentPage;
          this.recordFn();
        },
        priceDifferenceBlur(){
          //  this.addParams.listXmtStudentUnitPriceGrandson[0].unitPrice=this.addParams.basicPrice;
          
          // console.log(this.addParams.priceDifference,'this.addParams.priceDifference//')
          // if(this.addParams.priceDifference){
          //   for(var i=1;i<this.addParams.listXmtStudentUnitPriceGrandson.length;i++){
          //     this.addParams.listXmtStudentUnitPriceGrandson[i].unitPrice=
          //     Number(this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].unitPrice)
          //     -Number(this.addParams.priceDifference);
              
          //   }
          // }else{
          //   for(var i=0;i<this.addParams.listXmtStudentUnitPriceGrandson.length;i++){
          //     this.addParams.listXmtStudentUnitPriceGrandson[i].unitPrice=0;
          //   }
          // }
        },
        closeListClick(){
     
          this.$emit("studentListParamsChange");
        },
        
        handleSelectionChange(val){
          this.rowIdList=[];
          this.editInfo=[];
          if(val.length>0){
            for (let i = 0, len = val.length; i < len; i++) {
              this.rowIdList.push(val[i].id);
              this.editInfo.push(val[i])
              this.noList.push(val[i].no);
            }
          }
        },
        roleItemClick(row){
          // this.$refs.customDataTable.toggleRowSelection(row);
        },
        addClick(){
            this.priceParams={
              title:'新增单价',
              type:'add'
            }
            this.addParams={
                basicPrice:"",//基本单价
                priceDifference:"",//单价差值
                studentId:"",//学生Id
                type:1,
                listXmtStudentUnitPriceGrandson:[]
            },
            this.addShow=true;
        },
        editClick(){
          this.priceParams={
              title:'编辑单价',
              type:'edit'
            }
          if (this.editInfo.length > 1) {
            this.msgWarn("请选择单个数据进行编辑！");
            return;
          }
          if (this.editInfo.length == 0) {
            this.msgWarn("请选择一个数据进行编辑！");
            return;
          }
          this.addShow=true;
          this.addParams=this.editInfo[0];
          
        },
        //是否启用禁用
        isEnableClick(row){
          let data={
            id:this.studentInfo.id,
            childId:row.id,
            status:row.status==0?1:0,
            no:row.no,
          }
          setCustomPriceStatu(data).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentUnitPriceChildListFn();
            }
          });
        },
        delClick(){
          if (this.rowIdList.length == 0) {
            this.msgWarn("请至少选择一条数据进行删除！");
            return;
          }
          this.$confirm("确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              studentUnitPriceChildaRemove(this.rowIdList).then((res) => {
                if (res.code == 0&&res.msg=='操作成功！') {
                  this.msgSuccess("操作成功！");
                  this.studentUnitPriceChildListFn();
                }else{
                  this.msgWarn(res.rows);
                }
              });
            })
            .catch(() => {});
        },
        enableClick(){//启用
          if (this.rowIdList.length == 0||this.rowIdList.length >1) {
            this.msgWarn("请选择一条数据！");
            return;
          }
          let data={
            id:this.studentInfo.id,
            childId:this.rowIdList[0],
            status:1,
            no:this.noList[0],
            studentId:this.studentInfo.studentId
          }
          setCustomPriceStatu(data).then((res) => {
            if (res.code == 0&&res.rows) {
              this.msgSuccess(res.rows);
              this.studentUnitPriceChildListFn();
            }else{
              this.msgSuccess(res.msg);
              this.studentUnitPriceChildListFn();
            }
          });
        },
        disableClick(){
          if (this.rowIdList.length == 0||this.rowIdList.length >1) {
            this.msgWarn("请选择一条数据！");
            return;
          }
          let data={
            id:this.studentInfo.id,
            childId:this.rowIdList[0],
            status:0,
            no:this.noList[0],
            studentId:this.studentInfo.studentId
          }
          setCustomPriceStatu(data).then((res) => {
            if (res.code == 0&&res.rows) {
              this.msgSuccess(res.rows);
              this.studentUnitPriceChildListFn();
            }else{
               this.msgSuccess(res.msg);
               this.studentUnitPriceChildListFn();
            }
          });
        },
    }
}
</script>
<style scoped lang="scss">
    .stuInfo{
        display: flex;
        font-size:16px;
        span:nth-child(2){
            margin-left:60px;
        }
    }
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:30px;
      float:right;
      margin-top:20px;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
    
</style>