<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
        <div class="select-condition-item">
            <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            >
            <el-menu-item index="1">充值收入</el-menu-item>
            <el-menu-item index="2">产品收入</el-menu-item>
            </el-menu>
        </div>
    </div>
    <div class="btn-area" v-show="keyValue==2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品类型：</span>
          <el-select
            v-model="listParams.orderType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">付款渠道：</span>
          <el-select
            v-model="listParams.orderSourceFlag"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderSourceFlagList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">添加时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
      </div>
      <div class="right">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,getOrderList())"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="importExcel"
          >导出excel</el-button
        >
      </div>
    </div>
    <div class="btn-area" v-show="keyValue==2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="listParams.sectionId"
            placeholder="请选择"
            size="small"
            multiple
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">订单提交人：</span>
          <el-select
            v-model="orderSubmitterProduct"
            placeholder="请选择小组"
            size="small"
            multiple
            collapse-tags
            @change="productBlur()"
          >
            <el-option
              v-for="item in orderSourceIdList"
              :key="item.orgId"
               :label="`${item.name}`"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="orderSourceId2"
            placeholder="请选择人员"
            size="small"
            style="margin-left:10px;"
            collapse-tags
            multiple
            @change="orderSourceId2Change()"
          >
            <el-option
              v-for="item in personnelList2"
              :key="item.userId"
               :label="`${item.name}`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btn-area" v-show="keyValue==1">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="rechargeParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">付款渠道：</span>
          <el-select
            v-model="rechargeParams.orderSourceFlag"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderSourceFlagList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">添加时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange2"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
      </div>
      <div class="right">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(rechargeParams.pageNumber=1,productRevenueForOtoFn())"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="importRechargeExcel"
          >导出excel</el-button
        >
      </div>
    </div>
    <div class="btn-area" v-show="keyValue==1">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">年级：</span>
          <el-select
            v-model="rechargeParams.sectionId"
            placeholder="请选择"
            size="small"
            multiple
          >
            <el-option
              v-for="item in sectionList"
              :key="item.macroId"
              :label="item.name"
              :value="item.macroId"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">订单提交人：</span>
          <el-select
            v-model="orderSubmitterRecharge"
            placeholder="请选择小组"
            size="small"
            multiple
            collapse-tags
            @change="rechargeChange()"
          >
            <el-option
              v-for="item in orderSourceIdList"
              :key="item.orgId"
               :label="`${item.name}`"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="orderSourceId1"
            placeholder="请选择人员"
            size="small"
             collapse-tags
            style="margin-left:10px;"
            multiple
            @change="orderSourceId1Change()"
          >
            <el-option
              v-for="item in personnelList"
              :key="item.userId"
               :label="`${item.name}`"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <p style="padding-bottom:10px;" v-show="keyValue==2">
      <span style="padding:0 10px;">试听课总额：{{orderTotal}}元</span>
      <span style="padding:0 10px;">一对多总额：{{moreTotal}}元</span>
      <span style="padding:0 10px;">订单总数量：{{allQuantity}}</span>
      <span style="padding:0 10px;">订单总额：{{allTotal}}元</span>
    </p>
    <p style="padding-bottom:10px;" v-show="keyValue==1">
      <span style="padding:0 10px;">充值记录：{{rechargeTotal}}条</span>
      <span style="padding:0 10px;">缴费金额：{{totalCount}}元</span>
    </p>
    <div class="table-area" v-show="keyValue==2">
      <el-table
        size="small"
        :data="orderList"
        style="width: 100%; margin-bottom: 20px;"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60">
        </el-table-column>
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterProductType"
          label="产品类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterClassHour"
          label="总课时"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterTotalPrice"
          label="总价"
          align="center"
        ></el-table-column>
        
        <el-table-column
          label="实际支付"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.zdyActualPrice ? scope.row.zdyActualPrice : scope.row.totalPrice}}元</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="saleName"
          label="订单提交人"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.orderSourceName}}（{{scope.row.orderSourceRealName}}）</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderSourceFlag"
          :formatter="formatterOrderSource"
          label="付款渠道"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-area" v-show="keyValue==1">
      <el-table
        size="small"
        :data="rechargeList"
        style="width: 100%; margin-bottom: 20px;"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60">
        </el-table-column>
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="totalPrice"
          label="缴费金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="zdyGiveClassHour"
          label="赠送课时"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          label="订单提交人"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.orderSourceName}}（{{scope.row.orderSourceRealName}}）</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderSourceFlag"
          :formatter="formatterOrderSource"
          label="付款渠道"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealOrderDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="keyValue==2">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <div class="page-area" v-show="keyValue==1">
      <el-pagination
        @size-change="rechargeSizeChange"
        @current-change="rechargeCurrentChange"
        :current-page="rechargeParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="rechargeParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="rechargeTotal"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="detailDialogVisible"
      width="900px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="detail_type">订单概况</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>学生姓名：</span>
              <span>{{ studentDetail.name }}</span>
            </el-col>
            <el-col :span="8">
              <span>联系电话：</span>
              <span>{{
                studentDetail.phoneNo ? studentDetail.phoneNo : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>年级：</span>
              <span>{{
                studentDetail.sectionName
                  ? studentDetail.sectionName
                  : "暂无数据"
              }}</span>
            </el-col>
            
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
             <el-col :span="8">
              <span>产品类型：</span>
              <span>{{
               productTypeFn(orderDetail)
              }}</span>
            </el-col>
           <el-col :span="8">
              <span>订单编号：</span>
              <span>{{ orderDetail.orderNo }}</span>
            </el-col>
            <el-col :span="8">
              <span>付款渠道：</span>
              <span v-show="orderDetail.orderSourceFlag==''">暂无数据</span>
              <span v-show="orderDetail.orderSourceFlag==1">前台订单</span>
              <span v-show="orderDetail.orderSourceFlag==2">后台订单</span>
            </el-col>
           
          </el-row>
           <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
              <el-col :span="8">
              <span>产品名称：</span>
              <span>{{
                orderDetail.productName ? orderDetail.productName : "暂无数据"
              }}</span>
            </el-col>
             <el-col :span="8">
              <span>归属部门：</span>
              <span>{{
                orderDetail.orgName ? orderDetail.orgName : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>下单时间：</span>
              <span>{{ orderDetail.createTime }}</span>
            </el-col>
           </el-row>
           <el-row :gutter="20" class="row_detail" type="flex" align="bottom" v-show="orderDetail.productTypeName!='公开课'">
            <el-col :span="8">
              <span>总课时：</span>
              <span>{{ infoClassHour(orderDetail)}}</span>
            </el-col>
            <el-col :span="8">
              <span>单价：</span>
              <span>{{infoSinglePrice(orderDetail)}}</span>
            </el-col>
             <el-col :span="8">
              <span>总价：</span>
              <span>{{infoTotalPrice(orderDetail)}}</span>
            </el-col>
           </el-row>
           <el-row :gutter="20" class="row_detail" type="flex" align="bottom" v-show="(orderDetail.productType==1 || orderDetail.productType==3) && orderDetail.isSubscribe == 1">
            <el-col :span="8">
              <span>单价：{{orderDetail.zdySinglePrice ? orderDetail.zdySinglePrice : orderDetail.singlePrice}}元</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：{{orderDetail.zdyClassHour ? orderDetail.zdyClassHour : orderDetail.classHour}}个</span>
            </el-col>
            <el-col :span="8">
              <span>总金额：{{orderDetail.totalPrice}}元</span>
            </el-col>
           </el-row>
           <el-row :gutter="20" class="row_detail" type="flex" align="bottom" v-show="(orderDetail.productType==1 || orderDetail.productType==3) && orderDetail.isSubscribe == 1">
            <el-col :span="8">
              <span>优惠金额：{{orderDetail.zdyDiscountAmount ? orderDetail.zdyDiscountAmount : 0}}元</span>
            </el-col>
            <el-col :span="8">
              <span>赠送课时：{{orderDetail.zdyGiveClassHour ? orderDetail.zdyGiveClassHour : 0}}个</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：{{orderDetail.zdyActualPrice ? orderDetail.zdyActualPrice : orderDetail.totalPrice}}元</span>
            </el-col>
           </el-row>
           <el-row :gutter="20" class="row_detail" type="flex" align="bottom" >
              <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{
                orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+orderDetail.orderSourceRealName+'）' : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8" v-show="orderDetail.productTypeName=='一对多'">
              <span>优惠金额：{{orderDetail.zdyDiscountAmount ? orderDetail.zdyDiscountAmount : 0}}元</span>
            </el-col>
            <el-col :span="8" v-show="orderDetail.productTypeName=='一对多'">
              <span>实际支付：{{orderDetail.zdyActualPrice}}元</span>
            </el-col>
           </el-row>
        </div>
        <div class="detail_type">上课记录</div>
        <div class="table-area">
      <el-table
        size="small"
        :data="recordList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学科"
          :formatter="dealSubjectId"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="课程标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="上课时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teacherName"
          label="讲师"
          align="center"
        ></el-table-column>
        <el-table-column
          label="课程级别"
          align="center"
          :formatter="formatterPlanCourseLevel"
        ></el-table-column>
        <el-table-column
          prop="learnFee"
          label="课时单价"
          align="center"
           :formatter="formatterLearnFee"
        >
        
        </el-table-column>
        <el-table-column
         prop="planTeachFee"
          label="讲师课酬"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="上课状态"
          :formatter="attendClassStatusList"
          align="center"
        ></el-table-column>
        <el-table-column
              prop=""
              label="课程状态"
              align="center"
            >
              <template slot-scope="scope">
                  <span v-if="scope.row.abnormalStatus==0">正常</span>
                  <span v-else style="color:red;">异常</span>
              </template>
            </el-table-column>
      </el-table>
      <p class="table_info">
        <span>已排{{countMap.yipaike}}课时</span>
        <span>已上{{countMap.yishangke}}课时</span>
        <span>上课中{{countMap.shangkezhong}}课时</span>
        <span>未上{{countMap.weishangke}}课时</span>
      </p>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="recordhandleSizeChange"
        @current-change="recordhandleCurrentChange"
        :current-page="recordParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="recordParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="recordTotal"
        background
      ></el-pagination>
    </div>
      </div>
    </el-dialog>
    <orderInfo ref="orderInfo" :orderInfoStatus="orderInfoStatus"  :orderInfoParams="orderInfoParams" @CB_orderInfoShow="CB_orderInfoShow"></orderInfo>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { financeOrderList, orderAgree,getOrgList,getUserListByOrgIds } from "@/api/finance/finance.js";
import { orderDetail, orderUpdate, productRevenue,studentClassRecord,orderCountForList,productRevenueForOto } from "@/api/sales/myCustomer.js";
import { studentInfo,orderCreatName } from "@/api/sales/saleManage.js";
import { getSelectList, sectionCode,subjectCode } from "@/api/public";
import {
  listNoPage,
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import orderInfo from './dialog/orderInfo.vue';
export default {
  components:{
    orderInfo
  },
  data() {
    return {
      personnelList:[],
      personnelList2:[],
      orderSubmitterRecharge:[],
      orderSubmitterProduct:[],
      orderInfoStatus:false,
      orderInfoParams:{
        id:"",
        studentId:""
      },
      activeIndex:"1",
      recordParams:{
        pageNumber: 1,
        pageSize: 10,
        studentId:'',
        orderId:""
      },
      recordTotal:0,
      recordList:[],
      srecordStudentId:'',
      srecordOrderId:'',
      srcList:[],
      orderList: [],
      orderCountParams:{
        name:"",
        orderType:"1",
        orderSourceFlag:"0",
        courseTypeId:"0",
        sectionId:"0",
        searchBeginDate:null,
        searchEndDate:null
      },
      countMap:{
        shangkezhong:0,
        weishangke:0,
        yipaike:0,
        yishangke:0,
      },
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        name: "",
        orderType: 0,
        orderSourceFlag: 0,
        courseTypeId: 0,
        sectionId: [],
        searchBeginDate: null,
        searchEndDate: null,
        dataAuthority:"dept"
      },
      rechargeParams: {
        // orderSourceId:0,
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        name: "",
        orderType: 0,
        orderSourceFlag: 0,
        courseTypeId: 0,
        sectionId: [],
        searchBeginDate: null,
        searchEndDate: null,
        dataAuthority:"dept"
      },
      rechargeList:[],
      rechargeTotal:0,
      totalCount:0,
      orderSourceIdList:[],
      total: 0,
      oneTotal:0,
      orderTotal:0,
      moreTotal:0,
      allQuantity:0,
      allTotal:0,
      timePickerValue: "",
      timePickerValue2:"",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: '本月',
            onClick: (picker) => {
              const end = new Date();
              // 获取当前月的第一天
              const start = new Date(end.getFullYear(), end.getMonth(), 1);
              start.setTime(start.getTime());
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: '上月',
            onClick(picker) {
              const oDate = new Date();
              var year = oDate.getFullYear();
              var month = oDate.getMonth();
              var start, end;
              if (month == 0) {
                year--;
                start = new Date(year, 11, 1);
                end = new Date(year, 11, 31);
              } else {
                start = new Date(year, month - 1, 1);
                end = new Date(year, month, 0);
              }
              picker.$emit("pick", [start, end]);
            }
          }
        ],
      },
      sectionList: [
        {
          macroId: 0,
          name: "全部",
        },
      ],
      orderSourceFlagList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "前台订单",
        },
        {
          value: 2,
          label: "后台订单",
        },
      ],
      productTypeList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 3,
          label: "试听课",
        },
        {
          value: 2,
          label: "一对多",
        },
        {
          value: 4,
          label: "公开课",
        },
      ],
      orderStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待财务审核",
        },
        {
          value: 2,
          label: "财务审核拒绝",
        },
        {
          value: 3,
          label: "教务主管分配中",
        },
        {
          value: 4,
          label: "待排课",
        },
        {
          value: 5,
          label: "排课中",
        },
        {
          value: 6,
          label: "已退款",
        },
        {
          value: 7,
          label: "订单已完成",
        },
        {
          value: 8,
          label: "订单作废",
        },
         { 
          value: 9,
          label: "退款中",
        }
      ],
      orderDetail: {},
      studentDetail: {},
      detailDialogVisible: false,
      keyValue:"1",
      orderSourceId1:[],
      orderSourceId2:[]
    };
  },
  inject: ["reload"],
  methods: {
    CB_orderInfoShow(){
      this.orderInfoStatus=false;
    },
    productBlur(){
      this.getUserListByOrgIdsFn(2);
    },
    rechargeChange(){
      this.getUserListByOrgIdsFn(1);
    },
    getUserListByOrgIdsFn(num){
      if(num==1){//充值收入
        if(this.orderSubmitterRecharge.length==0){
          var dataMap = this.orderSourceIdList.map((user) => {
              return user.orgId
            }).join(',')
        }
        getUserListByOrgIds({orgIds:this.orderSubmitterRecharge.length==0?dataMap:this.orderSubmitterRecharge.toString(),"roleSign":"studentAdmin","roleSignAdmin":"studentAdminAdmin","saleSign":"salesAdmin","saleSignAdmin":"sales"}).then((res) => {
            this.personnelList = res;
            let ids = res.map((user) => {
              return user.userId
            }).join(',')
            this.orderSourceId1=ids.split(',').map(Number);
            this.rechargeParams.orderSourceId=ids;
            this.productRevenueForOtoFn();
        });
      }else{//产品收入
      if(this.orderSubmitterProduct.length==0){
          var dataMap = this.orderSourceIdList.map((user) => {
              return user.orgId
            }).join(',')
        }
        getUserListByOrgIds({orgIds:this.orderSubmitterProduct.length==0?dataMap:this.orderSubmitterProduct.toString(),"roleSign":"studentAdmin","roleSignAdmin":"studentAdminAdmin","saleSign":"salesAdmin","saleSignAdmin":"sales"}).then((res) => {
            this.personnelList2 = res;
            let ids = res.map((user) => {
              return user.userId
            }).join(',')
            this.orderSourceId2=ids.split(',').map(Number);
            this.listParams.orderSourceId=ids;
            this.getOrderList();
        });
      }
    },
    orderSourceId1Change(){
      let ids = this.orderSourceId1.map((user) => {
          return user
        }).join(',')
        
        this.orderSourceId1=ids.split(',').map(Number);
        this.rechargeParams.orderSourceId=ids;
        this.productRevenueForOtoFn();
    },
    orderSourceId2Change(){
      let ids = this.orderSourceId2.map((user) => {
          return user
        }).join(',')
        
        this.orderSourceId2=ids.split(',').map(Number);
        this.listParams.orderSourceId=ids;
        this.getOrderList();
    },
    handleSelect(key){
      this.keyValue=key;
      if(key==1){//充值收入
        this.getUserListByOrgIdsFn(1);
        // this.productRevenueForOtoFn();
      }else{//产品收入
        this.getUserListByOrgIdsFn(2);
        // this.getOrderList();
      }
    },
    dealOrderDetail(row){
      this.orderInfoStatus=true;
      this.orderInfoParams={
        id:row.id,
        studentId:row.studentId
      }
    },
    getSectionList() {
      getSelectList({
        parentCode: sectionCode,
      }).then((res) => {
        if (res.code == 0) {
          this.sectionList = res.rows;
          this.sectionList.unshift({
            macroId: 0,
            name: "全部",
          });
        }
      });
    },
    getSubjectList(){
      getSelectList({
        parentCode: subjectCode,
      }).then((res) => {
        if (res.code == 0) {
         console.log(res.rows,'123123...')
        }
      });
    },
    formatterProductType(item) {
      switch (item.productType) {
        case 1:
        case 3:
          return item.isSubscribe == 1 ? "试听课" : "一对一";
        case 2:
          return "一对多";
        case 5:
          return "一对多";
        case 4:
          return "公开课";
      }
    },
    formatterOrderStatus(item) {
      if (item.orderStatus) {
        for (let i = 0, len = this.orderStatusList.length; i < len; i++) {
          if (this.orderStatusList[i].value == item.orderStatus) {
            return this.orderStatusList[i].label;
          }
        }
      }
    },
    recordhandleSizeChange(pageSize){
      this.recordParams.pageSize = pageSize;
      this.studentClassRecordFn();
    },
    recordhandleCurrentChange(currentPage){
      this.recordParams.pageNumber = currentPage;
      this.studentClassRecordFn();
    },
    dealSubjectId(row) {
      var subjectlist = "";
      if (row.subjectId == 185) {
        subjectlist = "全学科";
      } else if (row.subjectId == 23) {
        subjectlist = "语文";
      } else if (row.subjectId == 24) {
        subjectlist = "数学";
      } else if (row.subjectId == 25) {
        subjectlist = "英语";
      } else if (row.subjectId == 101) {
        subjectlist = "物理";
      } else if (row.subjectId == 102) {
        subjectlist = "化学";
      } else if (row.subjectId == 99) {
        subjectlist = "政治";
      } else if (row.subjectId == 100) {
        subjectlist = "生物";
      } else if (row.subjectId == 87) {
        subjectlist = "地理";
      } else if (row.subjectId == 88) {
        subjectlist = "历史";
      } else if (row.subjectId == 103) {
        subjectlist = "科学";
      } else if (row.subjectId == 112) {
        subjectlist = "国学";
      } else if (row.subjectId == 113) {
        subjectlist = "书法";
      } else if (row.subjectId == 114) {
        subjectlist = "吉他";
      } else if (row.subjectId == 115) {
        subjectlist = "绘画";
      } else if (row.subjectId == 116) {
        subjectlist = "德语";
      } else if (row.subjectId == 117) {
        subjectlist = "法语";
      } else if (row.subjectId == 118) {
        subjectlist = "俄语";
      } else if (row.subjectId == 119) {
        subjectlist = "西班牙语";
      } else if (row.subjectId == 183) {
        subjectlist = "其他小语种";
      } else if (row.subjectId == 184) {
        subjectlist = "其他艺术课";
      }
      return subjectlist;
    },
    infoClassHour(item){
      if(item.zdyClassHour != null || item.classHour != null){
        return item.zdyClassHour ? item.zdyClassHour+ '课时' : item.classHour + '课时'
      }
      return ''
    },
    infoSinglePrice(item){
      if(item.zdySinglePrice != null || item.singlePrice != null){
        return item.zdySinglePrice ? item.zdySinglePrice + '元' : item.singlePrice + '元'
      }
      return ''
    },
    infoTotalPrice(item){
      if(item.zdyActualPrice != null || item.totalPrice != null){
        return item.zdyActualPrice ? item.zdyActualPrice+ '元' : item.totalPrice + '元'
      }
      return ''
    },
    productTypeFn(item){
      switch(item.productType){
        case 1: 
        case 3:
          return item.isSubscribe == 1 ? '试听课' : '一对一'
        case 2:
          return '一对多'
        case 4: 
          return '公开课'
        case 5:
          return '一对多'
      }
    },
    formatterOrderSource(item) {
      switch (item.orderSourceFlag) {
        case 1:
          return "前台订单";
        case 2:
          return "后台订单";
        default:
          return "";
      }
    },
    formatterClassHour(item){
      if(item.zdyClassHour != null || item.classHour != null){
        return item.zdyClassHour ? item.zdyClassHour+ '课时' : item.classHour + '课时'
      }
      return ''
    },
    formatterSinglePrice(item){
      if(item.productType == 2 || item.productType == 5){
        return '--'
      }
      if(item.zdySinglePrice != null || item.singlePrice != null){
        return item.zdySinglePrice ? item.zdySinglePrice+ '元' : item.singlePrice + '元'
      }
      return ''
    },
    formatterTotalPrice(item){
      if(item.zdyTotalPrice != null || item.totalPrice != null){
        return item.zdyTotalPrice ? item.zdyTotalPrice + '元': item.totalPrice + '元'
      }
      return ''
    },
    datePickerChange() {
      if (!this.timePickerValue) {
        this.listParams.searchBeginDate = null;
        this.listParams.searchEndDate = null;
        return;
      }
      this.listParams.searchBeginDate = this.timePickerValue[0];
      this.listParams.searchEndDate = this.timePickerValue[1];
    },
    datePickerChange2() {
      if (!this.timePickerValue2) {
        this.rechargeParams.searchBeginDate = null;
        this.rechargeParams.searchEndDate = null;
        return;
      }
      this.rechargeParams.searchBeginDate = this.timePickerValue2[0];
      this.rechargeParams.searchEndDate = this.timePickerValue2[1];
    },
    productRevenueForOtoFn(){
      productRevenueForOto(this.rechargeParams).then((res) => {
        this.rechargeList = res.rows;
        this.totalCount=res.countMap.orderTotalCount?res.countMap.orderTotalCount:0;
        this.rechargeTotal = res.total;
      });
    },
    getOrderList() {
      productRevenue(this.listParams).then((res) => {
        this.orderList = res.rows;
        this.total = res.total;
      });
      this.orderCountParams={
        orderSourceId:this.listParams.orderSourceId,
        name:this.listParams.name,
        courseTypeId:this.listParams.courseTypeId,
        orderSourceFlag:this.listParams.orderSourceFlag,
        orderType:this.listParams.orderType,
        sectionId:this.listParams.sectionId,
        searchBeginDate:this.listParams.searchBeginDate,
        searchEndDate:this.listParams.searchEndDate,
        dataAuthority:"dept",
        sortOrder: "asc"
      }
      orderCountForList(this.orderCountParams).then((res) => {
        if(res.code==0){
          this.oneTotal=res.rows.oneToOnePrice?res.rows.oneToOnePrice:0;
          this.orderTotal=res.rows.appointmentPrice?res.rows.appointmentPrice:0;
          this.moreTotal=res.rows.oneToMorePrice?res.rows.oneToMorePrice:0;
          this.allQuantity=res.rows.orderTotalCount?res.rows.orderTotalCount:0;
          this.allTotal=res.rows.orderTotalPrice?res.rows.orderTotalPrice:0;
          }
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getOrderList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getOrderList();
    },
    rechargeSizeChange(pageSize){
      this.rechargeParams.pageSize = pageSize;
      this.productRevenueForOtoFn();
    },
    rechargeCurrentChange(currentPage){
      this.rechargeParams.pageNumber = currentPage;
      this.productRevenueForOtoFn();
    },
    refreshClick() {
      this.reload();
    },
    dealSeeDetail(item) {
      this.recordParams.studentId=item.studentId;//学生id
      this.recordParams.orderId=item.id;//订单Id
      this.detailDialogVisible = true;
      this.orderDetail = {}
      this.studentDetail = {}
      this.studentClassRecordFn();
      orderDetail(item.id).then((res) => {
        if (res.code == 0) {
          this.orderDetail = res.rows;
          this.srcList.push(res.rows.certificate)
          switch (this.orderDetail.payMent) {
            case 1:
              this.orderDetail.payMentName = "支付宝";
              break;
            case 2:
              this.orderDetail.payMentName = "微信";
              break;
            case 3:
              this.orderDetail.payMentName = "对公账户";
              break;
            case 4:
              this.orderDetail.payMentName = "POS";
              break;
            default:
              this.orderDetail.payMentName = "";
          }
          switch (this.orderDetail.moneyType) {
            case 1:
              this.orderDetail.moneyTypeName = "全款";
              break;
            case 2:
              this.orderDetail.moneyTypeName = "定金";
              break;
            case 3:
              this.orderDetail.moneyTypeName = "尾款";
              break;
            default:
              this.orderDetail.moneyTypeName = "";
          }
          switch (this.orderDetail.orderStatus) {
            case 1:
              this.orderDetail.orderStatusName = "待财务审核";
              break;
            case 2:
              this.orderDetail.orderStatusName = "财务审核拒绝";
              break;
            case 3:
              this.orderDetail.orderStatusName = "教务主管分配中";
              break;
            case 4:
              this.orderDetail.orderStatusName = "待排课";
              break;
            case 5:
              this.orderDetail.orderStatusName = "排课中";
              break;
            case 6:
              this.orderDetail.orderStatusName = "已退款";
              break;
            case 7:
              this.orderDetail.orderStatusName = "订单已完成";
              break;
            case 8:
              this.orderDetail.orderStatusName = "订单作废";
              break;
            case 9:
              this.orderDetail.orderStatusName = "退款中";
              break;
            default:
              this.orderDetail.orderStatusName = "";
          }
          switch (this.orderDetail.productType) {
            case 1:
            case 3:
              this.orderDetail.productTypeName =
                this.orderDetail.isSubscribe == 1 ? "公开课" : "一对一";
              break;
            case 2:
            case 5:
              this.orderDetail.productTypeName = "一对多";
              break;
            case 4:
              this.orderDetail.productTypeName = "公开课";
              break;
            default:
              this.orderDetail.productTypeName = "";
          }
        }
      });
      studentInfo(item.studentId).then((res) => {
        if (res.code == 0) {
          this.studentDetail = res.rows;
          switch (this.studentDetail.sex) {
            case 1:
              this.studentDetail.sexName = "男";
              break;
            case 2:
              this.studentDetail.sexName = "女";
            default:
              this.studentDetail.sexName = "";
          }
          if (this.studentDetail.provinceName) {
            this.studentDetail.address =
              this.studentDetail.provinceName +
              "" +
              this.studentDetail.cityName +
              "" +
              this.studentDetail.countyName;
          }
        }
      });
    },
    studentClassRecordFn(){
      studentClassRecord(this.recordParams).then((res) => {
          this.recordList=res.rows;
          this.countMap=res.countMap;
          this.recordTotal=res.total;
      })
    },
    formatterPlanCourseLevel(row){
      var jibie = "";
      if (row.planCourseLevel == 1) {
        jibie = "初级课程";
      } else if (row.planCourseLevel == 2) {
        jibie = "中级课程";
      } else if (row.planCourseLevel == 3) {
        jibie = "高级课程";
      } else if (row.planCourseLevel == 4) {
        jibie = "特级课程";
      } else {
        jibie = "正常课程";
      }
      return jibie;
    },
    formatterLearnFee(row){
      return Number(row.usePrice) /Number(row.usedClassHour);
    },
    attendClassStatusList(row){
      if(row.attendClassStatus==0){
        return '未上课'
      }else if(row.attendClassStatus==1){
        return '已上课'
      }else if(row.attendClassStatus==2){
        return '上课中'
      }
    },
    formatterMoneyType(item){
      switch(item.moneyType){
        case 1: 
          return '全款'
        case 2: 
          return '定金'
        case 3: 
          return '尾款'
        default:
          return ''
      }
    },
    listNoPageList(){
      let data = {"roleSign":"studentAdmin","roleSignAdmin":"studentAdminAdmin","saleSign":"salesAdmin","saleSignAdmin":"sales"}
      getOrgList(data).then(res => {
        this.orderSourceIdList = res;
        let ids = res.map((user) => {
              return user.orgId
            }).join(',')
        this.orderSubmitterRecharge=ids.split(',').map(Number);
        this.orderSubmitterProduct=ids.split(',').map(Number);
        this.getUserListByOrgIdsFn(1);
      });
    },
    importRechargeExcel(){
      let jsonData = [],
        str = `学生姓名,年级,缴费金额,赠送课时,下单时间,订单提交人,付款渠道\n`;
      for (let i = 0, len = this.rechargeList.length; i < len; i++) {
        jsonData.push({
          studentName: this.rechargeList[i].studentName,
          studentSectionName: this.rechargeList[i].studentSectionName,
          totalPrice: this.rechargeList[i].totalPrice,
          zdyGiveClassHour: this.rechargeList[i].zdyGiveClassHour,
          createTime: this.orderList[i].createTime,
          orderSourceName: this.rechargeList[i].orderSourceName+'('+this.rechargeList[i].orderSourceRealName+')',
          orderSource: this.formatterOrderSource(this.rechargeList[i]),
        });
      }
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      const link = document.createElement("a");
      link.href = uri;
      link.download = "充值收入列表.xls";
      link.click();
    },
    importExcel() {
      let jsonData = [],
        str = `学生姓名,年级,订单编号,产品名称,产品类型,下单时间,订单状态,订单提交人,收款类型\n`;
      for (let i = 0, len = this.orderList.length; i < len; i++) {
        jsonData.push({
          studentName: this.orderList[i].studentName,
          studentSectionName: this.orderList[i].studentSectionName,
          orderNo: this.orderList[i].orderNo,
          productName: this.orderList[i].productName,
          productType: this.formatterProductType(this.orderList[i]),
          createTime: this.orderList[i].createTime,
          orderStatus: this.formatterOrderStatus(this.orderList[i]),
          saleName: this.orderList[i].saleName,
          moneyType: this.formatterMoneyType(this.orderList[i]),
        });
      }
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      const link = document.createElement("a");
      link.href = uri;
      link.download = "产品收入列表.xls";
      link.click();
    },
  },
  created() {
    
  },
   mounted() {
    var date=new Date();
　　var currentMonth=date.getMonth();
　　var nextMonth=++currentMonth;
　　var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
　　var oneDay=1000*60*60*24;
　　var lastTime=new Date(nextMonthFirstDay-oneDay);
　　var month=parseInt(lastTime.getMonth()+1);
　　var day=lastTime.getDate();
　　if(month<10){
　　  month='0'+month
　　}
　　if(day<10){
　　  day='0'+day
　　}
    this.timePickerValue=[date.getFullYear() + "-" + month + "-" + '01'+' 00:00:00',date.getFullYear() + "-" + month + "-" + day+' 23:59:59'];
    this.timePickerValue2=[date.getFullYear() + "-" + month + "-" + '01'+' 00:00:00',date.getFullYear() + "-" + month + "-" + day+' 23:59:59']
    this.listParams.searchBeginDate=this.timePickerValue[0];
    this.listParams.searchEndDate=this.timePickerValue[1];
    this.rechargeParams.searchBeginDate=this.timePickerValue2[0];
    this.rechargeParams.searchEndDate=this.timePickerValue2[1];
    this.listNoPageList();//获取充值
    this.getOrderList();
    this.getSectionList();
    this.getSubjectList();//获取学科
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
    .right{
      width: 200px;
      display: flex;
      justify-content: flex-end;
      /deep/ .el-button+.el-button{
        margin-left: 20px;
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 600;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.table_info{
  float:right;
  span{
    margin-right:15px;
  }

}
</style>
