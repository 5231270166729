<template>
    <div class="main-content sys-user-manage">
      <div class="btn-area">
        <div class="select-condition-item">
            <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            >
            <el-menu-item index="1">使用中</el-menu-item>
            <el-menu-item index="2">未使用</el-menu-item>
            </el-menu>
        </div>
    </div>
    <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">教师姓名：</span>
          <el-input
            v-model="listParams.name"
            placeholder="请输入教师姓名"
            size="small"
          ></el-input>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,getfixedClassPayList())"
          >搜索</el-button
        >
        <div class="select-condition-item"><span class="item-lable" style="color:red;font-size:14px;margin-left:10px;">* 双击单元格设置或修改固定课酬、生效时间</span></div>
        
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="teacherList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
        @cell-dblclick="tableDbEdit"
      >
        <el-table-column
          prop="realname"
          label="教师姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="periodId"
          label="学段"
          align="center"
          :formatter="dealSectionId"
        >
        
        </el-table-column>
        <el-table-column
          prop="subjectName"
          label="科目"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="fixedClassPay"
          label="固定课酬（元/课时）"
          align="center"
        >
        <template slot-scope="scope">
          <el-input v-model="scope.row.fixedClassPay" v-if="showInput == `fixedClassPay${scope.row.id}`"
            @blur='blurInput(scope.row.id,"fixedClassPay",scope.row.fixedClassPay)' v-focus>
          </el-input>
          <p v-else>{{scope.row.fixedClassPay}}</p>
        </template>
        </el-table-column>
        <el-table-column
          prop="fixedClassPayBeginTime"
          label="生效时间"
          align="center"
        >
          <template v-slot="scope">
          <el-date-picker v-model="scope.row.fixedClassPayBeginTime" v-if="showInput == `fixedClassPayBeginTime${scope.row.id}`"
            type="date" :clearable="false" value-format="yyyy-MM-dd" placeholder="请选择开始日期"
            @blur='blurInput(scope.row.id,"fixedClassPayBeginTime",scope.row.fixedClassPayBeginTime)' v-focus>
          </el-date-picker>
          <p v-else>{{scope.row.fixedClassPayBeginTime?scope.row.fixedClassPayBeginTime.substring(0,11):""}}</p>
        </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="aloneSetUp(scope.row)"
              >查看/设置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title=""
      :visible.sync="aloneSetUpShow"
      width="900px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>教师姓名：</span>
              <span>{{teacherParams.realname}}</span>
            </el-col>
            <el-col :span="8">
              <span>学段：</span>
              <span>{{dealPeriodId(teacherParams.periodId)}}</span>
            </el-col> 
            <el-col :span="8">
              <span>科目：</span>
              <span>{{teacherParams.subjectName}}</span>
            </el-col>
          </el-row>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <div ></div>
          <el-button type="primary" size="small" @click="otherDeploy">其他配置</el-button>
        </div>
        <div class="table-area" >
      <el-table
        size="small"
        :data="aloneList"
        style="width: 100%; margin-bottom: 20px;margin-top:10px;"
        row-key="userId"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名/班级名称"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{scope.row.studentName?scope.row.studentName:scope.row.roomName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sectionName"
          label="年级/学段"
          align="center"
        >
          <template slot-scope="scope">
          <div>{{scope.row.sectionName?scope.row.sectionName:scope.row.periodName}}</div>
        </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="课程级别"
          align="center"
          :formatter="formatterPlanCourseLevel"
        ></el-table-column>
        <el-table-column
          prop="teacherManagerRealName"
          label="教务姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          label="课酬类型"
          align="center"
        >
        <template slot-scope="scope">
          <div v-show="scope.row.classPayType==0">固定课酬</div>
          <div v-show="scope.row.classPayType==1" style="color:red;">变动课酬</div>
        </template>
        </el-table-column>
        <el-table-column
          prop="teacherFee"
          label="课酬（元/课时）"
          align="center"
        >
        </el-table-column>
        <el-table-column
         prop="beginTime"
          label="生效时间"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.beginTime?scope.row.beginTime.substring(0,16):""}}
          </template>
        </el-table-column>
        <el-table-column
          prop="stopTime"
          label="结束时间"
          align="center"
        >
          <template slot-scope="scope">
            {{scope.row.stopTime?scope.row.stopTime.substring(0,16):"无限期"}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="setClassPay(scope.row)"
              >设置</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="historyClassClick(scope.row)"
              >历史课酬</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="planClick(scope.row,1)"
              >隐藏</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="aloneHandleSizeChange"
        @current-change="aloneHandleCurrentChange"
        :current-page="aloneParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="aloneParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="aloneTotal"
        background
      ></el-pagination>
    </div>
      </div>
    </el-dialog>
    <el-dialog
      title="历史课酬"
      :visible.sync="historyClassShow"
      width="900px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <p style="padding-bottom:20px;"><span style="">老师姓名：{{teacherParams.realname}}</span><span style="margin-left:25px;">学生姓名/班级名称：{{infoSty}}</span></p>
        <div class="table-area">
      <el-table
        size="small"
        :data="historyClassList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="name"
          label="课酬类型"
          align="center"
        >
          <template slot-scope="scope">
            <div v-show="scope.row.classPayType==0">固定课酬</div>
            <div v-show="scope.row.classPayType==1">变动课酬</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacherFee"
          label="课酬（元/小时）"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="生效时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="stopTime"
          label="结束时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="设置时间"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="historyClassSizeChange"
        @current-change="historyClassCurrentChange"
        :current-page="historyClassParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="historyClassParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="historyClassTotal"
        background
      ></el-pagination>
    </div>
      </div>
    </el-dialog>
    <el-dialog
      title="设置课酬"
      :visible.sync="payShow"
      width="500px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <p style="padding-bottom:20px;"><span style="">老师姓名：{{teacherParams.realname}}</span><span style="margin-left:25px;">学生姓名/班级名称：{{infoSty}}</span></p>
          <el-form
            :model="payRuleForm"
            status-icon
            ref="saveRuleForm"
            label-width="100px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="20">
                <el-form-item label="" prop="">
                  <el-radio v-model="payRuleForm.classPayType" label="0" @change="classPayTypeChange(0)">固定课酬</el-radio>
                  <el-radio v-model="payRuleForm.classPayType" label="1" @change="classPayTypeChange(1)">变动课酬</el-radio>
              </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="payRuleForm.classPayType==1">
              <el-col :span="20">
                <el-form-item label="课酬" prop="teacherFee" :rules="flag ? payeRules.teacherFee : []">
                  <el-input
                    v-model="payRuleForm.teacherFee"
                    style="width:100px;"
                  ></el-input>（元/课时）
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="payRuleForm.classPayType==1">
              <el-col :span="20">
                <el-form-item label="生效时间" prop="beginTime" :rules="flag ? payeRules.beginTime : []">
                  <el-date-picker
                    v-model="beginTimeModel"
                    type="date"
                    @change="beginTimeChange()"
                    :picker-options="startDatePicker"
                    value-format="yyyy-MM-dd"
                    placeholder="选择时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="payRuleForm.classPayType==1">
              <el-col :span="20">
                <el-form-item label="结束时间" prop="stopTime">
                  <el-date-picker
                    v-model="stopTimeModel"
                    type="date"
                    :picker-options="endDatePicker"
                    @change="stopTimeChange()"
                    value-format="yyyy-MM-dd"
                    placeholder="选择时间">
                  </el-date-picker>
                  或者 <el-checkbox v-model="infiniteTime" @change="infiniteChange()">无限期</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
      </div>
      <span slot="footer" class="dialog-footer" >
        <el-button @click="cancelpay()" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="surePay('saveRuleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="其他配置"
      :visible.sync="otherDeployShow"
      width="900px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="table-area">
      <el-table
        size="small"
        :data="otherDeployList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          prop="teacherFee"
          label="学生姓名/班级名称"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{scope.row.studentName?scope.row.studentName:scope.row.roomName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="beginTime"
          label="年级"
          align="center"
        >
          <template slot-scope="scope">
          <div>{{scope.row.sectionName?scope.row.sectionName:scope.row.periodName}}</div>
        </template>
        </el-table-column>
        <el-table-column
          prop="stopTime"
          label="课程级别"
          :formatter="formatterPlanCourseLevel"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teacherManagerRealName"
          label="教务姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="设置时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="planClick(scope.row,0)"
              >恢复</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="otherSizeChange"
        @current-change="otherCurrentChange"
        :current-page="otherParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="otherParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="otherTotal"
        background
      ></el-pagination>
    </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getfixedClassPayList,updatefixedClassPayList,getTeacherClassPayList,setConfigteacherClassPay,configteacherClassPayList,configteacherPlanUpdate } from '@/api/finance/finance.js'
export default {
    data(){
        return{
          otherDeployList:[],
          otherTotal:0,
          otherDeployShow:false,
          beginTimeModel:'',
          stopTimeModel:'',
          activeIndex:"1",
          infoSty:"",
          teacherParams:{
            realname:"",
            subjectName:"",
            periodId:""
          },
            listParams:{
                name:"",
                pageNumber: 1,
                pageSize: 10,
                flag:'true',
            },
            startDatePicker: null,
            endDatePicker: null,
            total:0,
            teacherList:[],
            aloneSetUpShow:false,
            aloneList:[],
            aloneTotal:0,
            aloneParams:{
                teacherId:"",
                pageNumber: 1,
                pageSize: 10,
                ifHide:0
            },
            otherParams:{
              teacherId:"",
              pageNumber: 1,
              pageSize: 10,
              ifHide:1
            },
            infiniteTime:'',
            //历史课酬
            historyClassParams:{
              classroomId:null,
              studentId:"",
              teacherId:"",
              pageNumber: 1,
              pageSize: 10
            },
            historyClassList:[],
            historyClassTotal:0,
            historyClassShow:false,
            showInput:"",
            oldData:{},
            payShow:false,
            payRuleForm:{
              classPayType:"0"
            },
            flag:'true',
            payeRules:{
              teacherFee: [
                { required: true, message: "课酬不能为空", trigger: "blur" },
              ],
              beginTime: [
                { required: true, message: "生效时间不能为空", trigger: "blur" },
              ]
            }
        }
    },
    created(){
      this.getfixedClassPayList();
    },
    mounted(){
      this.startDatePicker = this.beginDate();
      this.endDatePicker = this.processDate();
    },
    methods:{
      planClick(row,type){
        configteacherPlanUpdate({id:row.id,ifHide:type}).then(res => {
          if(type==1){//外层隐藏
            this.getTeacherClassPayListFn();
          }else{//恢复
            this.getTeacherClassPayListFn();
            this.otherListFn();
          }
            
          });
      },
      otherDeploy(){
        this.otherDeployShow=true;
        this.otherListFn();
      },
      otherListFn(){
        getTeacherClassPayList(this.otherParams).then(res => {
          this.otherDeployList=res.rows;
          this.otherTotal=res.total;
        });
      },
      handleSelect(key, keyPath) {
          if(key==1){//使用中
            this.listParams={
                name:"",
                pageNumber: 1,
                pageSize: 10,
                flag:'true',
            }
            this.getfixedClassPayList()
          }else if(key==2){//未使用
            this.listParams={
                name:"",
                pageNumber: 1,
                pageSize: 10,
                flag:'false',
            }
            this.getfixedClassPayList()
          }
      },
      beginDate() {
        const self = this;
        return {
          disabledDate(time) {
            if (self.stopTimeModel) {
              return new Date(self.stopTimeModel).getTime() < time.getTime();
            }
          },
        };
      },
      processDate() {
        const self = this;
        return {
          disabledDate(time) {
            if (self.beginTimeModel) {
              return new Date(self.beginTimeModel).getTime() > time.getTime();
            }
          },
        };
      },
      infiniteChange(){
        if(this.stopTimeModel){
          this.stopTimeModel=null;
        }
      },
      beginTimeChange(){
        this.payRuleForm.beginTime = this.beginTimeModel+" 00:00:00";
      },
      stopTimeChange(){
        if(this.infiniteTime){
          this.infiniteTime='';
        }
        this.payRuleForm.stopTime = this.stopTimeModel+" 23:59:59";
      },
      classPayTypeChange(num){
        num==0?this.flag=false:this.flag=true;
      },
        getfixedClassPayList(){
          getfixedClassPayList(this.listParams).then(res => {
            this.teacherList=res.rows;
            this.total=res.total;
          });
        },
       async blurInput(id, name, value){
        //  if(value==''||value==null){
        //    if(name=='fixedClassPay'){
        //     this.msgWarn("固定课酬不能为空！");
        //     return false;
        //    }else{
        //     this.msgWarn("生效时间不能为空！");
        //     return false;
        //    }
        //  }
          let obj = {}
          if (this.oldData[name] != value&&name=='fixedClassPay') {//课酬
            obj[name] = value //被改变的数据
            let data={
              id:id,
              fixedClassPay:value,
            }
            updatefixedClassPayList(data).then(res => {
              if(res.code==0){
                this.msgSuccess('操作成功！');
                this.getfixedClassPayList();
              }
            });
          }else if(this.oldData[name] != value){
            obj[name] = value //被改变的数据
            let data={
              id:id,
              fixedClassPayBeginTime:value
            }
            updatefixedClassPayList(data).then(res => {
              if(res.code==0){
                this.msgSuccess('操作成功！');
                this.getfixedClassPayList();
              }
            });
          }
          this.showInput = ""
        },
        tableDbEdit(row, column, event) {
          this.showInput = column.property + row.id
          this.oldData[column.property] = row[column.property]
        },
        handleSizeChange(pageSize){
          this.listParams.pageSize = pageSize;
          this.getfixedClassPayList();
        },
        handleCurrentChange(currentPage){
          this.listParams.pageNumber = currentPage;
          this.getfixedClassPayList();
        },
        aloneHandleSizeChange(pageSize){
          this.aloneParams.pageSize = pageSize;
          this.getTeacherClassPayListFn();
        },
        aloneHandleCurrentChange(currentPage){
          this.aloneParams.pageNumber = currentPage;
          this.getTeacherClassPayListFn();
        },
        otherSizeChange(pageSize){
          this.otherParams.pageSize=pageSize;
          this.otherListFn();
        },
        otherCurrentChange(currentPage){
          this.otherParams.pageNumber=currentPage;
          this.otherListFn();
        },
        setClassPay(row){
          this.payShow=true;
          this.flag=false;
          this.infoSty=row.studentName?row.studentName:row.roomName;
          this.payRuleForm={
            configteacherPlanId:row.id,
            studentId:row.studentId,
            classPayType:'0',
            teacherId:row.teacherId,
            teacherFee:"",
            beginTime:null,
            stopTime:null,
            classroomId:row.classroomId
          }
          this.infiniteTime="";
          this.beginTimeModel="";
          this.stopTimeModel="";
        },
        surePay(formName){
          this.$refs[formName].validate((valid) => {
            if(valid){
              if(!this.payRuleForm.stopTime&&!this.infiniteTime&&this.payRuleForm.classPayType=='1'){
                this.msgWarn("结束时间只要选择一项！");
                return false;
              }
              let data={
                configteacherPlanId:this.payRuleForm.configteacherPlanId,
                classroomId:this.payRuleForm.classroomId,
                studentId:this.payRuleForm.studentId,
                teacherId:this.payRuleForm.teacherId,
                classPayType:this.payRuleForm.classPayType,
                teacherFee:this.payRuleForm.teacherFee?this.payRuleForm.teacherFee:null,
                stopTime:this.payRuleForm.stopTime?this.payRuleForm.stopTime:null,
                beginTime:this.payRuleForm.beginTime?this.payRuleForm.beginTime:null
              }
              setConfigteacherClassPay(data).then(res => {
              if(res.code==0){
                this.msgSuccess('操作成功！');
                this.payShow=false;
                this.getTeacherClassPayListFn();
              }
            });
            }else{
              this.msgWarn("必填字段不能为空！");
              return false;
            }
          })
        },
        dealPeriodId(row){
          var str = "";
          var arr2 = new Array();
          if (row) {
            arr2 = row.split(",");
          }
          for (var i = 0; i < arr2.length; i++) {
            if (arr2[i] == 107) {
              str += "高中,";
            } else if (arr2[i] == 106) {
              str += "初中,";
            } else if (arr2[i] == 105) {
              str += "小学,";
            } else if (arr2[i] == 108) {
              str += "艺术培训,";
            } else if (arr2[i] == 109) {
              str += "小语种,";
            } else if (arr2[i] == 235) {
              str += "全学段,";
            }
          }
          return str.substring(0, str.length - 1);
        },
        formatterPlanCourseLevel(row){
            var jibie = "";
            if (row.courseLevel == 1) {
              jibie = "初级课程"; 
            } else if (row.courseLevel == 2) {
              jibie = "中级课程";
            } else if (row.courseLevel == 3) {
              jibie = "高级课程";
            } else if (row.courseLevel == 4) {
              jibie = "特级课程";
            } else {
              jibie = "正常课程";
            }
            return jibie;
          
          },
        //单独设置
        aloneSetUp(row){
            this.aloneParams.teacherId=row.id;
            this.otherParams.teacherId=row.id;
            this.aloneSetUpShow=true;
            this.teacherParams={
              realname:row.realname?row.realname:"",
              subjectName:row.subjectName?row.subjectName:"",
              periodId:row.periodId?row.periodId:""
            }
            this.getTeacherClassPayListFn();
        },
        getTeacherClassPayListFn(){
          getTeacherClassPayList(this.aloneParams).then(res => {
              this.aloneList=res.rows;
              this.aloneTotal=res.total;
            });
        },
        historyClassClick(row){
            this.historyClassShow=true;
            this.historyClassParams.studentId=row.studentId;
            this.historyClassParams.teacherId=row.teacherId;
            this.infoSty=row.studentName?row.studentName:row.roomName;
            this.configteacherClassPayList();
        },
        configteacherClassPayList(){
          configteacherClassPayList(this.historyClassParams).then(res => {
              this.historyClassList=res.rows;
              this.historyClassTotal=res.total;
            });
        },
        historyClassSizeChange(pageSize){
          this.historyClassParams.pageSize = pageSize;
          this.configteacherClassPayList();

        },
        historyClassCurrentChange(currentPage){
          this.historyClassParams.pageNumber = currentPage;
          this.configteacherClassPayList();
        },
        cancelpay(){
          this.payShow=false;
          this.payRuleForm={
            classPayType:"",
            teacherFee:"",
            beginTime:"",
            stopTime:"",
            configteacherPlanId:"",
            classroomId:'',
            studentId:"",
            teacherId:"",
          }
        },
        dealSectionId(row) {
          var str = "";
          var arr2 = new Array();
          if (row.periodId) {
            arr2 = row.periodId.split(",");
          }
          for (var i = 0; i < arr2.length; i++) {
            if (arr2[i] == 107) {
              str += "高中,";
            } else if (arr2[i] == 106) {
              str += "初中,";
            } else if (arr2[i] == 105) {
              str += "小学,";
            } else if (arr2[i] == 108) {
              str += "艺术培训,";
            } else if (arr2[i] == 109) {
              str += "小语种,";
            } else if (arr2[i] == 235) {
              str += "全学段,";
            }
          }
          return str.substring(0, str.length - 1);
        },

    },
    directives: {
    // 通过自定义指令实现的表单自动获得光标的操作
    focus: {
      inserted: function(el) {
        if (el.tagName.toLocaleLowerCase() == "input") {
          el.focus()
        } else {
          if (el.getElementsByTagName("input")) {
            el.getElementsByTagName("input")[0].focus()
          }
        }
        el.focus()
      }
    },
    focusTextarea: {
      inserted: function(el) {
        if (el.tagName.toLocaleLowerCase() == "textarea") {
          el.focus()
        } else {
          if (el.getElementsByTagName("textarea")) {
            el.getElementsByTagName("textarea")[0].focus()
          }
        }
        el.focus()
      }
    }
  },
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content{
    .detail_type{
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
    .row_detail{
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .certificate{
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
>>>.td-img .el-icon-circle-close {
    color: white !important;
  }
  a{text-decoration:none;}
</style>

