<template>
   <el-dialog
        title="特殊课设置"
        center
        width="1000px"
        close-on-press-escape
        :visible.sync="specialViseble"
        @close="closeDialog"
        :close-on-click-modal="false"
    >
    <div>
        <div class="subjectList_div">
            <p>选择学科：</p>
            <ul>
                <li v-for="(item, index) in subjectIdList" :key="index">
                <el-button
                    size="small"
                    type="primary"
                    @click="addCourseList(item.macroId, item.name)"
                    >{{ item.name }}</el-button
                >
                </li>
          </ul>
          <el-table :data="courseData" style="width: 100%">
            <el-table-column
              prop="subjectId"
              label="学科"
              :formatter="dealSubjectId"
            >
            </el-table-column>
            <el-table-column prop="name" label="课程级别">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.courseLevel"
                  placeholder="请选择"
                  @focus="subjectRow(scope.row)"
                  filterable
                  allow-create
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="unit" label="课时单价">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.classSinglePrice"
                  placeholder="请输入内容"
                  maxlength="4"
                 
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="dealDelete(scope.row,scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
            style="display:flex;align-items: center;justify-content: center;margin-top:30px;"
          >
            <el-button size="small" @click="closeDialog"
              >取 消</el-button
            >
            <el-button type="primary" @click="save()" size="small"
              >确 定</el-button
            >
          </div>
    </div>
   </el-dialog>
</template>
<script>
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachTitle,
  fileUpload,
  phaseCode,
  followTags,
  constellation,//星座
} from "@/api/public";
import {
  configureCourseList,
  configureCourseSave,
  configureCourseRemove,
  removeByStudentId,
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
export default {
    props:["specialStatus","specialSid"],
    data(){
        return{
            courseDataLength:0,
            specialViseble:false,
            subjectIdList:[],
            courseData:[],
            options: [
           
            {
                id: 2,
                label: "中级课程"
            },
            {
                id: 3,
                label: "高级课程"
            },
            {
                id: 4,
                label: "特级课程"
            }
        ]
        }
    },
    watch:{
      specialStatus(newValue,oldValue){
        this.specialViseble=newValue;
        if(newValue==true){
            this.configureCourseListFn();
        }
      }
    },
    created(){
        
    },
    mounted(){
        this.getSubjectList();
        this.configureCourseListFn();
    },
    methods:{
        closeDialog(){
          this.$emit('CB_specialStatus')
        },
        configureCourseListFn() {
            var data = {
                studentId: this.specialSid,
                pageNumber: 1,
                pageSize: 1000
            };
            configureCourseList(data).then(res => {
                if (res.rows) {
                this.courseDataLength = res.rows.length;
                this.courseData = res.rows;
                }
            });
        },
        addCourseList(macroId, name) {
            this.courseData.push({
                studentId: this.specialSid,
                subjectId: macroId
            });
        },
        save() {
            if (this.courseDataLength > 0) {
                for (var i = 0; i < this.courseData.length - 1; i++) {
                    for (var j = i + 1; j < this.courseData.length; j++) {
                    if (this.courseData[i].courseLevel === this.courseData[j].courseLevel&&this.courseData[i].subjectId === this.courseData[j].subjectId) {
                        this.msgWarn("同一个课程级别不能设置多个！");
                        return false
                    }
                    }
                }
                for(var i=0;i<this.courseData.length;i++){
                if(this.courseData[i].classSinglePrice==undefined||this.courseData[i].courseLevel==undefined){
                    this.msgWarn("课程级别和课时单价不能为空！");
                    return false;
                }
                }
            
                removeByStudentId(this.specialSid).then(res => {
                if (res.code == 0) {
                    configureCourseSave(this.courseData).then(res => {
                    if (res.code == 0) {
                        this.msgSuccess("操作成功！");
                        this.$emit('CB_specialStatus')
                    }
                    });
                }
                });
            } else {
                for (var i = 0; i < this.courseData.length - 1; i++) {
                    for (var j = i + 1; j < this.courseData.length; j++) {
                    if (this.courseData[i].courseLevel === this.courseData[j].courseLevel) {
                        this.msgWarn("同一个课程级别不能设置多个！");
                        return false
                    }
                    }
                }
                for(var i=0;i<this.courseData.length;i++){
                console.log(this.courseData[i].classSinglePrice,this.courseData[i].courseLevel)
                if(this.courseData[i].classSinglePrice==undefined||this.courseData[i].courseLevel==undefined){
                this.msgWarn("课程级别和课时单价不能为空！");
                return false;
                }
            }
                configureCourseSave(this.courseData).then(res => {
                if (res.code == 0) {
                    this.msgSuccess("操作成功！");
                    this.$emit('CB_specialStatus')
                }
                });
            }
            },
        dealDelete(val,index) {
            if(val.id){
                configureCourseRemove([val.id]).then(res => {
                if (res.code == 0) {
                this.msgSuccess("操作成功！");
                this.configureCourseListFn();
                }
            });
            }else{
                this.courseData.splice(index,1);
            }
        },
        //获取学科
        getSubjectList() {
            return getSelectList({
                parentCode: subjectCode
            }).then(res => {
                if (res.code == 0) {
                res.rows.splice(0,1);
                this.subjectIdList = res.rows;
                }
            });
        },
        subjectRow(row, index) {
            this.options=[
               
                {
                id: 2,
                label: "中级课程"
                },
                {
                id: 3,
                label: "高级课程"
                },
                {
                id: 4,
                label: "特级课程"
                }
            ]
        },
        dealSubjectId(row) {
        var subjectlist=''
        if (row.subjectId == 185) {
            subjectlist = "全学科";
        } else if (row.subjectId == 23) {
            subjectlist = "语文";
        } else if (row.subjectId == 24) {
            subjectlist = "数学";
        } else if (row.subjectId == 25) {
            subjectlist = "英语";
        } else if (row.subjectId == 101) {
            subjectlist = "物理";
        } else if (row.subjectId == 102) {
            subjectlist = "化学";
        } else if (row.subjectId == 99) {
            subjectlist = "政治";
        } else if (row.subjectId == 100) {
            subjectlist = "生物";
        } else if (row.subjectId == 87) {
            subjectlist = "地理";
        } else if (row.subjectId == 88) {
            subjectlist = "历史";
        } else if (row.subjectId == 103) {
            subjectlist = "科学";
        } else if (row.subjectId == 112) {
            subjectlist = "国学";
        } else if (row.subjectId == 113) {
            subjectlist = "书法";
        } else if (row.subjectId == 114) {
            subjectlist = "吉他";
        } else if (row.subjectId == 115) {
            subjectlist = "绘画";
        } else if (row.subjectId == 116) {
            subjectlist = "德语";
        } else if (row.subjectId == 117) {
            subjectlist = "法语";
        } else if (row.subjectId == 118) {
            subjectlist = "俄语";
        } else if (row.subjectId == 119) {
            subjectlist = "西班牙语";
        } else if (row.subjectId == 183) {
            subjectlist = "其他小语种";
        } else if (row.subjectId == 184) {
            subjectlist = "其他艺术课";
        }
        return subjectlist;
        }
    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-dialog-content {
    padding-right: 20px;
  }
  .subjectList_div {
    margin-top: 30px;
  }
  .subjectList_div ul {
    display: flex;
    flex-wrap: wrap;
  }
  .subjectList_div ul li {
    text-align: center;
    color: #fff;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 10px;
  }
  .subjectList_div p{
      font-size:16px;
      font-weight: 600;
  }
}
</style>