<template>
<div >
    <el-dialog
        title="单价表"
        center
        width="800px"
        close-on-press-escape
        :visible.sync="dialogFromViseble"
        @close="closeDialog"
        :close-on-click-modal="false"
    >
       <div class="dialog-contents">
        <div  class="btn-div">
            <el-button-group style="float:right;">
          <el-button type="default" size="small" @click="addClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <span>新增</span>
          </el-button>
          <el-button type="default" size="small" @click="editClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edit" />
            </svg>
            <span>编辑</span>
          </el-button>
          <el-button type="default" size="small" @click="delClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-trash" />
            </svg>
            <span>删除</span>
          </el-button>
        </el-button-group>
        </div>
        <div class="table-area">
          <el-table
            size="small"
            :data="customData"
            style="width: 100%;margin-top:10px;"
            row-key="roleId"
            border
            tooltip-effect="dark"
            ref="customDataTable"
            @selection-change="handleSelectionChange"
            @row-click="roleItemClick"
        >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
            prop="sectionName"
            label="年级"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="basicPrice"
            label="基本单价"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="address"
            align="center"
            label="阶梯价格">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="seePrice(scope.row)" >查看</el-button>
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="设置时间"
            align="center"
            width="180">
        </el-table-column>
        </el-table>
        </div>
        <div class="page-area">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="customTotal"
            background
          ></el-pagination>
        </div>
        </div>
    </el-dialog>
    <el-dialog
        :title="priceParams.title"
        center
        width="500px"
        :close-on-click-modal="false"
        :visible.sync="addShow"
    >
        <div>
            <el-form
          :model="addParams"
          status-icon
          :rules="saveRules"
          ref="addParams"
          label-width="100px"
          size="small"
        >
        <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="选择年级" prop="sectionId">
                <el-select
                  v-model="addParams.sectionId"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in sectionList"
                    :key="item.macroId"
                    :label="item.name"
                    :value="item.macroId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="基本单价" prop="basicPrice">
                <el-input
                  style="width:90px;"
                  v-model="addParams.basicPrice"
                  @blur="basicPriceBlur()"
                ></el-input>
                <span style="padding:0 3px;">元/课时</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="单价差值" prop="priceDifference">
                <el-input
                  style="width:90px;"
                  v-model="addParams.priceDifference"
                  @blur="priceDifferenceBlur()"
                ></el-input>
                <span style="padding:0 3px;">元</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
                <el-form-item  prop="addParams" style="color:red;">
                    <span slot="label">
                        <span style="font-weight:600;">阶梯价格</span>
                    </span>
                    <el-button @click="addLadderPrice()" type="primary" size="small" >新增</el-button>
                </el-form-item>
            </el-col>
          </el-row>
          <div v-for="(item,index) in addParams.listXmtStudentUnitPriceGrandson" :key="index">
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item :label="'课时范围'+`${index+1}`" prop="addParams">
                <el-input
                  style="width:90px;"
                  v-model="item.begin"
                ></el-input>
                <span style="padding:0 3px;">至</span>
                <el-input
                  style="width:90px;"
                  v-model="item.end"
                ></el-input>
                <span style="padding:0 3px;">课时</span>
                
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="课时单价" prop="addParams">
                <el-input
                  style="width:90px;"
                  v-model="item.unitPrice"
                ></el-input>
                <span style="padding:0 5px;">元/课时</span>
                <el-button type="danger" size="small" @click="removePrice(index)">删除</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          </div>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave('addParams')"
          size="small"
          >{{sureName?sureName:'确 定'}}</el-button
        >
      </span>
    </el-dialog>
     <el-dialog
        title="价格查看"
        center
        width="400px"
        :visible.sync="seePriceShow"
        :close-on-click-modal="false"
    >
      <div>
        <div class="seePrice-div">
          <p>基本单价：{{priceInfoParams.basicPrice}}元/课时</p>
        <ul>
          <li v-for="(item,index) in priceInfoParams.listXmtStudentUnitPriceGrandson" :key="index">
            <p>课时范围{{index+1}}：{{item.begin}}至{{item.end}}课时</p>
            <p>课时单价：{{item.unitPrice}}元/课时</p>
          </li>
        </ul>
        </div>
      </div>
     </el-dialog>
</div>
</template>
<script>
import {
    studentUnitPriceChildList,//列表
    studentUnitPriceChildaSave,//新增
    studentUnitPriceChildaUpdate,//修改
    studentUnitPriceChildaRemove,//删除
    setCustomPriceStatu,//启用禁用
    studentUnitPriceChildInfo,
    setFixPrice
 } from '@/api/finance/finance.js'
 import { getSelectList, sectionCode } from "@/api/public";
export default {
    props: ["studentInfo","dialogStatus","sureName","sectionId","studentId"],
    data(){
        var basicPriceValidator = (rule, value, callback) => {
        if (!this.addParams.basicPrice) {
          callback(new Error('基本单价不能为空'));
        } else if (this.addParams.basicPrice.indexOf(".") != -1 && (this.addParams.basicPrice.split('.').length > 2 || this.addParams.basicPrice.split('.')[0] === '' || this.addParams.basicPrice.split('.')[1] === '')) {
          callback(new Error('请输入正确格式的基本单价')) //防止输入多个小数点
        } else if (this.addParams.basicPrice.indexOf(".") != -1 && this.addParams.basicPrice.split('.')[1].length > 2) {
          callback(new Error('请输入正确的小数位数')) //小数点后两位
        } else if (this.addParams.basicPrice * 1 > 99999) {
          callback(new Error('最大值为99999'))  // 限制最大额度
        } else {
          callback();
        }
        }
        return{
            sectionList:[],
            dialogFromViseble:false,
            priceInfoParams:{},
            seePriceShow:false,
            customData:[],
            customTotal:0,
            dialogVisible:false,
            params:{
                pageNumber:1,
                pageSize:10,
                studentId:"",
                type:"0",
                delFlag:"0"
            },
            priceParams:{
              title:'新增单价',
              type:'add'
            },
            addShow:false,
            addParams:{
                sectionId:"",
                basicPrice:"",//基本单价
                priceDifference:"",//单价差值
                studentId:"",//学生Id
                type:0,
                listXmtStudentUnitPriceGrandson:[
                    // {
                    //     begin:1,
                    //     end:103,
                    //     unitPrice:""
                    // }
                ]
            },
            rowIdList:[],
            editInfo:[],
            saveRules:{
               basicPrice: [
                { required: true,validator: basicPriceValidator, trigger: "blur" },
              ],

            }
        }
    },
    watch:{
      dialogStatus(newValue,oldValue){
        this.dialogFromViseble=newValue;
        if(newValue==true){
          this.studentUnitPriceChildListFn();
        }
      },
    },
    created(){
    },
    mounted(){
      this.getSectionList();
    },
    methods:{
        seePrice(row){
          this.seePriceShow=true;
          studentUnitPriceChildInfo(row.id).then(res => {
            if(res.code==0){
              this.priceInfoParams=res.rows;
            }
          });
        },
        studentUnitPriceChildListFn(){
            studentUnitPriceChildList(this.params).then(res => {
                this.customData=res.rows;
                this.customTotal=res.total;
            });
        },
        handleSizeChange(pageSize){
          this.params.pageSize = pageSize;
          this.studentUnitPriceChildListFn();
        },
        handleCurrentChange(currentPage){
          this.params.pageNumber = currentPage;
          this.studentUnitPriceChildListFn();
        },
        confirmSave(formName){
          var arr=this.addParams.listXmtStudentUnitPriceGrandson;
          for(var i=0;i<arr.length;i++){
            if(i < arr.length - 1){
              if(Number(arr[i].end)+1!=Number(arr[i+1].begin)){
                this.msgWarn('课时范围'+Number(i+2)+'课时配置不正确！');
                return;
              }
            }
          }
       
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.priceParams.type=='add'){
                      if(this.sureName){//财务审核并设置单价
                        studentUnitPriceChildaSave(this.addParams).then((res) => {
                          if (res.code == 0&&res.msg=='操作成功！') {
                              this.msgSuccess("操作成功！");
                              this.studentUnitPriceChildListFn();
                              setFixPrice({id:null,studentId:this.studentId,sectionId:this.sectionId}).then(res => {
                                  if(res.code==0&&res.msg=='操作成功！'){
                                    this.msgSuccess("操作成功！");
                                    this.addShow=false;
                                    this.$emit('CB_dialogStatus')
                                  }else{
                                    this.msgWarn(res.msg);
                                  }
                                  });
                          }else{
                            this.msgWarn(res.msg);
                          }
                        });
                      }else{
                        studentUnitPriceChildaSave(this.addParams).then((res) => {
                          if (res.code == 0&&res.msg=='操作成功！') {
                              this.addShow=false;
                              this.msgSuccess("操作成功！");
                              this.studentUnitPriceChildListFn();
                          }else{
                            this.msgWarn(res.msg);
                          }
                        });
                      }
                    
                    }else if(this.priceParams.type=='edit'){
                      studentUnitPriceChildaUpdate(this.addParams).then((res) => {
                          if (res.code == 0) {
                              this.addShow=false;
                              this.msgSuccess("操作成功！");
                              this.studentUnitPriceChildListFn();
                          }
                      });
                    }
                }else {
                    this.msgWarn("必填字段不能为空！");
                    return false;
                }
            })
        },
        //新增阶梯价格
        addLadderPrice(){
            var re = /^[0-9]+.?[0-9]*/;
            if(!re.test(this.addParams.priceDifference)||!re.test(this.addParams.basicPrice)){
                this.msgWarn("请输入正确单价格式！");
                return;
            }
            if((Number(this.addParams.basicPrice)>Number(this.addParams.priceDifference))&&this.addParams.listXmtStudentUnitPriceGrandson.length==0){
              this.addParams.listXmtStudentUnitPriceGrandson.push({
                begin:1,
                end:103,
                unitPrice:this.addParams.basicPrice
              })
            }
            else if(this.addParams.listXmtStudentUnitPriceGrandson.length){
              this.addParams.listXmtStudentUnitPriceGrandson.push({
                begin:this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].end+1,
                end:this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].end+100,
                unitPrice:(this.addParams.listXmtStudentUnitPriceGrandson[this.addParams.listXmtStudentUnitPriceGrandson.length-1].unitPrice-this.addParams.priceDifference).toFixed(2)
              })
            }
            else{
              this.msgWarn("单价差值不能大于基本单价！");
              return;
            }
        },
        getSectionList() {
          getSelectList({
            parentCode: sectionCode,
          }).then((res) => {
            if (res.code == 0) {
              this.sectionList = res.rows;
            }
          });
        },
        closeDialog(){
          this.$emit('CB_dialogStatus')
        },
        basicPriceBlur(){
          // var arr=this.addParams.listXmtStudentUnitPriceGrandson;
          // for(let i = 1,len=arr.length;i<=arr.length;i++){
          //   arr[i-1].unitPrice= arr[i-1].unitPrice-this.addParams.priceDifference
          // }
          // for(var i=1;i<this.addParams.listXmtStudentUnitPriceGrandson.length;i++){
          //    if(i < this.addParams.listXmtStudentUnitPriceGrandson.length -1){
          //       this.addParams.listXmtStudentUnitPriceGrandson[i].unitPrice=
          //       this.addParams.listXmtStudentUnitPriceGrandson[i+1].unitPrice-this.addParams.priceDifference
          //    }
          // }
        },
        priceDifferenceBlur(){
          console.log(this.addParams.priceDifference)
        },
        closeListClick(){
          this.$emit("studentListParamsChange");
        },
        handleSelectionChange(val){
          this.rowIdList=[];
          this.editInfo=[];
          if(val.length>0){
            for (let i = 0, len = val.length; i < len; i++) {
              this.rowIdList.push(val[i].id);
              this.editInfo.push(val[i])
            }
          }
        },
        roleItemClick(row){
          // this.$refs.customDataTable.toggleRowSelection(row);
        },
         removePrice(index){
          this.addParams.listXmtStudentUnitPriceGrandson.splice(this.addParams.listXmtStudentUnitPriceGrandson.length-1,1)
        },
        addClick(){
            this.priceParams={
              title:'新增单价',
              type:'add'
            }
            this.addParams={
                basicPrice:"",//基本单价
                priceDifference:"",//单价差值
                studentId:"",//学生Id
                type:0,
                sectionId:"",
                listXmtStudentUnitPriceGrandson:[]
            },
            this.addShow=true;
        },
        editClick(){
          this.priceParams={
              title:'编辑单价',
              type:'edit'
            }
          if (this.editInfo.length > 1) {
            this.msgWarn("请选择单个数据进行编辑！");
            return;
          }
          if (this.editInfo.length == 0) {
            this.msgWarn("请选择一个数据进行编辑！");
            return;
          }
          this.addShow=true;
          this.addParams=this.editInfo[0];
        },
        //是否启用禁用
        isEnableClick(row){
          let data={
            id:this.studentInfo.id,
            childId:row.id,
            status:row.status==0?1:0,
            no:row.no,
          }
          setCustomPriceStatu(data).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("操作成功！");
              this.studentUnitPriceChildListFn();
            }
          });
        },
        delClick(){
          if (this.rowIdList.length == 0) {
            this.msgWarn("请至少选择一条数据进行删除！");
            return;
          }
          this.$confirm("确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              studentUnitPriceChildaRemove(this.rowIdList).then((res) => {
                if (res.code == 0) {
                  this.msgSuccess("操作成功！");
                  this.studentUnitPriceChildListFn();
                }
              });
            })
            .catch(() => {});
        },
        enableClick(){

        },
        disableClick(){

        },
    }
    
}
</script>
<style scoped lang="scss">
    .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
    .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialog-contents{
    display: flex;
    flex-direction: column;
    height:100%;
    .btn-div{
      height:50px;
      float:right;
    }
    .table-area{
      flex:1;
      overflow: hidden;
    }
  }
 
</style>