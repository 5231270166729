<template>
    <div>
       <el-dialog
      title="订单详情"
      :visible="showStatus"
      width="800px"
      :close-on-click-modal="false"
      center
      @close="closeDialog"
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="detail_type">学生概况</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>学生姓名：</span>
              <span>{{studentDetail.name}}</span>
            </el-col>
            <el-col :span="8">
              <span>联系电话：</span>
              <span>{{studentDetail.phoneNo ? studentDetail.phoneNo : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>年级：</span>
              <span>{{studentDetail.sectionName ? studentDetail.sectionName : '暂无数据'}}</span>
            </el-col> 
            <el-col :span="8">
              <span>性别：</span>
              <span>{{studentSex(studentDetail.sex)}}</span>
            </el-col>
          </el-row>
        </div>
        
        <div class="detail_type">充值信息</div>
        <div v-show="infoOrderType!=5">
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" >
            <el-col :span="8">
              <span>总价：</span>
              <span>{{orderDetail.zdyTotalPrice ? orderDetail.zdyTotalPrice : orderDetail.totalPrice}}</span>
            </el-col>
            <el-col :span="8">
              <span>单价：</span>
              <span>{{orderDetail.zdySinglePrice ? orderDetail.zdySinglePrice : orderDetail.singlePrice}}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{orderDetail.zdyClassHour ? orderDetail.zdyClassHour : orderDetail.classHour}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyDiscountAmount!=null&&(orderDetail.orderType==2||orderDetail.orderType==4)">
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{orderDetail.zdyDiscountAmount?orderDetail.zdyDiscountAmount:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{orderDetail.zdyActualPrice?orderDetail.zdyActualPrice:0}}</span>
            </el-col>
          </el-row>
           <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyClassHour">
            <el-col :span="8">
              <span>单价：</span>
              <span>{{orderDetail.zdySinglePrice?orderDetail.zdySinglePrice:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{orderDetail.zdyClassHour?orderDetail.zdyClassHour:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>总金额：</span>
              <span>{{orderDetail.zdyTotalPrice?orderDetail.zdyTotalPrice:0}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail row_detail2" type="flex" align="bottom" v-show="orderDetail.zdyClassHour">
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{orderDetail.zdyDiscountAmount?orderDetail.zdyDiscountAmount:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>赠送课时：</span>
              <span>{{orderDetail.zdyGiveClassHour?orderDetail.zdyGiveClassHour:0}}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{orderDetail.zdyActualPrice?orderDetail.zdyActualPrice:0}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>收款方式：</span>
              <span>{{orderDetail.payMentName ? orderDetail.payMentName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>收款类型：</span>
              <span>{{orderDetail.moneyTypeName ? orderDetail.moneyTypeName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>支付方式：</span>
              <span>{{
                orderDetail.payType == 2 ? "线下支付" : "余额支付"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>产品名称：</span>
              <span>{{orderDetail.productName}}</span>
            </el-col>
            <el-col :span="8">
              <span v-if="orderDetail.productType==2||orderDetail.productType==5">学段：</span>
              <span v-else>年级：</span>
              <span>{{orderDetail.sectionName ? orderDetail.sectionName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>教师职称：</span>
              <span>{{orderDetail.teacherTitleName ? orderDetail.teacherTitleName : '暂无数据'}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单编号：</span>
              <span>{{orderDetail.orderNo}}</span>
            </el-col>
            <el-col :span="8">
              <span>下单时间：</span>
              <span>{{orderDetail.createTime}}</span>
            </el-col>
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{orderDetail.orderStatusName}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <el-image 
                    class="td-img"
                    style="width: 100px; height: 100px;z-index:10000;"
                    :src="orderDetail.certificate" 
                    v-if="orderDetail.certificate"
                      :preview-src-list="srcList"
                  >
                  </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreementName"><a target="view_window" :href="orderDetail.coachAgreement">{{orderDetail.coachAgreementName}}</a> </span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{orderDetail.orgName ? orderDetail.orgName : orderDetail.teachManageOrgrName}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+orderDetail.orderSourceRealName+'）' : orderDetail.teachManagerName+'（'+orderDetail.orderSourceRealName+'）'}}</span>
            </el-col>
            <el-col :span="8">
              <span>归属校区：</span>
              <span>{{orderDetail.schoolName ? orderDetail.schoolName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>产品类型：</span>
              <span>{{orderDetail.productTypeName ? orderDetail.productTypeName : '暂无数据'}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col>
              <span>备注：</span>
              <span>{{orderDetail.remark ? orderDetail.remark : '暂无数据'}}</span>
            </el-col>
          </el-row>
        </div>
        <div v-show="infoOrderType==5">
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom" v-show="orderDetail.totalPrice">
            <el-col :span="8" >
              <span style="font-weight: bold;color:#ff3f3f;">缴费金额：</span>
              <span style="font-weight: bold;color:#ff3f3f;">{{orderDetail.totalPrice}}</span>
            </el-col>
            <el-col :span="8">
              <span style="font-weight: bold;color:#ff3f3f;">赠送课时：</span>
              <span style="font-weight: bold;color:#ff3f3f;">{{orderDetail.zdyGiveClassHour}}</span>
            </el-col>
            <el-col :span="8">
              <span>支付方式：</span>
              <span>{{orderDetail.payMentName}}（{{orderDetail.moneyTypeName}}）</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <el-image
                    class="td-img"
                    style="width: 100px; height: 100px;z-index:10000;"
                    :src="orderDetail.certificate"
                    v-if="orderDetail.certificate"
                      :preview-src-list="srcList"
                  >
                  </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreementName"><a target="view_window" :href="orderDetail.coachAgreement">{{orderDetail.coachAgreementName}}</a> </span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{orderDetail.orgName ? orderDetail.orgName : orderDetail.teachManageOrgrName}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+orderDetail.orderSourceRealName+'）' : orderDetail.teachManagerName+'（'+orderDetail.orderSourceRealName+'）'}}</span>
            </el-col>
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{orderDetail.orderSourceName ? orderDetail.orderSourceName+'（'+orderDetail.orderSourceRealName+'）' : orderDetail.teachManagerName+'（'+orderDetail.orderSourceRealName+'）'}}</span>
            </el-col>
            <el-col :span="8">
              <span>归属校区：</span>
              <span>{{orderDetail.schoolName ? orderDetail.schoolName : '暂无数据'}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>备注：</span>
              <span>{{orderDetail.remark ? orderDetail.remark : '暂无数据'}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import { orderDetail } from "@/api/sales/myCustomer.js";
import { studentInfo } from '@/api/sales/saleManage.js'
export default {
    props:["orderInfoStatus","orderInfoParams"],
    watch:{
      orderInfoStatus(newValue,oldValue){
        this.showStatus=newValue;
        if(newValue){
          this.orderDetailFn();
        }
      }
    },
    data(){
        return{
          showStatus:false,
          studentDetail:{},
          infoOrderType:"",
          srcList:[],
          orderDetail:{},
          studentAccount:0,
          accountBalance:0,

        }
    },
    mounted(){
        
    },
    methods:{
      closeDialog(){
        this.$emit("CB_orderInfoShow")
      },
      orderDetailFn(){
        orderDetail(this.orderInfoParams.id).then(res => {
        if(res.code == 0){
          this.orderDetail = res.rows;
          this.studentAccount=res.rows.studentAccount;
          this.srcList.push(res.rows.certificate)
          this.infoOrderType=res.rows.orderType;
          switch(this.orderDetail.payMent){
            case 1: 
              this.orderDetail.payMentName = '支付宝'
              break;
            case 2:
              this.orderDetail.payMentName = '微信'
              break;
            case 3:
              this.orderDetail.payMentName = '对公账户'
              break;
            case 4:
              this.orderDetail.payMentName = 'POS'
              break;
            default:
              this.orderDetail.payMentName = ''
          }
          switch(this.orderDetail.moneyType){
            case 1:
              this.orderDetail.moneyTypeName = '全款'
              break
            case 2:
              this.orderDetail.moneyTypeName = '定金'
              break
            case 3:
              this.orderDetail.moneyTypeName = '尾款'
              break
            case 4:
              this.orderDetail.moneyTypeName = '特殊订单'
              break
            case 5:
              this.orderDetail.moneyTypeName = '余额转充值'
              break
            default:
              this.orderDetail.moneyTypeName = ''
          }
          switch(this.orderDetail.orderStatus){
            case 1:
              this.orderDetail.orderStatusName = '待财务审核'
              break
            case 2:
              this.orderDetail.orderStatusName = '财务审核拒绝'
              break
            case 3:
              this.orderDetail.orderStatusName = '教务主管分配中'
              break
            case 4:
              this.orderDetail.orderStatusName = '待排课'
              break
            case 5:
              this.orderDetail.orderStatusName = '排课中'
              break
            case 6:
              this.orderDetail.orderStatusName = '已退款'
              break
            case 7:
              this.orderDetail.orderStatusName = '订单已完成'
              break
            case 8:
              this.orderDetail.orderStatusName = '订单作废'
              break
            case 8:
              this.orderDetail.orderStatusName = '退款中'
              break
            default:
              this.orderDetail.orderStatusName = ''
          }
          switch(this.orderDetail.productType){
            case 1:
            case 3:
              this.orderDetail.productTypeName = (this.orderDetail.isSubscribe == 1 ? '试听课' : '一对一')
              break
            case 2:
            case 5:
              this.orderDetail.productTypeName = '一对多'
              break
            case 4:
              this.orderDetail.productTypeName = '公开课'
              break
            default:
              this.orderDetail.productTypeName = ''
          }
        }
      })
      studentInfo(this.orderInfoParams.studentId).then(res => {
        if(res.code == 0){
          this.accountBalance=res.rows.accountBalance;
          this.studentDetail = res.rows
          if(this.studentDetail.provinceName){
            this.studentDetail.address = this.studentDetail.provinceName + '' + this.studentDetail.cityName + '' + this.studentDetail.countyName
          }
        }
      })
      },
      studentSex(val){
       if(val==1){
         return '男'
       }else if(val==2){
         return '女'
       }else if(val==null||val==''){
         return ''
       }
    },
    }

}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content{
    .detail_type{
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color:#000;
    }
    .row_detail{
      padding: 6px 0;
    }
    .row_detail2{
      span{
        font-weight: bold;
        color:#ff3f3f;
      }
    }
    .certificate{
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
>>>.td-img .el-icon-circle-close {
    color: white !important;
  }
  a{text-decoration:none;}
</style>
