<template>
    <div class="main-content sys-user-manage">
        <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="输入学生姓名/联系方式"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">申请时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panelsss
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button type="primary" size="small" icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,studentRefundListFn())"
          >搜索</el-button
        >
        <el-button type="primary" size="small" icon="el-icon-search"
          @click="importExcel"
          >导出excel</el-button
        >
      </div>
    </div>
     <p style="margin-top:20px;">合计：退款人数{{people}}人，退款金额{{money}}元</p>
    <div class="table-area">
     
      <el-table
        size="small"
        :data="tableList"
        style="width: 100%; margin-bottom: 20px;margin-top:20px;"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
       <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bankAddress"
          label="开户支行"
          align="center"
        ></el-table-column>
        <el-table-column 
          prop="bankAccount"
          label="收款账号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bankUserName"
          label="收款姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="bankUserPhone"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realityRefundAmount"
          label="实退款金额"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="createTime"
          label="申请时间"
          align="center"
        ></el-table-column>
         <el-table-column
          prop="educationalApprovalTime"
          label="退款时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="seeDetail(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="goPayment(scope.row)"
              v-show="scope.row.educationalApproval=='0'"
              >去打款</el-button
            >
            <el-button
              type="text"
              size="small"
               v-show="scope.row.educationalApproval=='1'"
              >已打款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
          <el-pagination
            @size-change="handleSizeCourseChange"
            @current-change="handleCourseChange"
            :current-page="listParams.pageNumber"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="listParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </div>
        <el-dialog
          title="退费详情"
          :visible.sync="refundSync"
          width="800px"
          :close-on-click-modal="false"
          center
          custom-class="product-dialog"
        >
          <div class="dialog-content studentInfo">
            <h2>学生概况</h2>
            <ul>
              <li>学生姓名：{{studentDetailList.name}}</li>
              <li>联系方式：{{studentDetailList.no}}</li>
              <li>年级：{{studentDetailList.sectionName}}</li>
            </ul>
            <ul v-for="(item, indexs) in orderdDetail" :key="indexs">
               <el-divider></el-divider>
              <li>产品名称：{{item.productName}}</li>
              <li>订单编号：{{item.orderNo}}</li>
              <li>总课时：{{item.classHour?item.classHour:item.zdyClassHour}}课时</li>
              <li>课时单价：{{item.singlePrice?item.singlePrice:item.zdySinglePrice}}元</li>
              <li>总费用：{{item.totalPrice?item.totalPrice:item.zdyActualPrice}}元</li>
              <li>实收费用：{{item.totalPrice?item.totalPrice:item.zdyActualPrice}}元</li>
              <!-- <h2>课消记录</h2> -->
              <div v-for="(items, indexss) in item.xmtStudentRefundInfoChildList" :key="indexss" class="infoRecord">
                <span>课程级别：{{courseLevelFn(items.courseLevel)}}</span>
                <span>课时单价：{{items.classSinglePrice}}元</span>
                <span>已上课时数：{{items.countUsedClassHour}}</span>
                <span>课耗金额：{{classMoney(items.countUsedClassHour,items.classSinglePrice)}}元</span>
              </div>
            </ul>
             <h2>退款金额</h2>
             <ul>
                <li>应退款金额：{{getRefundAmount}}元</li>
                <li>实退款金额：{{getRealityRefundAmount}}元</li>
                <li>备注：{{getBackReason}}</li>
                <li>申请时间：{{getCreateTime}}</li>
                <li>申请人：{{getCreateName}}</li>
                <li>打款时间：{{getEducationalApprovalTime}}</li>
              </ul>
          </div>
      </el-dialog>
       <el-dialog
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>退款时间：</span>
          <el-date-picker
            v-model="pickerDate"
            type="datetime"
            :picker-options="pickerDateOptions()"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            @change="dateValueChange"
            placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small"
          @click="pickerDateClick()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <refundDialog ref="refundDialog"   @close="refundClose()" :page="3" :visibleStudentId="visibleStudentId" :visibleId="visibleId" v-if="Visible" :visible="Visible"></refundDialog>
    </div>
</template>
<script>

import dayjs from "dayjs";
import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
import {
  studentRefundInfo
} from "@/api/administration/tubeTeachBusiness/studentManage.js";
import { studentInfo } from "@/api/sales/saleManage";
import { studentRefundList,studentRefundUpdate } from '@/api/finance/finance.js'
import refundDialog from '../finance/dialog/refund-dialog.vue'
    export default{
        components:{
          refundDialog
        },
        data(){
            return{
                visibleStudentId:'',
                Visible:false,
                getRefundAmount:"",//应退款金额
                getRealityRefundAmount:"",
                getBackReason:"",
                getCreateName:"",
                getCreateTime:"",
                getEducationalApprovalTime:"",
                orderMoney:0,
                pickerDate:"",
                apportionVisible:false,
                studentDetailList:{},
                orderdDetail:[],
                people:0,
                money:0,
                pickerDateParams:{
                  id:"",
                  educationalApproval:1,
                  orderIds:"",
                  educationalApprovalTime:null
                },
                refundSync:false,
                listParams:{
                    name:"",
                    pageNumber:1,
                    pageSize:10,
                    searchBeginTime:null,
                    searchEndTime:null,
                    teacherApproval:1,
                    dataAuthority:'dept',
                     ifTeacher:"Y"
                },
                timePickerValue:"",
                tableTime:"",
                total:0,
                tableList:[],
                pickerDateOptions() {
                   // this.oldTime 是上一条数据的开始时间
                  let date = new Date(this.tableTime)
                  let minutes = date.getMinutes() + 1
                  // h和m 是将日期只取时分
                  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                  let m = (minutes < 10 ? '0' + minutes : minutes);
                  // pickerDate 判断当前选择时间是否等于上条数据时间
                  // 若等于时，限制时间从00：00：00 - 上条数据开始时间，不等于时，24小时可选
                  let pickerDate = date.toDateString() === new Date(this.pickerDate).toDateString() 
                  return {
                    // 日期限制
                    disabledDate: (time) => {
                      let old = this.tableTime
                      return time.getTime() < new Date(old).getTime() - 86400000
                    },
                    // 时间限制
                    selectableRange: pickerDate? h + m  + ':00 - 23:59:59' : '00:00:00 - 23:59:59'
                  }
                  // // this.oldTime 是上一条数据的开始时间
                  // let date = new Date(this.tableTime)
                  // let minutes = date.getMinutes() + 1
                  // // h和m 是将日期只取时分
                  // let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                  // let m = (minutes < 10 ? '0' + minutes : minutes);
                  // return {
                  //   // 日期限制
                  //   disabledDate: (time) => {
                  //     let old = this.tableTime
                  //     return time.getTime() < new Date(old).getTime() - 86400000
                  //   },
                  //   // 时间限制
                  //   selectableRange: h + m  + ':00 - 23:59:59'
                  // }
                },
                visibleId:"",
                pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs().format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                        const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
                        const start = new Date(
                            dayjs()
                            .subtract(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一周",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(7, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(30, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                        const end = new Date(
                            dayjs()
                            .add(1, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        const start = new Date(
                            dayjs()
                            .subtract(90, "day")
                            .format("YYYY-MM-DD") + " 00:00:00"
                        );
                        picker.$emit("pick", [start, end]);
                        }
                    }
                ]
            },
            }
        },
        methods:{
          classMoney(countUsedClassHour,classSinglePrice){
            return Math.ceil(Number(countUsedClassHour))*Number(classSinglePrice)
          },
          refundClose(){
            this.Visible=false;
            this.studentRefundListFn();
          },
          seeDetail(row){
            if(row.type==null){
              this.refundSync=true;
              studentInfo(row.studentId).then(res => {
                  this.studentDetailList = res.rows;
                });
              studentRefundInfo(row.orderIds).then(res => {
                  this.orderdDetail=res.rows.xmtStudentRefundInfoEntity;
                  this.getRefundAmount=res.rows.refundAmount;
                  this.getRealityRefundAmount=res.rows.realityRefundAmount;
                  this.getBackReason=res.rows.backReason;
                  this.getCreateName=res.rows.createUserName+'（'+res.rows.createName+'）';
                  this.getCreateTime=res.rows.createTime;
                  this.getEducationalApprovalTime=res.rows.educationalApprovalTime;
              })
            }else{//缴费退费
              this.Visible=true;
              this.visibleStudentId=row.studentId;
              this.visibleId=row.id;
            }
            
          },
          dateValueChange(){
            this.pickerDateParams.educationalApprovalTime=this.pickerDate;
          },
          goPayment(row){
            this.pickerDate="";
            this.pickerDateParams.id=row.id;
            this.pickerDateParams.orderIds=row.orderIds;
            this.apportionVisible=true;
            this.tableTime = new Date(row.createTime)
          }, 
          pickerDateClick(){
            let oldTime = new Date(this.tableTime).getTime();
            let endTime = new Date(this.pickerDateParams.educationalApprovalTime).getTime();
            if(oldTime>endTime){
              this.msgWarn("打款时间不能小于退款时间！");
              return false;
            }
            if(endTime>new Date().getTime()){//打款时间大于当前时间
              this.msgWarn("请在当前时间之前打款！");
              return false;
            }
            studentRefundUpdate(this.pickerDateParams).then(res => {
                if(res.code==0){
                   this.msgSuccess("操作成功！");
                   this.apportionVisible=false;
                   this.studentRefundListFn();
                }
            })
          } ,  
          importExcel(){
            let jsonData = [], str = `学生姓名,开户支行,收款账号,收款姓名,联系方式,实退款金额,申请时间,退款时间\n`
            for(let i = 0, len = this.tableList.length; i < len; i++){
              jsonData.push({
                'name': this.tableList[i].name?this.tableList[i].name:'',
                'bankAddress': this.tableList[i].bankAddress?this.tableList[i].bankAddress:'',
                'bankAccount': this.tableList[i].bankAccount?this.tableList[i].bankAccount:'',
                'bankUserName': this.tableList[i].bankUserName?this.tableList[i].bankUserName:'',
                'bankUserPhone': this.tableList[i].bankUserPhone?this.tableList[i].bankUserPhone:'',
                'realityRefundAmount': this.tableList[i].realityRefundAmount?this.tableList[i].realityRefundAmount:'',
                'createTime': this.tableList[i].createTime?this.tableList[i].createTime:'',
                'educationalApprovalTime': this.tableList[i].educationalApprovalTime?this.tableList[i].educationalApprovalTime:'',
              })
            }
            // 增加\t为了不让表格显示科学计数法或者其他格式
            for(let i = 0 ; i < jsonData.length ; i++ ){
                for(const key in jsonData[i]){
                    str+=`${jsonData[i][key] + '\t'},`;     
                }
                str+='\n';
            }
            // encodeURIComponent解决中文乱码
            const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
            // 通过创建a标签实现
            const link = document.createElement("a");
            link.href = uri;
            // 对下载的文件命名
            link.download =  "学生退款明细列表.xls";
            link.click();
          },
          datePickerChange(){
              if (!this.timePickerValue) {
                this.listParams.searchBeginTime = null;
                this.listParams.searchEndTime = null;
                return;
              }
              this.listParams.searchBeginTime = this.timePickerValue[0];
              this.listParams.searchEndTime = this.timePickerValue[1];
            },
            studentRefundListFn(){
                studentRefundList(this.listParams).then(res => {
                    this.tableList=res.rows;
                    this.total=res.total;
                    this.people=res.total;
                    this.money=res.count;
                })
            },
            handleSizeCourseChange(pageSize){
              this.listParams.pageSize = pageSize;
              this.studentRefundListFn();
            },
            handleCourseChange(currentPage){
              this.listParams.pageNumber = currentPage;
              this.studentRefundListFn();
            },  
            courseLevelFn(item){
            if(item==1){
              return '初级课程'
            }else if(item==2){
              return '中级课程'
            }else if(item==3){
              return '高级课程'
            }else if(item==4){
              return '特级课程'
            }else {
              return '正常课程'
            }
          },
        },
        mounted(){
            this.studentRefundListFn();
        }
    }
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 18px;
      line-height: 30px;
      font-weight: 900;
    }
    .row_detail {
      padding: 6px 0;
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate_box {
    width: 160px;
    height: 100px;
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100%;
      cursor: pointer;
    }
  }
  .certificate_tip {
    width: 160px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .coachAgreement_tip {
    line-height: 30px;
  }
  .dialog-content {
    min-height: 260px;
    max-height: 60vh;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    .item_select {
      display: flex;
      align-items: center;
      span {
        width: 120px;
      }
    }
    .record {
      margin-top: 230px;
    }
    .info-title {
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      font-size: 16px;
      text-indent: 12px;
      text-align: left;
    }
  }
  .studentInfo{
    h2{
      font-size:16px;
      margin-top:20px;
    }
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-top:10px;
      li{
        width:33.3%;
        padding:5px 0;
      }
    }
  }
  .infoRecord{
    display: flex;
    width:100%;
    span{
      width:25%;
      display: inline-block;
      padding:5px 0;
    }
  }
}
</style>