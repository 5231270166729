<template>
  <div class="main-content sys-user-manage">
    <div class="btn-area">
      <div class="select-condition-item">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">充值审核</el-menu-item>
          <el-menu-item index="2">订单审核</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="btn-area" v-show="keyValue == 2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="学生姓名/联系方式/订单编号/产品名称"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品类型：</span>
          <el-select
            v-model="listParams.orderType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">产品状态：</span>
          <el-select
            v-model="listParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="() => ((listParams.pageNumber = 1), getOrderList())"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="importExcel(2)"
          >导出excel</el-button
        >
      </div>
    </div>
    <div class="btn-area" v-show="keyValue == 2">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">添加时间：</span>
          <el-date-picker
            size="small"
            v-model="timePickerValue"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange(2)"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
      </div>
    </div>
    <div class="btn-area" v-show="keyValue == 1">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="rechargeParams.name"
            placeholder="学生姓名"
            size="small"
            style="min-width: 120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">审核状态：</span>
          <el-select
            v-model="rechargeParams.orderStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">充值时间：</span>
          <el-date-picker
            size="small"
            v-model="rechargePicker"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="datePickerChange(1)"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="() => ((rechargeParams.pageNumber = 1), getOrderList())"
          >搜索</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-printer"
          @click="importExcel(1)"
          >导出excel</el-button
        >
      </div>
    </div>
    <div class="table-area" v-show="keyValue == 2">
      <el-table
        size="small"
        :data="orderList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterProductType"
          label="产品类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterOrderStatus"
          label="订单状态"
          align="center"
        ></el-table-column>
        <el-table-column prop="saleName" label="订单提交人" align="center">
          <template slot-scope="scope">
            <span
              >{{ scope.row.orderSourceName }}（{{
                scope.row.orderSourceRealName
              }}）</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="formatterMoneyType"
          label="收款类型"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-area" v-show="keyValue == 1">
      <el-table
        size="small"
        :data="rechargeList"
        style="width: 100%; margin-bottom: 20px"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column type="index" label="序号" align="center" width="60">
        </el-table-column>
        <el-table-column
          prop="studentName"
          label="学生姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentSectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="totalPrice"
          label="缴费金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="zdyGiveClassHour"
          label="赠送课时"
          align="center"
        ></el-table-column>
        <el-table-column
          label="充值时间"
          prop="createTime"
          align="center"
        ></el-table-column>
        <el-table-column
          :formatter="formatterOrderStatus"
          label="审核状态"
          align="center"
        ></el-table-column>
        <el-table-column prop="saleName" label="订单提交人" align="center">
          <template slot-scope="scope">
            <span
              >{{ scope.row.orderSourceName }}（{{
                scope.row.orderSourceRealName
              }}）</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="moneyType"
          :formatter="formatterMoneyType"
          label="收款类型"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="dealSeeDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area" v-show="keyValue == 2">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <div class="page-area" v-show="keyValue == 1">
      <el-pagination
        @size-change="rechargeSizeChange"
        @current-change="rechargeCurrentChange"
        :current-page="rechargeParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="rechargeParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="rechargeTotal"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="detailDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="detail_type">学生概况</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>学生姓名：</span>
              <span>{{ studentDetail.name }}</span>
            </el-col>
            <el-col :span="8">
              <span>联系电话：</span>
              <span>{{
                studentDetail.phoneNo ? studentDetail.phoneNo : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>年级：</span>
              <span>{{
                studentDetail.sectionName
                  ? studentDetail.sectionName
                  : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>性别：</span>
              <span>{{ studentSex(studentDetail.sex) }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="detail_type">账户信息</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8" v-show="orderDetail.orderType != 2">
              <span>总计缴费：</span>
              <span>{{ totalPrice ? totalPrice : 0 }}</span>
            </el-col>
            <el-col :span="8" v-show="orderDetail.orderType != 2">
              <span>剩余金额：</span>
              <span>{{ studentAccount ? studentAccount : 0 }}</span>
            </el-col>
            <el-col :span="8">
              <span style="color: #409eff; cursor: pointer" @click="allOrder()"
                >查看全部订单</span
              >
            </el-col>
          </el-row>
        </div>
        <div class="detail_type">充值信息</div>
        <div v-show="infoOrderType != 5">
          <el-row
            :gutter="20"
            class="row_detail row_detail2"
            type="flex"
            align="bottom"
            v-show="!orderDetail.zdyClassHour"
          >
            <el-col :span="8">
              <span>总价：</span>
              <span>{{
                orderDetail.zdyTotalPrice
                  ? orderDetail.zdyTotalPrice
                  : orderDetail.totalPrice
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>单价：</span>
              <span
                v-if="
                  orderDetail.productType == 2 || orderDetail.productType == 5
                "
                >--</span
              >
              <span v-else>{{
                orderDetail.zdySinglePrice
                  ? orderDetail.zdySinglePrice
                  : orderDetail.singlePrice
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{
                orderDetail.zdyClassHour
                  ? orderDetail.zdyClassHour
                  : orderDetail.classHour
              }}</span>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="row_detail row_detail2"
            type="flex"
            align="bottom"
            v-show="
              orderDetail.zdyDiscountAmount != null &&
              (orderDetail.orderType == 2 || orderDetail.orderType == 4)
            "
          >
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{
                orderDetail.zdyDiscountAmount
                  ? orderDetail.zdyDiscountAmount
                  : 0
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{
                orderDetail.zdyActualPrice ? orderDetail.zdyActualPrice : 0
              }}</span>
            </el-col>
          </el-row>
          <!-- 自定义课时 -->
          <el-row
            :gutter="20"
            class="row_detail row_detail2"
            type="flex"
            align="bottom"
            v-show="orderDetail.zdyClassHour"
          >
            <el-col :span="8">
              <span>单价：</span>
              <span>{{
                orderDetail.zdySinglePrice ? orderDetail.zdySinglePrice : 0
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>课时数：</span>
              <span>{{
                orderDetail.zdyClassHour ? orderDetail.zdyClassHour : 0
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>总金额：</span>
              <span>{{
                orderDetail.zdyTotalPrice ? orderDetail.zdyTotalPrice : 0
              }}</span>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="row_detail row_detail2"
            type="flex"
            align="bottom"
            v-show="orderDetail.zdyClassHour"
          >
            <el-col :span="8">
              <span>优惠金额：</span>
              <span>{{
                orderDetail.zdyDiscountAmount
                  ? orderDetail.zdyDiscountAmount
                  : 0
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>赠送课时：</span>
              <span>{{
                orderDetail.zdyGiveClassHour ? orderDetail.zdyGiveClassHour : 0
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>实际支付：</span>
              <span>{{
                orderDetail.zdyActualPrice ? orderDetail.zdyActualPrice : 0
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>收款方式：</span>
              <span>{{
                orderDetail.payMentName ? orderDetail.payMentName : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>收款类型：</span>
              <span>{{
                orderDetail.moneyTypeName
                  ? orderDetail.moneyTypeName
                  : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>支付方式：</span>
              <span>{{
                orderDetail.payType == 2 ? "线下支付" : "余额支付"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>产品名称：</span>
              <span>{{ orderDetail.productName }}</span>
            </el-col>
            <el-col :span="8">
              <span
                v-if="
                  orderDetail.productType == 2 || orderDetail.productType == 5
                "
                >学段：</span
              >
              <span v-else>年级：</span>
              <span>{{
                orderDetail.sectionName ? orderDetail.sectionName : "暂无数据"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>教师职称：</span>
              <span>{{
                orderDetail.teacherTitleName
                  ? orderDetail.teacherTitleName
                  : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单编号：</span>
              <span>{{ orderDetail.orderNo }}</span>
            </el-col>
            <el-col :span="8">
              <span>下单时间：</span>
              <span>{{ orderDetail.createTime }}</span>
            </el-col>
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{ orderDetail.orderStatusName }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <el-image
                class="td-img"
                style="width: 100px; height: 100px; z-index: 10000"
                :src="orderDetail.certificate"
                v-if="orderDetail.certificate"
                :preview-src-list="srcList"
              >
              </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreementName"
                ><a target="view_window" :href="orderDetail.coachAgreement">{{
                  orderDetail.coachAgreementName
                }}</a>
              </span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{
                orderDetail.orgName
                  ? orderDetail.orgName
                  : orderDetail.teachManageOrgrName
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{
                orderDetail.orderSourceName
                  ? orderDetail.orderSourceName +
                    "（" +
                    orderDetail.orderSourceRealName +
                    "）"
                  : orderDetail.teachManagerName +
                    "（" +
                    orderDetail.orderSourceRealName +
                    "）"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>归属校区：</span>
              <span>{{
                orderDetail.schoolName ? orderDetail.schoolName : "暂无数据"
              }}</span>
            </el-col>

            <el-col :span="8">
              <span>产品类型：</span>
              <span>{{
                orderDetail.productTypeName
                  ? orderDetail.productTypeName
                  : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col>
              <span>备注：</span>
              <span>{{
                orderDetail.remark ? orderDetail.remark : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
        </div>
        <div v-show="infoOrderType == 5">
          <el-row
            :gutter="20"
            class="row_detail"
            type="flex"
            align="bottom"
            v-show="orderDetail.totalPrice"
          >
            <el-col :span="8">
              <span style="font-weight: bold; color: #ff3f3f">缴费金额：</span>
              <span style="font-weight: bold; color: #ff3f3f">{{
                orderDetail.totalPrice
              }}</span>
            </el-col>
            <el-col :span="8">
              <span style="font-weight: bold; color: #ff3f3f">赠送课时：</span>
              <span style="font-weight: bold; color: #ff3f3f">{{
                orderDetail.zdyGiveClassHour
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>支付方式：</span>
              <span
                >{{ orderDetail.payMentName }}（{{
                  orderDetail.moneyTypeName
                }}）</span
              >
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>付款凭证：</span>
              <el-image
                class="td-img"
                style="width: 100px; height: 100px; z-index: 10000"
                :src="orderDetail.certificate"
                v-if="orderDetail.certificate"
                :preview-src-list="srcList"
              >
              </el-image>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>辅导协议：</span>
              <span v-if="orderDetail.coachAgreementName"
                ><a target="view_window" :href="orderDetail.coachAgreement">{{
                  orderDetail.coachAgreementName
                }}</a>
              </span>
              <span v-else>暂无数据</span>
            </el-col>
            <el-col :span="8">
              <span>归属部门：</span>
              <span>{{
                orderDetail.orgName
                  ? orderDetail.orgName
                  : orderDetail.teachManageOrgrName
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>订单状态：</span>
              <span>{{
                orderDetail.orderSourceName
                  ? orderDetail.orderSourceName +
                    "（" +
                    orderDetail.orderSourceRealName +
                    "）"
                  : orderDetail.teachManagerName +
                    "（" +
                    orderDetail.orderSourceRealName +
                    "）"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>订单提交人：</span>
              <span>{{
                orderDetail.orderSourceName
                  ? orderDetail.orderSourceName +
                    "（" +
                    orderDetail.orderSourceRealName +
                    "）"
                  : orderDetail.teachManagerName +
                    "（" +
                    orderDetail.orderSourceRealName +
                    "）"
              }}</span>
            </el-col>
            <el-col :span="8">
              <span>归属校区：</span>
              <span>{{
                orderDetail.schoolName ? orderDetail.schoolName : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>备注：</span>
              <span>{{
                orderDetail.remark ? orderDetail.remark : "暂无数据"
              }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-show="orderDetail.orderStatus == 1"
      >
        <el-button @click="conformOrder" type="primary" size="small"
          >通 过</el-button
        >
        <el-button type="primary" @click="refuseOrder" size="small"
          >驳 回</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="priceSeeShow"
      width="500px"
      :close-on-click-modal="false"
      center
      @close="priceClose()"
      custom-class="product-dialog"
    >
      <div>
        <div style="margin-top: 10px">
          <p style="text-align: center">
            充值订单已审核通过，请设置学生课时单价
          </p>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
            "
          >
            <el-button type="primary" @click="useFixed()" size="small"
              >使用单价表</el-button
            >
            <el-button type="primary" @click="useChange()" size="small"
              >自定义设置</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="全部订单"
      :visible.sync="orderDialogVisible"
      width="900px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="table-area">
          <el-table
            size="small"
            :data="orderData"
            style="width: 100%; margin-bottom: 20px"
            row-key="userId"
            border
            tooltip-effect="dark"
          >
            <el-table-column
              prop="studentName"
              label="缴费金额"
              align="center"
              :formatter="formatterZdyActualPrice"
            ></el-table-column>
            <el-table-column
              prop="zdyGiveClassHour"
              label="赠送课时"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="充值时间"
              align="center"
            ></el-table-column>
            <el-table-column
              :formatter="formatterOrderStatus"
              prop="usedClassHour"
              label="审核状态"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="orderSourceName"
              label="订单提交人"
              align="center"
            ></el-table-column>
            <el-table-column
              label="收款类型"
              align="center"
              :formatter="formatterMoneyType"
            ></el-table-column>
          </el-table>
        </div>
        <!-- <div class="page-area">
      <el-pagination
        @size-change="recordhandleSizeChange"
        @current-change="recordhandleCurrentChange"
        :current-page="recordParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="recordParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="recordTotal"
        background
      ></el-pagination>
    </div> -->
      </div>
    </el-dialog>
    <unitPriceList
      ref="unitPriceList"
      @CB_dialogStatus="CB_dialogStatus"
      :dialogStatus="dialogStatus"
      :sureName="sureName"
      :studentId="studentDetail.id"
      :sectionId="studentDetail.sectionId"
    ></unitPriceList>
    <customMoney
      ref="customMoney"
      v-if="Visible"
      :visible="Visible"
      :studentInfo="studentInfo"
      @close="Visible = false"
    ></customMoney>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  financeOrderList,
  orderAgree,
  setFixPrice,
} from "@/api/finance/finance.js";
import { orderDetail, orderUpdate, orderList } from "@/api/sales/myCustomer.js";
import { studentInfo } from "@/api/sales/saleManage.js";
import unitPriceList from "./dialog/unit-price-list.vue";
import customMoney from "./dialog/custom-money.vue";
export default {
  components: {
    unitPriceList,
    customMoney,
  },
  data() {
    return {
      studentAccount: 0,
      totalPrice: 0,
      studentInfo: {
        isShow: false,
        studentId: "",
      },
      Visible: false,
      sureName: "添加并为该学生设置",
      dialogStatus: false,
      activeIndex: "1",
      infoOrderType: "",
      srcList: [],
      orderList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        name: "",
        orderType: 0,
        orderStatus: 1,
        searchBeginDate: null,
        searchEndDate: null,
        dataAuthority: "dept",
      },
      total: 0,
      timePickerValue: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00");
              const start = new Date(
                dayjs().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(
                dayjs().add(1, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              const start = new Date(
                dayjs().subtract(90, "day").format("YYYY-MM-DD") + " 00:00:00"
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      productTypeList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 3,
          label: "试听课",
        },
        {
          value: 2,
          label: "一对多",
        },
        {
          value: 4,
          label: "公开课",
        },
      ],
      orderStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待财务审核",
        },
        {
          value: 2,
          label: "财务审核拒绝",
        },
        {
          value: 3,
          label: "教务主管分配中",
        },
        {
          value: 4,
          label: "待排课",
        },
        {
          value: 5,
          label: "排课中",
        },
        {
          value: 6,
          label: "已退款",
        },
        {
          value: 7,
          label: "订单已完成",
        },
        {
          value: 8,
          label: "订单作废",
        },
        {
          value: 9,
          label: "退款中",
        },
      ],
      subId: "",
      priceSeeShow: false,
      orderDetail: {},
      studentDetail: {},
      detailDialogVisible: false,
      rechargeParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        name: "",
        orderType: 5,
        orderStatus: 1,
        searchBeginDate: null,
        searchEndDate: null,
        dataAuthority: "dept",
      },
      keyValue: "1",
      rechargePicker: "",
      rechargeList: [],
      rechargeTotal: 0,
      orderDialogVisible: false,
      orderData: [],
    };
  },
  inject: ["reload"],
  methods: {
    handleSelect(key) {
      this.keyValue = key;
      this.getOrderList();
    },
    formatterProductType(item) {
      switch (item.productType) {
        case 1:
        case 3:
          return item.isSubscribe == 1 ? "试听课" : "一对一";
        case 2:
          return "一对多";
        case 4:
          return "公开课";
        case 5:
          return "一对多";
      }
    },
    formatterMoneyType(row) {
      switch (row.moneyType) {
        case 1:
          return "全款";
        case 2:
          return "定金";
        case 3:
          return "尾款";
        case 4:
          return "特殊订单";
        case 5:
          return "余额转充值";
        default:
          return "/";
      }
    },
    useFixed() {
      setFixPrice({
        id: null,
        studentId: this.studentDetail.id,
        sectionId: this.studentDetail.sectionId,
      }).then((res) => {
        if (res.code == 0 && res.msg == "操作成功！") {
          this.msgSuccess("操作成功！");
          this.priceSeeShow = false;
          this.detailDialogVisible = false;
          this.getOrderList();
        } else {
          this.msgWarn(res.rows);
          this.dialogStatus = true;
        }
      });
    },
    //查看全部订单
    allOrder() {
      this.orderDialogVisible = true;
      if (this.infoOrderType == 5) {
        orderList({ studentId: this.studentDetail.id, orderType: 5 }).then(
          (res) => {
            if (res.code == 0) {
              this.orderData = res.rows;
            }
          }
        );
      } else {
        orderList({ studentId: this.studentDetail.id }).then((res) => {
          if (res.code == 0) {
            this.orderData = res.rows;
          }
        });
      }
    },
    useChange() {
      this.$router.push({ name: "unitPrice" });
    },
    formatterOrderStatus(item) {
      if (item.orderStatus) {
        for (let i = 0, len = this.orderStatusList.length; i < len; i++) {
          if (this.orderStatusList[i].value == item.orderStatus) {
            return this.orderStatusList[i].label;
          }
        }
      }
    },
    formatterMoneyType(item) {
      switch (item.moneyType) {
        case 1:
          return "全款";
        case 2:
          return "定金";
        case 3:
          return "尾款";
        case 4:
          return "特殊订单";
        case 5:
          return "余额转充值";
        default:
          return "";
      }
    },
    formatterZdyActualPrice(row) {
      //9.28 没有实际支付  就显示总价字段
      if (row.zdyActualPrice) {
        return row.zdyActualPrice;
      } else {
        if (row.zdyActualPrice) {
          return row.zdyActualPrice;
        } else {
          return row.totalPrice;
        }
      }
    },
    datePickerChange(type) {
      if (type == 1) {
        //充值审核
        if (!this.rechargePicker) {
          this.rechargeParams.searchBeginDate = null;
          this.rechargeParams.searchEndDate = null;
          return;
        }
        this.rechargeParams.searchBeginDate = this.rechargePicker[0];
        this.rechargeParams.searchEndDate = this.rechargePicker[1];
      } else {
        //订单审核
        if (!this.timePickerValue) {
          this.listParams.searchBeginDate = null;
          this.listParams.searchEndDate = null;
          return;
        }
        this.listParams.searchBeginDate = this.timePickerValue[0];
        this.listParams.searchEndDate = this.timePickerValue[1];
      }
    },
    CB_dialogStatus() {
      this.dialogStatus = false;
      this.priceSeeShow = false;
      this.getOrderList();
    },
    getOrderList() {
      var data = {};
      if (this.keyValue == 1) {
        data = this.rechargeParams;
      } else {
        data = this.listParams;
      }
      financeOrderList(data).then((res) => {
        if (this.keyValue == 1) {
          this.rechargeList = res.rows;
          this.rechargeTotal = res.total;
        } else {
          this.orderList = res.rows;
          this.total = res.total;
        }
      });
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.getOrderList();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.getOrderList();
    },
    rechargeSizeChange(pageSize) {
      this.rechargeParams.pageSize = pageSize;
      this.getOrderList();
    },
    rechargeCurrentChange(currentPage) {
      this.rechargeParams.pageNumber = currentPage;
      this.getOrderList();
    },
    refreshClick() {
      this.reload();
    },
    dealSeeDetail(item) {
      this.detailDialogVisible = true;
      orderDetail(item.id).then((res) => {
        if (res.code == 0) {
          this.orderDetail = res.rows;
          this.totalPrice = res.rows.totalPrice;
          this.srcList.push(res.rows.certificate);
          this.infoOrderType = res.rows.orderType;
          switch (this.orderDetail.payMent) {
            case 1:
              this.orderDetail.payMentName = "支付宝";
              break;
            case 2:
              this.orderDetail.payMentName = "微信";
              break;
            case 3:
              this.orderDetail.payMentName = "对公账户";
              break;
            case 4:
              this.orderDetail.payMentName = "POS";
              break;
            default:
              this.orderDetail.payMentName = "";
          }
          switch (this.orderDetail.moneyType) {
            case 1:
              this.orderDetail.moneyTypeName = "全款";
              break;
            case 2:
              this.orderDetail.moneyTypeName = "定金";
              break;
            case 3:
              this.orderDetail.moneyTypeName = "尾款";
              break;
            case 4:
              this.orderDetail.moneyTypeName = "特殊订单";
              break;
            case 5:
              this.orderDetail.moneyTypeName = "余额转充值";
              break;
            default:
              this.orderDetail.moneyTypeName = "";
          }
          if(this.orderDetail.payType == 3) {
            this.orderDetail.payMentName = '--'
          }
          switch (this.orderDetail.orderStatus) {
            case 1:
              this.orderDetail.orderStatusName = "待财务审核";
              break;
            case 2:
              this.orderDetail.orderStatusName = "财务审核拒绝";
              break;
            case 3:
              this.orderDetail.orderStatusName = "教务主管分配中";
              break;
            case 4:
              this.orderDetail.orderStatusName = "待排课";
              break;
            case 5:
              this.orderDetail.orderStatusName = "排课中";
              break;
            case 6:
              this.orderDetail.orderStatusName = "已退款";
              break;
            case 7:
              this.orderDetail.orderStatusName = "订单已完成";
              break;
            case 8:
              this.orderDetail.orderStatusName = "订单作废";
              break;
            case 8:
              this.orderDetail.orderStatusName = "退款中";
              break;
            default:
              this.orderDetail.orderStatusName = "";
          }
          switch (this.orderDetail.productType) {
            case 1:
            case 3:
              this.orderDetail.productTypeName =
                this.orderDetail.isSubscribe == 1 ? "试听课" : "一对一";
              break;
            case 2:
            case 5:
              this.orderDetail.productTypeName = "一对多";
              break;
            case 4:
              this.orderDetail.productTypeName = "公开课";
              break;
            default:
              this.orderDetail.productTypeName = "";
          }
        }
      });
      studentInfo(item.studentId).then((res) => {
        if (res.code == 0) {
          this.studentAccount = res.rows.studentAccount;
          this.studentDetail = res.rows;

          if (this.studentDetail.provinceName) {
            this.studentDetail.address =
              this.studentDetail.provinceName +
              "" +
              this.studentDetail.cityName +
              "" +
              this.studentDetail.countyName;
          }
        }
      });
    },
    priceClose() {
      this.priceSeeShow = false;
      this.detailDialogVisible = false;
    },
    studentSex(val) {
      if (val == 1) {
        return "男";
      } else if (val == 2) {
        return "女";
      } else if (val == null || val == "") {
        return "";
      }
    },
    refuseOrder() {
      this.detailDialogVisible = false;
      this.$prompt("请输入驳回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入驳回原因不能为空！",
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          this.detailDialogVisible = false;
          orderUpdate({
            id: this.orderDetail.id,
            disagreeCause: value,
            orderStatus: 2,
          }).then((res) => {
            if (res.code == 0 && res.msg == "操作成功！") {
              this.msgSuccess("操作成功！");
              this.getOrderList();
            } else {
              this.msgWarn(res.rows);
            }
          });
        })
        .catch(() => {
          this.detailDialogVisible = true;
        });
    },
    conformOrder() {
      if (this.infoOrderType == 5) {
        //充值审核
        orderAgree({
          id: this.orderDetail.id,
          studentId: this.studentDetail.id,
          xmtStudent: {
            contract: this.studentDetail.contract,
          },
        }).then((res) => {
          if (res.code == 0 && res.msg == "操作成功！") {
            //之前配置过
            this.detailDialogVisible = false;
            this.msgSuccess("操作成功！");
            this.getOrderList();
          } else if (res.code == 0 && res.rows.id) {
            this.priceSeeShow = true;
            this.studentInfo.id = res.rows.id;
          } else {
            this.msgWarn(res.rows);
          }
        });
      } else {
        orderAgree({
          id: this.orderDetail.id,
          studentId: this.studentDetail.id,
          xmtStudent: {
            contract: this.studentDetail.contract,
          },
        }).then((res) => {
          if (res.code == 0 && res.msg == "操作成功！") {
            this.detailDialogVisible = false;
            this.msgSuccess("操作成功！");
            this.getOrderList();
          } else {
            this.msgWarn(res.rows);
          }
        });
      }
    },
    importExcel(type) {
      let jsonData = [],
        str =
          type == 2
            ? `学生姓名,年级,订单编号,产品名称,产品类型,下单时间,订单状态,订单提交人,收款类型\n`
            : `学生姓名,年级,缴费金额,赠送课时,充值时间,审核状态,订单提交人,收款类型`;
      if (type == 1) {
        //充值审核
        for (let i = 0, len = this.rechargeList.length; i < len; i++) {
          jsonData.push({
            studentName: this.rechargeList[i].studentName,
            studentSectionName: this.rechargeList[i].studentSectionName,
            totalPrice: this.rechargeList[i].totalPrice,
            zdyGiveClassHour: this.rechargeList[i].zdyGiveClassHour,
            createTime: this.rechargeList[i].createTime,
            orderStatus: this.formatterOrderStatus(this.rechargeList[i]),
            saleName: this.rechargeList[i].saleName,
            moneyType: this.formatterMoneyType(this.rechargeList[i]),
          });
        }
      } else {
        for (let i = 0, len = this.orderList.length; i < len; i++) {
          jsonData.push({
            studentName: this.orderList[i].studentName,
            studentSectionName: this.orderList[i].studentSectionName,
            orderNo: this.orderList[i].orderNo,
            productName: this.orderList[i].productName,
            productType: this.formatterProductType(this.orderList[i]),
            createTime: this.orderList[i].createTime,
            orderStatus: this.formatterOrderStatus(this.orderList[i]),
            saleName: this.orderList[i].saleName,
            moneyType: this.formatterMoneyType(this.orderList[i]),
          });
        }
      }
      // 增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < jsonData.length; i++) {
        for (const key in jsonData[i]) {
          str += `${jsonData[i][key] + "\t"},`;
        }
        str += "\n";
      }
      // encodeURIComponent解决中文乱码
      const uri =
        "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      // 通过创建a标签实现
      const link = document.createElement("a");
      link.href = uri;
      // 对下载的文件命名
      link.download = "审核列表.xls";
      link.click();
    },
  },
  created() {
    this.getOrderList();
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      display: flex;
      .select-condition-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .item-lable {
          word-break: keep-all;
        }
      }
    }
  }
  .dialog-content {
    .detail_type {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: #000;
    }
    .row_detail {
      padding: 6px 0;
    }
    .row_detail2 {
      span {
        font-weight: bold;
        color: #ff3f3f;
      }
    }
    .certificate {
      width: 80px;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
>>> .td-img .el-icon-circle-close {
  color: white !important;
}
a {
  text-decoration: none;
}
</style>
