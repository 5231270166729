<template>
    <div class="main-content sys-user-manage">
        <div class="btn-area">
      <div class="left">
        <div class="select-condition-item">
          <span class="item-lable">关键字：</span>
          <el-input
            v-model="listParams.name"
            placeholder="教师姓名/联系方式"
            size="small"
            style="min-width:120px"
          ></el-input>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">选择时间：</span>
             <el-date-picker
                v-model="listParams.salaryMonth"
                type="month"
                @change="salaryMonthFn"
                placeholder="选择月">
            </el-date-picker>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">课酬通知情况：</span>
          <el-select
            v-model="listParams.noticeStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in noticeStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select-condition-item">
          <span class="item-lable">打款状态：</span>
          <el-select
            v-model="listParams.applyStatus"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in applyStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-search"
          @click="()=>(listParams.pageNumber=1,teacherPayWagesList())"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="table-area">
      <div style="display:flex;align-items: center;justify-content: space-between;">
        <p>
          <el-button type="primary" class="mR10" size="small" @click="summaryClick()" style="margin-top:10px;">工资汇总</el-button>
          <el-button type="primary" class="mR10" size="small" @click="importExcel()" style="margin-top:10px;">导出excel</el-button>
          <el-button type="primary" class="mR10" size="small"  @click="oneClickPayment()" style="margin-top:10px;">一键打款</el-button>
          <span>课时数：{{countMap.totalClassHours?countMap.totalClassHours:0}}</span>
          <span>课酬：{{countMap.totalCoursePay?countMap.totalCoursePay:0}}</span>
        </p>
        <p>
          <span>最新汇总时间：{{dataList.length?dataList[0].createTime:''}}</span>
          <el-button type="default" size="small" @click="refreshClick">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shuaxin" />
            </svg>
            <span>刷新</span>
          </el-button>
        </p>
      </div>
      <el-table
        size="small"
        :data="dataList"
        style="width: 100%; margin: 20px 0;"
        row-key="userId"
        border
        height="100%"
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          label="编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="salaryMonth"
          label="月份"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="教师姓名"
          align="center"
        >
            <template slot-scope="scope">
                <div style="text-decoration:underline;cursor:pointer;" @click="daalDetail(scope.row)">{{ scope.row.realname }}</div>
            </template>
        </el-table-column>
        <el-table-column
          prop="subjectName"
          label="科目"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sectionName"
          label="学段"
          align="center"
          :formatter="dealPeriodId"
        >
        </el-table-column>
        <el-table-column
          label="总课时数"
          align="center"
          prop="totalClassHours"
        ></el-table-column>
        <el-table-column
          prop="totalCoursePay"
          label="总课酬"
          align="center"
        ></el-table-column>
        <el-table-column
          label="课酬通知情况"
          align="center"
        >
          <template slot-scope="scope">
            <p v-show="scope.row.noticeStatus==0">未通知</p>
            <p v-show="scope.row.noticeStatus==1">已通知</p>
            <p v-show="scope.row.noticeStatus==2">已确认</p>
            <p v-show="scope.row.noticeStatus==3">有异议<el-button type="text" size="mini" @click="seeNotice(scope.row)">（查看）</el-button></p>
          </template>
        </el-table-column>
        <el-table-column
          prop="saleName"
          label="打款状态"
          :formatter="formatterApplyStatus"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
      title="需要汇总的月份"
      :visible.sync="summaryShow"
      :close-on-click-modal="false"
      width="500px"
      center
    > 
      <div class="dialog-content">
        <div class="item_select">
          <el-date-picker
            v-model="month"
            type="month"
            @change="monthChange"
            placeholder="请选择月份">
         </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="summaryShow = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="summaryDefine()" size="small" :loading="summaryLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="详情"
      :visible.sync="detailDialogVisible"
      width="1100px"
      :close-on-click-modal="false"
      center
      custom-class="product-dialog"
    >
      <div class="dialog-content">
        <div class="detail_type">基本信息</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>教师姓名：</span>
              <span>{{teacherDetail.name}}</span>
            </el-col>
            <el-col :span="8">
              <span>学段：</span>
              <span>{{dealPeriodId(teacherDetail)}}</span>
            </el-col> 
            <el-col :span="8">
              <span>科目：</span>
              <span>{{teacherDetail.subjectName ? teacherDetail.subjectName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>联系电话：</span>
              <span>{{teacherDetail.phoneNo ? teacherDetail.phoneNo : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
             <el-button type="primary" size="small" @click="sendTeacher()">发送老师</el-button>
             <el-button type="primary" size="small" @click="paymentClick()" v-show="applyStatus==0">去打款</el-button>
             <el-button type="info" disabled size="small" v-show="applyStatus==1">已打款</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="detail_type">账户信息</div>
        <div>
          <el-row :gutter="20" class="row_detail" type="flex" align="bottom">
            <el-col :span="8">
              <span>开户人姓名：</span>
              <span>{{teacherDetail.bankUserName ? teacherDetail.bankUserName : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>联系方式：</span>
              <span>{{teacherDetail.bankUserPhone ? teacherDetail.bankUserPhone : '暂无数据'}}</span>
            </el-col> 
            <el-col :span="8">
              <span>开户支行：</span>
              <span>{{teacherDetail.bankAddress ? teacherDetail.bankAddress : '暂无数据'}}</span>
            </el-col>
            <el-col :span="8">
              <span>银行账户：</span>
              <span>{{teacherDetail.bankAccount ? teacherDetail.bankAccount : '暂无数据'}}</span>
            </el-col>
          </el-row>
        </div>
        <div class="table-area">
          <el-table
            size="small"
            :data="classInfoData"
            style="width: 100%; margin:20px 0"
            row-key="id"
            border
            tooltip-effect="dark"
          >
          <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop=""
              label="学生姓名/班级名称"
              align="center"
              :formatter="formatterShow"
            ></el-table-column>
            <el-table-column
              prop="sectionName"
              label="年级"
              align="center"
              width="75"
            ></el-table-column>
            <el-table-column
              prop="usedClassHour"
              label="总课时数"
              align="center"
               width="75"
            ></el-table-column>
            <el-table-column
              prop="teachFee"
              label="总课酬"
              align="center"
            ></el-table-column>
            <el-table-column
              label="教务姓名"
              align="center"
              prop="teachManagerName"
            >
              <template slot-scope="scope">
                  <span>{{scope.row.teachManagerName}}（{{scope.row.teachManagerRealName}}）</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="saleName"
              label="所属销售"
              align="center"
            >
              <template slot-scope="scope">
                  <span v-show="scope.row.saleRealName">{{scope.row.saleName}}（{{scope.row.saleRealName}}）</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="schoolName"
              label="归属校区"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="seeStudentInfo(scope.row)"
                  >详情</el-button
                > </template
              >
            </el-table-column>
          </el-table>
          <p style="float:right;"><span>{{salaryMonth}}月份共{{classHours}}课时，其中异常课时：{{abnormalClassHours}}课时，正常课时：{{totalClassHours}}课时，可发送课酬共计{{totalCoursePay}}元</span></p>
        </div>
      </div>
    </el-dialog>
     <el-dialog
      :visible.sync="apportionVisible"
      :close-on-click-modal="false"
      width="500px"
      center
    >
      <div class="dialog-content">
        <div class="item_select">
          <span>打款时间：</span>
         <el-date-picker
            v-model="pickerDate"
            type="datetime"
            :picker-options="pickerDateOptions()"
            format="yyyy-MM-dd HH:mm"
            @change="dateValueChange"

            placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="apportionVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small"
          @click="pickerDateClick()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="seeNoticePopup"
      width="500px"
      center
    >
      <div >
        <div class="bbb">
          <p style="font-size:16px;font-weight:600;">请跟据老师异议，联系教务老师核对</p>
        <div>
           <ul class="notice_ul" v-for="(item,index) in noticeList" :key="index">
            <li>第{{index+1}}节</li>
            <li>上课时间：{{item.classBeginTime}}</li>
            <li>学生姓名：{{item.studentName}}</li>
            <li>课时数量：{{item.classNum}}</li>
            <li>教务老师：{{item.teacherManagerName}}</li>
          </ul>
        </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="clickPaymentPopup"
      width="540px"
      center
    >
      <div>
        <p style="font-size:16px;padding-bottom:20px;">请选择需要打款的老师</p>
        <div>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 10px 0;"></div>
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox  v-for="city in cities" :label="city.realname" :key="city.id" style="margin-top:6px;">{{city.realname}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clickPaymentPopup = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmSave()"
          size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="seeStuShow"
      width="1100px"
      center
      :close-on-click-modal="false"
      custom-class="product-dialog"
    >
      <div class="dialog-content">
         <div class="table-area">
      <el-table
        size="small"
        :data="seeStuList"
        style="width: 100%; "
        row-key="id"
        border
        tooltip-effect="dark"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="beginTime"
          label="上课时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="学生姓名/班级名称"
          align="center"
          :formatter="formatterShow"
        ></el-table-column>
        <el-table-column
          prop="sectionName"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="usedClassHour"
          label="课时数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachFee"
          label="课酬"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="teachFee"
          label="是否计算老师课酬"
          align="center"
        >
          <template slot-scope="scope">
              <span>{{scope.row.ifClassRemuneration==1?'不计算':'计算'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="上课方式"
          align="center"
          :formatter="formatterClassMode"
        ></el-table-column>
        <el-table-column
          prop="learnFee"
          label="产品类型"
          align="center"
          :formatter="formatterOrderType"
        >
        </el-table-column>
        <el-table-column
         prop="planTeachFee"
          label="教务姓名"
          align="center"
        >
          <template slot-scope="scope">
              <span>{{scope.row.teachManagerName}}（{{scope.row.teachManagerRealName}}）</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="所属销售"
          align="center"
        >
          <template slot-scope="scope">
              <span v-show="scope.row.saleRealName">{{scope.row.saleName}}（{{scope.row.saleRealName}}）</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="schoolName"
          label="归属校区"
          align="center"
        ></el-table-column>
        <el-table-column
              prop=""
              label="课程状态"
              align="center"
            >
              <template slot-scope="scope">
                  <span v-if="scope.row.abnormalStatus==0">正常</span>
                  <span v-else style="color:red;">异常</span>
              </template>
            </el-table-column>
      </el-table>
     
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="stuSizeChange"
        @current-change="stuCurrentChange"
        :current-page="seeStuParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="seeStuParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="seeTotal"
        background
      ></el-pagination>
    </div>
      </div>
    </el-dialog>
    </div>
</template>
<script>
    import {
  getSelectList,
  sectionCode,
  subjectCode,
  teachStyle,
  teachYear,
  teachSpeed,
  constellation,
  followTags,
  fileUpload,
  reg_Num,
} from "@/api/public";
import dayjs from "dayjs";
import XLSX from "xlsx"
import { 
    teacherPayWagesList,
    summaryTeacherSalary,
    getInfoByTeacherId,
    teacherPayWagesUpdate,//打款
    teacherPayWagesSendMsg,
    importByTime,
    teacherPayWagesAbnormal,
    batchApply,
    getProductItemInfoByTeacherId
} from '@/api/finance/finance.js'
export default{
    data(){
        return{
          summaryLoading:false,
          seeStuShow:false,
          allElection:[],
          selectionArr:[],
          isIndeterminate: true,
          checkAll: false,
          checkedCities:[],
          cities:[],
          clickPaymentPopup:false,
          noticeList:[],
          seeStuParams:{
            pageNumber:1,
            pageSize:10,
            teacherId:"",
            time:"",
            studentId:"",
            classroomId:""
          },
          seeStuList:[],
          seeTotal:0,
            listParams:{
                pageNumber:1,
                pageSize:10,
                name:"",
                salaryMonth:"",
                noticeStatus:"",
                applyStatus:""
            },
            paymentParams:{
                pageNumber:1,
                pageSize:10000,
                name:"",
                salaryMonth:"",
                noticeStatus:"",
                applyStatus:"0"
            },
            tableTime:"",
            dataList:[],
            detailDialogVisible:false,
            total:0,
            datetime:"",
            summaryShow:false,
            month:"",
            newMonth:"",
            applyStatus:"",
            apportionVisible:false,
            pickerDate:"",
            totalClassHours:'',
            totalCoursePay:'',
            salaryMonth:"",
            classHours:"",
            abnormalClassHours:"",
            isExamine:false,
            pickerDateOptions() {
                  // this.oldTime 是上一条数据的开始时间
                  let date = new Date(this.tableTime)
                  let minutes = date.getMinutes() + 1
                  // h和m 是将日期只取时分
                  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                  let m = (minutes < 10 ? '0' + minutes : minutes);
                  return {
                    // 日期限制
                    disabledDate: (time) => {
                      let old = this.tableTime
                      return time.getTime() < new Date(old).getTime() - 86400000
                    },
                    // 时间限制
                    selectableRange: h + m  + ':00 - 23:59:59'
                  }
                },
             pickerDateParams:{
                  id:"",
                  applyAmountTime:null,
                  applyStatus:1
                },
                countMap:{},
            isClickLock:true,
            teacherDetail:{
              name:"",
              sectionName:"",
              subjectName:"",
              phoneNo:"",
              bankUserName:"",
              bankUserPhone:"",
              bankType:"",
              bankAccount:"",
              periodId:""
            },
            seeNoticePopup:false,
            teacherOrderList:[],
            classInfoData:[],
            teacherParams:{
              teacherId:"",
              time:"",
            },
            noticeStatusList:[
                {
                    label:'请选择',
                    value:''
                },
                {
                    label:'未通知',
                    value:'0'
                },
                {
                    label:'已通知',
                    value:'1'
                },
                {
                    label:'已确认',
                    value:'2'
                },
                {
                    label:'有异议',
                    value:'3'
                }
            ],
            applyStatusList:[
                {
                    label:'请选择',
                    value:''
                },
                {
                    label:'未打款',
                    value:'0'
                },
                {
                    label:'已打款',
                    value:'1'
                }
            ]
        }
    },
    inject: ["reload"],
    mounted(){
        this.teacherPayWagesList();
    },
    methods:{
        teacherPayWagesList(){
            teacherPayWagesList(this.listParams).then((res) => {
                this.dataList = res.rows;
                this.total = res.total;
                this.countMap=res.countMap?res.countMap:{}
                
            });
        },
        handleSizeChange(pageSize) {
            this.listParams.pageSize = pageSize;
            this.teacherPayWagesList();
        },
        handleCurrentChange(currentPage) {
            this.listParams.pageNumber = currentPage;
            this.teacherPayWagesList();
        },
        salaryMonthFn(){
            this.listParams.pageNumber=1;
            var d=new Date(this.listParams.salaryMonth);
            this.datetime=this.timeFn(d);
            this.listParams.salaryMonth=this.datetime;
            this.paymentParams.salaryMonth=this.datetime;
            this.teacherPayWagesList();
            
       },
       teacherPayWagesList2(){
          teacherPayWagesList(this.paymentParams).then((res) => {
              if(res.rows.length){
                this.cities=res.rows;
                this.allElectionFun();
                this.DefaultFullSelection();
              }
            });
       },
       monthChange(){
          var d=new Date(this.month);
          this.newMonth=this.timeFn(d);
          this.listParams.salaryMonth=this.newMonth;
       },
       allElectionFun() { // 获取需要默认显示的数据
          this.allElection = [];
            for (var i = 0; i < this.cities.length; i++) {
              this.allElection.push(this.cities[i].realname)
          }
      },
      DefaultFullSelection() {
      // 初始化 默认全部选中
      this.checkedCities = this.allElection;
      let checkedCount = this.checkedCities.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      this.selectionFun(this.checkedCities);
    },
    selectionFun(selectionArr) { // 获取选中的对象
      this.selectionArr = [];
        for (var i = 0; i < this.cities.length; i++) {
          for (var j = 0; j < selectionArr.length; j++) {
                if (selectionArr[j] === this.cities[i].realname) {
                  this.selectionArr.push(this.cities[i])
              }
            }
      }},
       handleCheckAllChange(val){//全选
         this.allElectionFun();
         this.checkedCities = val ? this.allElection : [];
         this.isIndeterminate = false;
         this.selectionFun(this.checkedCities);
      },
      handleCheckedCitiesChange(value){
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < 
        this.cities.length;
        this.selectionFun(value);
      },
      confirmSave(){
        if(this.selectionArr.length==0){
          this.msgWarn("请选择打款老师！");
        }else{
          batchApply(this.selectionArr).then((res) => {
          if(res.code==0){
            this.msgSuccess("操作成功！");
            this.clickPaymentPopup=false;
            this.teacherPayWagesList();
          }else{
            this.msgWarn(res.msg);
          }
        });
        }
      },
       oneClickPayment(){
         this.teacherPayWagesList2();
         if(this.listParams.salaryMonth==''){
           this.msgWarn("请先选择打款月份！");
         }else{
            var startDate=this.listParams.salaryMonth.split('-');
            var strDate=new Date().getFullYear()+'-'+(new Date().getMonth()+1);
            var endDate=strDate.split('-');
            var startDateTemp = startDate[0] + "/" + Number(startDate[1]) ;
            var endDateTemp = endDate[0] + "/" + Number(endDate[1]) ;
            console.log(startDateTemp,endDateTemp)
            if (startDateTemp < endDateTemp){
              this.clickPaymentPopup=true;
            }
            else{
                console.log("大于或者等于");
            }
         }
       },
       seeStudentInfo(row){
         this.seeStuShow=true;
         this.seeStuParams.pageNumber=1;
         this.seeStuParams.pageSize=10;
         this.seeStuParams.studentId=row.studentId?row.studentId:"";
         this.seeStuParams.classroomId=row.classroomId?row.classroomId:"";
         this.getProductItemInfoByTeacherIdFn();
       },
       getProductItemInfoByTeacherIdFn(){
         getProductItemInfoByTeacherId(this.seeStuParams).then((res) => {
            this.seeStuList=res.rows;
            this.seeTotal=res.total;
        });
       },
       stuSizeChange(pageSize){
         this.seeStuParams.pageSize = pageSize;
         this.getProductItemInfoByTeacherIdFn();
       },
       stuCurrentChange(currentPage){
         this.seeStuParams.pageNumber = currentPage;
         this.getProductItemInfoByTeacherIdFn();
       },
       sendTeacher(){
         teacherPayWagesSendMsg(this.teacherParams).then((res) => {
            if(res.code==0){
              this.msgSuccess("操作成功！");
              this.detailDialogVisible=false;
              this.teacherPayWagesList();
            }else{
              this.msgWarn(res.rows);
            }
        });
       },
       summaryClick(){
           this.summaryShow=true;
           this.month="";
       },
       dealPeriodId(row){
        var str = "";
        var arr2 = new Array();
        if (row.periodId) {
          arr2 = row.periodId.split(",");
        }
        for (var i = 0; i < arr2.length; i++) {
          if (arr2[i] == 107) {
            str += "高中,";
          } else if (arr2[i] == 106) {
            str += "初中,";
          } else if (arr2[i] == 105) {
            str += "小学,";
          } else if (arr2[i] == 108) {
            str += "艺术培训,";
          } else if (arr2[i] == 109) {
            str += "小语种,";
          } else if (arr2[i] == 235) {
            str += "全学段,";
          }
        }
        return str.substring(0, str.length - 1);
      },
       importExcel(){
         if(!this.listParams.salaryMonth){
           this.msgWarn('请先选择汇总月份！');
           return false;
         }
         importByTime({"time":this.listParams.salaryMonth}).then((res) => {
            if(res.code==0){
              this.teacherOrderList=res.rows;
              console.log(res.rows.length)
              this.msgSuccess("操作成功！");
              if(this.teacherOrderList.length){
                let jsonData = [], str = `老师姓名,上课时间,学生姓名/班级名称,年级,课时数,课酬,上课方式,产品类型,教务姓名,所属人,归属校区,课程状态\n`
                for(let i = 0, len = this.teacherOrderList.length; i < len; i++){
                  jsonData.push({
                    'teacherRealName': this.teacherOrderList[i].teacherRealName,
                    'beginTime': this.teacherOrderList[i].beginTime,
                    'studentName': this.teacherOrderList[i].studentName?this.teacherOrderList[i].studentName:this.teacherOrderList[i].roomName,
                    'sectionName': this.teacherOrderList[i].sectionName,
                    'usedClassHour': this.teacherOrderList[i].usedClassHour,
                    'teachFee': this.teacherOrderList[i].teachFee,
                    'createTime': this.teacherOrderList[i].classMode==1?'线上':'线下',
                    'orderType': this.teacherOrderList[i].orderType==1?'一对一':this.teacherOrderList[i].orderType==2?'一对多':'试听课',
                    'teachManagerName': this.teacherOrderList[i].teachManagerName+'('+this.teacherOrderList[i].teachManagerRealName+')',
                    'saleName': this.teacherOrderList[i].saleName+'('+this.teacherOrderList[i].saleRealName+')',
                    'schoolName':this.teacherOrderList[i].schoolName?this.teacherOrderList[i].schoolName:'',
                    'abnormalStatus':this.teacherOrderList[i].abnormalStatus==0?'正常':'异常',
                })
                }
                for(let i = 0 ; i < jsonData.length ; i++ ){
                    for(const key in jsonData[i]){
                        str+=`${jsonData[i][key] + '\t'},`;
                    }
                    str+='\n';
                }
                const uri = 'data:text/xls;charset=utf-8,\ufeff' + encodeURIComponent(str);
                const link = document.createElement("a");
                link.href = uri;
                link.download =  "老师审核列表.xls";
                link.click();
              }
            }else{
              this.msgWarn(res.rows);
            }
        });
    },
       daalDetail(row){
         this.detailDialogVisible=true;
         this.teacherParams.teacherId=row.teacherId;
         this.teacherParams.time=row.salaryMonth;
         this.seeStuParams.teacherId=row.teacherId;
         this.seeStuParams.time=row.salaryMonth;
         this.getInfoByTeacherIdFn();
       },
       getInfoByTeacherIdFn(){
         getInfoByTeacherId(this.teacherParams).then((res) => {
            if(res.code==0){
                this.teacherDetail.name=res.rows.name;
                this.teacherDetail.sectionName=res.rows.sectionName;
                this.teacherDetail.subjectName=res.rows.subjectName;
                this.teacherDetail.phoneNo=res.rows.phoneNo;
                this.teacherDetail.bankUserName=res.rows.bankUserName;
                this.teacherDetail.bankUserPhone=res.rows.bankUserPhone;
                this.teacherDetail.bankType=res.rows.bankType;
                this.teacherDetail.bankAccount=res.rows.bankAccount;
                this.teacherDetail.bankAddress=res.rows.bankAddress;
                this.teacherDetail.periodId=res.rows.periodId;
                this.classInfoData=res.rows.xmtProductItemEntity;
                this.applyStatus=res.rows.applyStatus;
                this.pickerDateParams.id=res.rows.id;
                this.salaryMonth=res.rows.salaryMonth?res.rows.salaryMonth:"";
                this.classHours=Number(res.rows.abnormalClassHours)+Number(res.rows.totalClassHours);
                this.abnormalClassHours=res.rows.abnormalClassHours;
                this.totalClassHours=res.rows.totalClassHours;
                this.totalCoursePay=res.rows.totalCoursePay;
                // this.totalClassHours=res.rows.totalClassHours;
                // this.totalCoursePay=res.rows.totalCoursePay?res.rows.totalCoursePay:0;
                this.tableTime = new Date(res.rows.createTime)
            }
        });
       },
       seeNotice(row){
         this.seeNoticePopup=true;
         let params={
            "pageNumber":1,
            "pageSize":1000,
            "status":1,
            "payWagesId":row.id
         }
         teacherPayWagesAbnormal(params).then((res) => {
            this.noticeList=res.rows;
          });
       },
       refreshClick() {
        //  console.log(this.listParams.pageSize,this.listParams.pageNumber)
         this.teacherPayWagesList()
      },
       summaryDefine(){
         if(this.newMonth==''){
           this.msgWarn("汇总月份不能为空！");
           return;
         }
         this.summaryLoading=true;
          summaryTeacherSalary({time:this.newMonth}).then((res) => {
              this.summaryLoading=false;
                if(res.code==0&&res.rows=="汇总成功"){
                    this.msgSuccess(res.rows);
                    this.summaryShow=false;
                    this.teacherPayWagesList();
                }else{
                  this.msgWarn(res.rows);
                   this.summaryLoading=false;
                }
            });
       },
       paymentClick(){
         let num=Number(this.salaryMonth.split('-')[1])
         if(this.salaryMonth){
          if(new Date().getFullYear()==Number(this.salaryMonth.split('-')[0])){
            if(new Date().getMonth()+1>num){
              this.apportionVisible=true;
            }else{
              this.msgWarn("当月课酬不可打款！");
            }
          }
          if(new Date().getFullYear()>Number(this.salaryMonth.split('-')[0])){
            this.apportionVisible=true;
          }else{
            // this.msgWarn("当月课酬不可打款！");
          }
         }
       },
       pickerDateClick(){
         teacherPayWagesUpdate(this.pickerDateParams).then(res => {
                if(res.code==0){
                   this.msgSuccess("操作成功！");
                   this.apportionVisible=false;
                   this.getInfoByTeacherIdFn();
                   this.teacherPayWagesList();
                }
            })
       },
       dateValueChange(){
         this.pickerDateParams.applyAmountTime=this.pickerDate;
       },
        formatterClassMode(item){
          if(item.classMode==1){
            return '线上'
          }else if(item.classMode==2){
            return '线下'
          }
        },
        formatterOrderType(item){
          if(item.orderType==1){
            return '一对一'
          }else if(item.orderType==2||(item.orderType==null&&item.classroomId)){
            return '一对多'
          }else if(item.orderType==3){
            return '试听课'
          }
        },
        formatterShow(item){
           return  item.roomName?item.roomName:item.studentName;
        },
        formatterApplyStatus(item){
            if(item.applyStatus==0){
                return '未打款'
            }else if(item.applyStatus==1){
                return '已打款'
            }
        },
        timeFn(date) {
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? '0' + m : m
            var d = date.getDate()
            d = d < 10 ? '0' + d : d
            var h = date.getHours()
            h = h < 10 ? '0' + h : h
            var minute = date.getMinutes()
            minute = minute < 10 ? '0' + minute : minute
            var second = date.getSeconds()
            second = second < 10 ? '0' + second : second
            return y + '-' + m;
        },
    }
}
</script>
<style scoped lang="scss">
    .main-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .btn-area {
            height: 50px;
            display: flex;
            align-items: center;
            .left {
            flex: 1;
            display: flex;
            .select-condition-item {
                display: flex;
                align-items: center;
                margin-right: 20px;
                .item-lable {
                word-break: keep-all;
                }
            }
            }
        }
        .dialog-content{
            .detail_type{
            font-size: 18px;
            line-height: 30px;
            font-weight: 900;
            }
            .row_detail{
            padding: 6px 0;
            }
            .certificate{
            width: 80px;
            }
        }
        .table-area {
            flex: 1;
            overflow: hidden;
        }
        .page-area {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .detail_type{
          font-size: 18px;
          line-height: 30px;
          font-weight: 900;
        }
        .row_detail{
          padding: 6px 0;
        }
        .item_select {
          display: flex;
          align-items: center;
          span {
            width: 120px;
          }
        }
        }
        .bbb{
          display: flex;
          flex-direction: column;
          height:500px;
          >div{
            flex:1;
            height:300px;
            overflow-x:hidden;
          }
        }
        .notice_ul{
          font-size:15px;
          margin-top:20px;
          li{
            line-height: 2;
          }
          li:nth-child(1){
            font-weight: 600;
          }
        }
</style>