<template>
  <div class="main-content sys-role-manage">
    <div class="btn-area">
      <div class="left">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input v-model="listParams.name" placeholder="请输入学生姓名" size="small"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="()=>(listParams.pageNumber=1,studentUnitPriceListFn())"
            >搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button-group>
          <el-button type="default" size="small" @click="getUnitPrice()">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-quanxian2" />
            </svg>
            <span>单价表</span>
          </el-button>
        </el-button-group>
      </div>
    </div>
    <div class="table-area">
      <el-table
        size="small"
        :data="dataList"
        style="width: 100%;margin-bottom: 20px;"
        row-key="roleId"
        border
        height="100%"
        tooltip-effect="dark"
        ref="roleListTable"
        @selection-change="handleSelectionChange"
        @row-click="roleItemClick"
      >
        <el-table-column prop="studentName" label="学生姓名" width="100" align="center"></el-table-column>
        <el-table-column prop="sectionName" label="年级" align="center"></el-table-column>
        <el-table-column prop="gmtCreate" label="单价模式" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.type==1?'阶梯模式':scope.row.type==2?'时间模式':''}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="" label="价格" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="seePrice(scope.row)" v-show="scope.row.childId">查看</el-button>
            <el-button type="text" size="small" v-show="!scope.row.childId">---</el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="gmtCreate" label="教务老师" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.teachManagerName">{{scope.row.teachManagerName}}（{{scope.row.teachManagerRealName}}）</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="设置时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            
            <!-- <el-button
              type="text"
              size="small"
              @click="setMode(scope.row)"
              >模式设置</el-button
            > -->
            <el-button
              type="text"
              size="small"
              @click="setCustom(scope.row)"
              >阶梯模式设置</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="setTimeCustom(scope.row)"
              >时间模式设置</el-button
            >
             <el-button
              type="text"
              size="small"
              @click="setSpecial(scope.row)"
              >特殊课</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-area">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listParams.pageNumber"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="listParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      ></el-pagination>
    </div>
    <el-dialog
        title="价格查看"
        center
        width="400px"
        :visible.sync="seePriceShow"
        :close-on-click-modal="false"
    >
      <div>
        <div class="seePrice-div">
          <p>基本单价：{{priceInfoParams.basicPrice}}元/课时</p>
        <ul>
          <li v-for="(item,index) in priceInfoParams.listXmtStudentUnitPriceGrandson" :key="index">
            <p>课时范围{{index+1}}：{{item.begin}}至{{item.end}}课时</p>
            <p>课时单价：{{item.unitPrice}}元/课时</p>
          </li>
        </ul>
        </div>
      </div>
     </el-dialog>
     <el-dialog
        title="模式设置"
        center
        width="400px"
        :visible.sync="setModeShow"
        :close-on-click-modal="false"
    >
      <div style="min-height:150px;">
        <div style="height:110px;display:flex;align-items:center;justify-content:center;">
          <el-radio-group v-model="modeParams.type" >
          <el-radio label="1">阶梯模式</el-radio>
          <el-radio label="2">时间模式</el-radio>
        </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setModeShow = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary"  size="small"
          @click="modeClick()"
          >确 定</el-button
        >
      </span>
     </el-dialog>
    <customMoney ref="customMoney" v-if="Visible" :visible="Visible" :studentInfo="studentInfo" @close="Visible=false" @refreshList="refreshList"></customMoney>
    <timeMode ref="timeMode" :studentInfo="studentInfo" @CB_timeStatus="CB_timeStatus" :timeStatus="timeStatus" @refreshList="refreshList"></timeMode>
    <unitPriceList ref="unitPriceList" @CB_dialogStatus="CB_dialogStatus" :dialogStatus="dialogStatus"></unitPriceList>
    <specialList ref="specialList" :specialStatus="specialStatus" :specialSid="specialSid" @CB_specialStatus="CB_specialStatus"></specialList>
  </div>
</template>
<script>
import { studentUnitPriceList,studentUnitPriceChildInfo,setFixPrice,setType } from '@/api/finance/finance.js'
import customMoney from './dialog/custom-money.vue';//阶梯模式设置
import timeMode from './dialog/time-mode.vue';//时间模式设置
import unitPriceList from './dialog/unit-price-list.vue'
import specialList from './dialog/special-class.vue'
export default {
  data() {
    return {
      timeStatus:false,
      dialogStatus:false,
      specialStatus:false,
      dataList: [],
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        name: "",
      },
      total: 0,
      Visible:false,
      unitPriceVisible:false,
      studentInfo:{
        isShow:false,
        studentId:""
      },
      seePriceShow:false,
      priceInfoParams:{},
      specialSid:"",
      setModeShow:false,
      modeParams:{
        id:'',
        type:'1'
      }

    };
  },
  components:{
    customMoney,
    unitPriceList,
    specialList,
    timeMode
  },
  methods: {
    //模式设置
    setMode(row){
      this.setModeShow=true;
      this.modeParams.type='1';
      this.modeParams.id=row.id;
    },
    modeClick(){
      setType(this.modeParams).then(res => {
          if(res.code==0){
            this.msgSuccess("操作成功！");
            this.setModeShow=false;
            this.studentUnitPriceListFn();
          }else{
            this.msgWarn(res.msg);
          }
      });
    },
    CB_dialogStatus(){
      this.dialogStatus=false;
    },
    CB_timeStatus(){
      this.timeStatus=false;
    },
    refreshList() {
      this.studentUnitPriceListFn();
    },
    CB_specialStatus(){
      this.specialStatus=false;
    },
    setCustom(row){
      this.studentInfo=row;
      this.rowsEndTime=null;
       this.Visible=true;
      // if(row.type==1){//阶梯模式
      //   this.Visible=true;
      // }else if(row.type==2){
      //   this.timeStatus=true;
      // }
    },
    setTimeCustom(row){
       this.studentInfo=row;
      // this.modeParams.id=row.id;
      this.timeStatus=true;
    },
    getUnitPrice(){
      this.dialogStatus=true;
    },
    studentUnitPriceListFn(){
      studentUnitPriceList(this.listParams).then(res => {
            this.dataList=res.rows;
            this.total=res.total;
          });
    }, 
    studentListParamsChange() {
      this.Visible = false;
    },
    handleSelectionChange(val) {
     
    },
    handleSizeChange(pageSize) {
      this.listParams.pageSize = pageSize;
      this.studentUnitPriceListFn();
    },
    handleCurrentChange(currentPage) {
      this.listParams.pageNumber = currentPage;
      this.studentUnitPriceListFn();
    },
    roleItemClick(row){
       this.$refs.roleListTable.toggleRowSelection(row);
    },
    setFixPrice(row){
      setFixPrice({id:row.id,sectionId:row.sectionId}).then(res => {
       if(res.code==0){
         this.msgSuccess("操作成功！");
         this.studentUnitPriceListFn();
       }
      });
    },
    seePrice(row){
      this.seePriceShow=true;
      studentUnitPriceChildInfo(row.childId).then(res => {
        if(res.code==0){
          this.priceInfoParams=res.rows;
        }
      });
    },
    setSpecial(row){
      this.specialStatus=true;
      this.specialSid=row.studentId;

    },
  },
  created() {
    this.studentUnitPriceListFn();
  },
  mounted() {
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .btn-area {
    height: 50px;
    display: flex;
    align-items: center;
    .left {
      width: 300px;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .table-area {
    flex: 1;
    overflow: hidden;
  }
  .page-area {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .seePrice-div{
      font-size:15px;
      padding-bottom:30px;
      ul{
        margin-top:20px;
        li{
          line-height: 26px;
        }
      }
    }
}
</style>
